import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../service/userProfile.service";
import { UserSliceState } from "../../types/user";
import { defaultUserProfile } from "../../constants/userProfile";
import { setResponseValue } from "./api-response/api-response";

const initialState: UserSliceState = {
  currentUserProfile: defaultUserProfile,
  isFetching: false,
  isSuccess: false,
  isError: false,
  isUpdate: false,
  error: "",
  tenantUsers: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUserProfiles.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.tenantUsers = action?.payload?.data;
    });
    builder.addCase(getAllUserProfiles.pending, (state) => {
      state.isFetching = true;
      state.isSuccess = false;
    });
    builder.addCase(getAllUserProfiles.rejected, (state) => {
      state.isFetching = false;
      state.isSuccess = false;
    });

    builder.addCase(getUserProfileById.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.currentUserProfile = action.payload;
    });
    builder.addCase(getUserProfileById.pending, (state) => {
      state.isFetching = true;
      state.isSuccess = false;
    });
    builder.addCase(getUserProfileById.rejected, (state) => {
      state.isFetching = false;
      state.isSuccess = false;
    });
  },
});

export const getAllUserProfiles = createAsyncThunk(
  "user/getAllUserProfiles",
  async (
    {
      authTenantId,
      callerEmail,
      callerName,
    }: { authTenantId: string; callerEmail: string; callerName: string },
    thunkAPI
  ) => {
    // console.log("getAllUserProfiles:", authTenantId);
    const { data, error } = await UserService.getAllUserProfiles(
      authTenantId,
      callerEmail,
      callerName
    );

    // filter out ABAEngine Admin users
    const filteredData = data.filter((user:any) => user.authRole !== "ABAEngine Admin")
    
    if (data) {
      return { data: filteredData };
    } else {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/* Code Review: 
    - TODO: seems not used
*/
// Fetch User Details  thunks
export const getUserProfileById = createAsyncThunk(
  "user/getUserProfileById",
  async ({ userId }: { userId: string }, thunkAPI) => {
    const { error, data } = await UserService.getUserProfileById(userId);
    if (data) {
      return { data };
    } else {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/* Code Review: 
    - Hui, 1.10.2024
*/
// Create User Profile
export const createUserProfile = createAsyncThunk(
  "user/createUserProfile",
  async (
    input: {
      firstName: string;
      lastName: string;
      email: string;
      role: string;
      businessUnit: string;
      authTenantId: string;
      caller: string;
      //createUserId: any;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      
      const { status, message } = await UserService.createUserProfile(
        input.firstName ? input.firstName : "",
        input.lastName ? input.lastName : "",
        input.email ? input.email : "",
        input.role ? input.role : "",
        input.businessUnit ? input.businessUnit : "",
        input.authTenantId ? input.authTenantId : "",
        input.caller ? input.caller : ""
        //input.createUserId ? input?.createUserId : ""
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }    
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    } 
  }
);


/* Code Review: 
    - Hui, 1.10.2024
*/
export const deleteUserProfile = createAsyncThunk(
  "user/deleteUserProfile",
  async (
    {
      id,
      email,
      authTenantId,
      permanentDelete,
    }: {
      id: string;
      email: string;
      authTenantId: string;
      permanentDelete: boolean;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await UserService.deleteUserProfile(
        id,
        email,
        authTenantId,
        permanentDelete
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }    
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  } 
);

//delete multiple user profile
export const deleteMultipleUserProfile = createAsyncThunk(
  "user/deleteMultipleUserProfile",
  async (
    {
      ids,
      authTenantId,
      permanentDelete,
    }: {
      ids: string[];
      authTenantId: string;
      permanentDelete: boolean;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await UserService.deleteMultipleUserProfile(
        ids,
        authTenantId,
        permanentDelete
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

/* Code Review: 
    - Hui, 1.10.2024
*/
export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (
    {
      id,
      email,
      firstName,
      lastName,
      role,
      authTenantId,
      businessUnit,
    }: 
    {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      role: string;
      authTenantId: string;
      businessUnit: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await UserService.updateUserProfile(
        id,
        email,
        firstName,
        lastName,
        role,
        authTenantId,
        businessUnit
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }    
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  } 
);

/* Code Review: 
    - Hui, 1.10.2024
*/
export const inviteUser = createAsyncThunk(
  "inviteUser/userProfile",
  async (
    {
      id,
      role,
      name,
      email,
    }: { id: string; role: string; name: string; email: string },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await UserService.inviteUserAPI(id, role, name, email);
      console.log("error:", data);
      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const inviteMultipleUser = createAsyncThunk(
  "inviteUser/userProfile",
  async ({ ids }: { ids: string[] }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await UserService.inviteMultipleUserAPI(ids);
      console.log("error:", data);
      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//deprecated!! handle in backend API createOrganization()

// eslint-disable-next-line no-empty-pattern
export const {} = userSlice.actions;
export default userSlice;
