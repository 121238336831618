import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Grid,
  Switch,
  Card,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  Table,
  TableContainer,
  Paper,
  TableHead,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataCatalog,
  deleteCatalogTemplate,
  updateDataCatalogById,
} from "../../../redux/features/data-catalog/data-catalog-slice";
import { NewDataCatalog } from "../../../types/businessSettings";
import { Add, BorderColor } from "@mui/icons-material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataCatalogConcepts } from "../../../constants/catalog";
import defaultProfile from "../../../assets/images/defaultProfile.png"

interface IdataCatalog {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  SetIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCatalogData: React.Dispatch<React.SetStateAction<NewDataCatalog>>;
  setfiltercatalogname: React.Dispatch<React.SetStateAction<string>>;
  filtercatalogname: string;
}

// Custom Table
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

// Custom Table Col style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

// Custom Table Row style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

const BusinessCatalog: React.FC<IdataCatalog> = ({
  setOpenDialog,
  SetIsEdit,
  setSelectedCatalogData,
  setfiltercatalogname,
  filtercatalogname,
}) => {
  //#region variable region

  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //custom rendering
  const [isrender, setIsRender] = useState<boolean>(false);

  //fetch all catalogData
  const NewDataCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.NewDataCatalog
  );

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //store id
  const [catalogDataId, setCatalogDataId] = useState<string>("");

  //#endregion

  //#region methods region

  //handle update and get catalog data
  const getAllCatalogPlan = useCallback(async (item: NewDataCatalog) => {
    try {
      // eslint-disable-next-line
      const data = await dispatch(
        updateDataCatalogById({
          id: item.id,
          catalogName: item.catalogName,
          conceptValue: item.conceptValue,
          isActive: !item.isActive,
          description: item.description,
        })
      );
    } catch (error: any) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle catalog active
  const handleCatalogSwitchChange = async (item: NewDataCatalog) => {
    getAllCatalogPlan(item);
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    dispatch(getDataCatalog());
  }, [dispatch]);
  //#endregion

  return (
    <>
      <Grid
        className="catelogHeader"
        item
        xs={12}
        md={8}
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Grid
          className="catalogHead"
          style={{
            display: "flex",
            gap: "5px 10px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6" component="div">
            Select Catalog:
          </Typography>

          {Object.values(DataCatalogConcepts)
            .filter((item) => item !== "Template Variable")
            ?.map((item, index) => (
              <Box className="fromGroup-chips" key={item}>
                <Card
                  key={index}
                  className={
                    filtercatalogname === item ? "fromGroup-chip-active" : ""
                  }
                  onClick={() => {
                    filtercatalogname === item
                      ? setfiltercatalogname("")
                      : setfiltercatalogname(item);
                  }}
                >
                  {item}
                </Card>
              </Box>
            ))}
        </Grid>
        <Grid
          width={"145px"}
          flexGrow={"0"}
          flexShrink={0}
          flexBasis={"145px"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button
            type="submit"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setOpenDialog(true);
              SetIsEdit(false);
            }}
          >
            Add New Item
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="listingTable">
        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 820,
              borderCollapse: "separate",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Concept</StyledTableCell>
                <StyledTableCell align="center">Value</StyledTableCell>
                <StyledTableCell align="center">Updated By</StyledTableCell>
                <StyledTableCell align="center">Updated On</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {NewDataCatalog.length > 0 ? (
                <>
                  {(filtercatalogname
                    ? NewDataCatalog?.filter(
                        (item) => item.catalogName === filtercatalogname
                      )
                    : NewDataCatalog
                  ).map((item) => (
                    <>
                      <StyledTableRow
                        style={{ borderRadius: 20 }}
                        key={item.id}
                      >
                        <StyledTableCell>
                          <Box className="fromGroup-chips">
                            <Card
                              key={item.id}
                              className={
                                filtercatalogname?.includes(item.catalogName)
                                  ? "fromGroup-chip-active"
                                  : ""
                              }
                            >
                              {item.catalogName}
                            </Card>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>{item.conceptValue} </StyledTableCell>
                        <StyledTableCell>
                          {item.updatedBy?.firstName ? (
                            <div className="profilePart">
                              <img
                                src={item.updatedBy?.profilePictureUrl || defaultProfile}
                                alt="profilepicture"
                              />
                              {item.updatedBy?.firstName}{" "}
                              {item.updatedBy?.lastName}
                            </div>
                          ) : (
                            <div className="profilePart">default</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.updatedAt &&
                            `${moment(item.updatedAt as any).format("lll")}`}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ display: "flex", gap: "23px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "10px",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Switch
                              checked={item.isActive}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleCatalogSwitchChange(item);
                                setIsRender(!isrender);
                              }}
                            />
                            <BorderColor
                              color="primary"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                SetIsEdit(true);
                                setOpenDialog(true);
                                setSelectedCatalogData(item);
                              }}
                            />
                            <DeleteIcon
                              color="primary"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setDeletePopup(true);
                                setCatalogDataId(item.id);
                              }}
                            />
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Dialog open={deletePopup} className="smallModel">
          <div>
            <DialogContent>
              Do you want to delete this concept value?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setDeletePopup(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(deleteCatalogTemplate({ id: catalogDataId }));
                  setDeletePopup(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Grid>
    </>
  );
};

export default BusinessCatalog;
