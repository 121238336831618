import React, { useEffect, useState } from "react";
import PlanningsHeading from "./planning-Heading/PlanningHeading";
import PlanningsDetails from "./planning-Details/PlanningsDetails";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPlanById } from "../../redux/features/plannings/planning-slice";

const Plannings: React.FC = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();
  //tracks selection changes in planning calender list
  const [changedList, setchangedList] = useState<boolean>(false);

  useEffect(() => {
    if (id) dispatch(getPlanById({ Id: id }));
  }, [id]);

  return (
    <div className="pageTemplate mapDetailsTemplate">
      <PlanningsHeading
        changedList={changedList}
        setchangedList={setchangedList}
      />

      <PlanningsDetails setchangedList={setchangedList} />
    </div>
  );
};

export default Plannings;
