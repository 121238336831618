import { AppUrls } from "../AppUrls";

export interface Tabs {
  tabName: string;
  id: string;
  url: string;
}

export const HeadersTabs: Tabs[] = [
  {
    // tabName: "Map",
    tabName: "Map",
    id: "1",
    url: AppUrls.Client.Map,
  },
  {
    tabName: "Board",
    id: "2",
    url: AppUrls.Client.Board,
  },
  {
    // tabName: "Map",
    tabName: "Planning",
    id: "3",
    url: AppUrls.Client.Planning,
  },
  // {
  //   // tabName: "Map",
  //   tabName: "PlanningTwo",
  //   id: "3",
  //   url: AppUrls.Client.PlanningTwo,
  // },
  {
    tabName: "Schedules",
    id: "4",
    url: AppUrls.Client.Schedules,
  },
  {
    tabName: "Forms",
    id: "5",
    url: AppUrls.Client.Forms,
  },
  {
    tabName: "Assessments",
    id: "6",
    url: AppUrls.Client.Assessments,
  },
  
  {
    tabName: "Clients",
    id: "6",
    url: AppUrls.Client.Clients,
  },
  {
    tabName: "Employees",
    id: "7",
    url: AppUrls.Client.Employees,
  },
  {
    tabName: "Message",
    id: "8",
    url: AppUrls.Client.Message,
  },
];

export const ClientTabs: Tabs[] = [
  {
    tabName: "Summary",
    id: "1",
    url: "",
  },
  {
    tabName: "Schedules",
    id: "2",
    url: "",
  },
  {
    tabName: "Files",
    id: "3",
    url: "",
  },
];
