import axios from "../axios/axios";
import { IUserProfile } from "../types/user";

//CTO Review: not used & not tested yet
export async function getUserProfileById(userId: string) {
  try {
    const { data } = await axios.get<IUserProfile>(`/userProfile/${userId}`);
    return { data };
  } catch (e: any) {
    return {
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export const createUserProfile = async (
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  businessUnit: string,
  authTenantId: string,
  caller: string
  //authUserId: any
) => {
  try {
    const data = await axios.post(`/userProfile`, {
      firstName,
      lastName,
      email,
      role,
      businessUnit,
      authTenantId,
      caller,
      //authUserId,
    });
    return data.data;
  } catch (e: any) {
    throw e;
  }
};

export const deleteUserProfile = async (
  id: string,
  email: string,
  authTenantId: string,
  permanentDelete: boolean
) => {
  try {
    const data = await axios.delete<any>(`/userProfile/${id}`, {
      data: { email, authTenantId, permanentDelete },
    });
    return data.data;
  } catch (e: any) {
    throw e;
  }
};

//delete multiple user profile
export const deleteMultipleUserProfile = async (
  ids: string[],
  authTenantId: string,
  permanentDelete: boolean
) => {
  try {
    const data = await axios.delete<any>(`/userProfile`, {
      data: { ids, authTenantId, permanentDelete },
    });
    return data.data;
  } catch (e: any) {
    throw e;
  }
};

export const updateUserProfile = async (
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  role: string,
  authTenantId: string,
  businessUnit: string
  // email: string
) => {
  try {
    const data = await axios.put(`/userProfile/${id}`, {
      id,
      email,
      firstName,
      lastName,
      role,
      authTenantId,
      businessUnit,
    });
    return data.data;
  } catch (e: any) {
    throw e;
  }
};

export const createTenantOwner = async (email: string, tenantId: string) => {
  try {
    const { data } = await axios.post("/userProfile/tenantOwner", {
      email,
      tenantId,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

//backend will decide data scope based on caller
export const getAllUserProfiles = async (
  authTenantId: string,
  callerEmail: string,
  callerName: string
) => {
  try {
    const { data } = await axios.get(
      `/userProfiles?authTenantId=${authTenantId}&callerEmail=${callerEmail}&callerName=${callerName}, `
    );
    return data;
  } catch (e: any) {
    console.log(e, "error");
    return e;
  }
};

// export const getAllTenatUserProfiles = async () => {
//   try {
//     const { data } = await axios.get(`/userProfile`);
//     return { data };
//   } catch (e: any) {
//     console.log(e, "error");
//     return e;
//   }
// };

export const sendUserEmail = async (data: any) => {
  try {
    const res = await axios.post("/authMessage", data);
    return res;
  } catch (e) {}
};

const inviteUserAPI = async (
  userId: string,
  role: string,
  name: string,
  email: string
) => {
  try {
    const { data } = await axios.post("/userProfile/invite", {
      userId,
      role,
      name,
      email,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

//invite multiple users
const inviteMultipleUserAPI = async (
  userIds: string[]
) => {
  try {
    const { data } = await axios.post("/userProfile/invite", {
      userIds
    });
    return data;
  } catch (e) {
    throw e;
  }
};

const UserService = {
  getUserProfileById,
  createTenantOwner,
  createUserProfile,
  // updateUserEmail,
  deleteUserProfile,
  updateUserProfile,
  getAllUserProfiles,
  inviteUserAPI,
  deleteMultipleUserProfile,
  inviteMultipleUserAPI
};
export default UserService;
