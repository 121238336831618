import axios from "../axios/axios";

export const postExportRequest = async (
  entityTypes: string[],
  fileFormat: string
) => {
  try {
    const data = await axios.get("/export", {
      params: {
        entityTypes,
        fileFormat,
      },
    });

    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const postImportRequest = async (formData: FormData) => {
  try {
    const data = await axios.post("/import", formData);
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const getExportTemplates = async () => {
  try {
    const data = await axios.get("/export/templates");
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const getImportExoportRequest = async()=>{
  try {
    const data = await axios.get("/importExportRequest");
    return data.data;
  } catch (error:any) {
    throw error?.response.data;
  }
}

const ImportExportService = {
  postExportRequest,
  postImportRequest,
  getExportTemplates,
  getImportExoportRequest
};

export default ImportExportService;
