import { Button, Typography } from "@mui/material";
import ProviderBoard from "../../providers/provider-trello-board/ProviderBoard";
import { ReactComponent as LeftArrow } from "../../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/images-svg/rightArrow.svg";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

interface IOnboarding {
  callbacks: any;
  updateHandler: () => void;
  providerIntakeModal: (id: string) => void;
}

const Onboarding: React.FC<IOnboarding> = ({
  updateHandler,
  providerIntakeModal,
  callbacks,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0px 5px",
        }}
      >
        <Typography mr={1} variant="h6" fontWeight={"bold"}>
          Stages
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            padding: "0px 10px",
          }}
          onClick={updateHandler}
        >
          <SettingsOutlinedIcon sx={{ fontSize: "25px" }} color="primary" />
          <Typography mr={1}> Setting </Typography>
        </div>
      </div>
      <div className="cardBlock cardBlockClient">
        <div className="clientBoardSlider-module">
          <div className="clientBoardSlider">
            <ProviderBoard
              callbacks={callbacks}
              chatCommand={providerIntakeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
