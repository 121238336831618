import axios from "../axios/axios";
import {
  IPlanDetail,
  IUpdatePlan,
  ICreatePlan,
} from "../types/planning";
import { Response, SingleResponse } from "../types/response";

//post Planner api
export const createPlan = async (payloadData: ICreatePlan) => {
  try {
    const { data } = await axios.post(`/planning`, payloadData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update Planning Details
export const updatePlanList = async (payloadData: IUpdatePlan , id:string) => {
  try {
    const { data } = await axios.put(`/planning/${id}`, payloadData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//publish plan
export const publishPlan = async (Id: string) => {
  try {
    const { data } = await axios.patch(`/planning/${Id}/publish`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
// deletePlan;

//update pending api
export const deletePlan = async (Id: string) => {
  try {
    const { data } = await axios.delete(`/planning/${Id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update pending api
export const resetPlan = async (id: string) => {
  try {
    const { data } = await axios.put(`/planning/${id}/reset`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
//update pending api
export const updatePlanName = async (Id: string, planName: string) => {
  try {
    const { data } = await axios.put(`/planning/${Id}/planName`, {
      planName: planName,
    });

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update pending api
export const getPlanById = async (Id: string) => {
  try {
    const { data } = await axios.get<SingleResponse<IPlanDetail>>(
      `/planning/${Id}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//get all Plan list
export async function getAllPlanList() {
  try {
    const { data } = await axios.get<Response<IPlanDetail[]>>(`/planning`);

    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

const PlanService = {
  //postplannerForm,
  createPlan,

  getAllPlanList,
  getPlanById,

  updatePlanName,

  deletePlan,
  resetPlan,
  publishPlan,
  updatePlanList,
};
//
export default PlanService;
