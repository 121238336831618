import React, { FC, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@descope/react-sdk";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Close, HelpOutlineSharp, Menu } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setSideMenuSlider } from "../../redux/features/auth-slice";
import { getOrgInfo } from "../../redux/features/organization/organization-slice";
import { getAllBusinessUnits } from "../../redux/features/business-unit/business-unit";

import HeaderTab from "../header-tab/HeaderTab";

import { getRolesForTenant } from "../../utils/getRolesForTenant";

import { SystemMenu } from "../../constants/side-menu";
import { HeadersTabs, Tabs } from "../../constants/tabs";

import ABAEngineLogo from "../../assets/images/logos/abaengine.png";

import "./SideMenu.scss";

type Anchor = "top" | "left" | "bottom" | "right";

interface MenuIcon {
  menuIcon: boolean;
}

const SideMenu: FC<MenuIcon> = ({ menuIcon }) => {
  // #region variables region

  //user from front egg
  // const { user } = useAuth();
  // const currentUserRole = user?.roles[0]?.key;

  // Descope Integration
  const { user } = useUser();
  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  // routing object
  const navigate = useNavigate();

  //dispatch for redux
  const dispatch = useAppDispatch();

  //control side menu slider
  const sideMenuSlider = useAppSelector((state) => state.auth.sideMenuSlider);

  const handleClose = () => {
    dispatch(setSideMenuSlider(!sideMenuSlider));
  };

  //displays which tab is active
  const [selectedTab, setSelectedTab] = useState<string>("");

  //org logo
  const orgLogo = useAppSelector(
    (state) => state.organizationSlice.orgData.orgLogo.url
  );

  // #endregion

  // const toggleDrawer =
  //   (anchor: Anchor, open: boolean) =>
  //   (event: React.KeyboardEvent | React.MouseEvent) => {
  //     if (
  //       event.type === "keydown" &&
  //       ((event as React.KeyboardEvent).key === "Tab" ||
  //         (event as React.KeyboardEvent).key === "Shift")
  //     ) {
  //       return;
  //     }

  //     setState({ ...state, [anchor]: open });
  //   };

  // #region methods region

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={() => toggleDrawer(anchor, false)}
      // onKeyDown={ () => toggleDrawer(anchor, false)}
    >
      <div className="sideMenu__header">
        <div className="sideMenu__header-inner">
          <IconButton
            className="mainHeader__toggle"
            size="large"
            edge="start"
            sx={{ mr: 2 }}
            style={{ background: "transform", color: "#000" }}
            onClick={() => dispatch(setSideMenuSlider(!sideMenuSlider))}
          >
            <Menu />
          </IconButton>
          <img
            alt="Loading"
            src={orgLogo || ABAEngineLogo}
            className="sideMenu__logo"
          />
        </div>
        <span
          className="sideMenu__close"
          onClick={() => dispatch(setSideMenuSlider(!sideMenuSlider))}
        >
          <Close />
        </span>
      </div>
      <Box display={"flex"} className="mobile-menu">
        <HeaderTab
          onTabClick={(tab: Tabs) => {
            setSelectedTab(tab.tabName);

            // localStorage.setItem("selectedTab", tab.tabName); //turn it off
            navigate(tab.url);
            dispatch(setSideMenuSlider(!sideMenuSlider));
          }}
          selectedTab={selectedTab}
          tabs={HeadersTabs}
        />
      </Box>
      <List className="sideMenu__nav">
        {SystemMenu.map(
          (item, index) =>
            item &&
            (currentRoles.includes("Owner") ||
              !(
                (item.text === "Billing" ||
                 item.text === "Integration") &&
                (currentRoles.includes("Admin") ||
                  currentRoles.includes("ABAEngine Admin"))
              )) && ( //exclude billing
              <ListItem
                key={item.text}
                onClick={() => {
                  // console.log(item,"item")
                  if (item.text === "Company") {
                    dispatch(getOrgInfo({ useLoader: true }));
                    dispatch(getAllBusinessUnits());
                  }
                  if (item) {
                    navigate(item?.path?.toLowerCase() as string);
                    dispatch(setSideMenuSlider(!sideMenuSlider));
                  }
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <item.labelICon />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            )
        )}
        {/* {isAuthenticated && <InviteButton />} */}
      </List>

      <div className="sideMenu__footer">
        <span className="sideMenu__footer-icon">
          <HelpOutlineSharp />
        </span>
        <Typography>Help and Feedback</Typography>
      </div>
    </Box>
  );

  // #endregion

  return (
    <div key="left" className="sideMenu">
      <Drawer
        anchor={"left"}
        open={menuIcon}
        onClose={() => {
          dispatch(setSideMenuSlider(!sideMenuSlider));
        }}
      >
        {list("left")}
      </Drawer>
    </div>
  );
};

export default SideMenu;
