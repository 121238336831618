import { useEffect, useState } from "react";
import {
  Box,
  FormGroup,
  Grid,
  Switch,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  Table,
  TableContainer,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  TableHead,
  Card,
  MenuItem,
  Select,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { OrgData } from "../../../types/company";
import jotformLogo from "../../../assets/images/images-svg/jotform-icon-transparent.svg";
import surveyLogo from "../../../assets/images/images-svg/SurveyIcon.svg";
import internetformLogo from "../../../assets/images/images-png/internet.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ParentAppForm from "./ParentAppForm";
import { BorderColor, Close } from "@mui/icons-material";
import {
  DuplicateFormDataById,
  UpdateFormDataById,
  deleteIntake,
} from "../../../redux/features/intake/intake-form-slice";
// import { IntakeFormList } from "../../../types/businessSettings";
import { useNavigate } from "react-router-dom";
import {
  GetFormCards,
  UpdateFormActiveById,
} from "../../../redux/features/forms/form-slice";
import { IFormCard } from "../../../types/forms";
import LaunchIcon from "@mui/icons-material/Launch";
import moment from "moment";
import { MenuProps } from "../../../constants/providers";
import CloseIcon from "@mui/icons-material/Close";

// Custom Table
export const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

// Custom Table Col style
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

// Custom Table Row style
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

export enum BusinessFormGroup {
  Intake = "Intake",
  Onboarding = "Onboarding",
  Assessment = "Assessment",
}

export enum BusinessFormType {
  AssessmentForm = "Assessment Form",
  IntakePacketForm = "Intake Packet Form",
  IntakeClientForm = "Intake Client Form",
  NewEmployeeForm = "New Employee Form",
  EmployeePacketForm = "Employee Packet Form",
}

interface IBussinessForm {
  tabState: string;
}
const BusinessForm: React.FC<IBussinessForm> = ({ tabState }) => {
  //#region variable region
  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //track path
  const navigate = useNavigate();

  //fetch business settings data from store
  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  /*
  //store front Insurance Card
  // eslint-disable-next-line
  const [frontInsuranceCard, setFrontInsuranceCard] = useState(
    org.allowToUploadFrontInsuranceCard
  );

  //store back Insurance Card
  // eslint-disable-next-line
  const [backInsuranceCard, setBackInsuranceCard] = useState(
    org.allowToUploadBackInsuranceCard
  );

  //store Custom Intake Form
  // eslint-disable-next-line
  const [completeCustomIntakeForm, setCompleteCustomIntakeForm] = useState(
    org.allowToCompleteCustomIntakeForm
  );
  */

  // Pop up Set for save Files
  const [editDialog, setEditDialog] = useState<boolean>(false);

  //handles the popup for editing Credential details
  const [editFormDetails, setEditFormDetails] = useState<boolean>(false);

  //store id
  const [intakeFormId, setIntakeFormId] = useState<string>("");

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //open external form
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  //stores selected form type
  const [selectedFormType, setSelectedFormType] =
    useState<string>("Intake Packet Form");

  //filters form group
  const [filterFormGroup, setFilterGroup] = useState<string>(
    tabState || "Intake"
  );

  //store id
  const [parentformId, setParentFormID] = useState<string>("");

  //handles the popup for editing Credential details
  // eslint-disable-next-line
  const [addFormsDetails, setAddFormsDetails] = useState<boolean>(false);

  //stores intakeFormList
  const intakeForms = useSelector<RootState, IFormCard[]>(
    (state) => state.formSlice.AllForms
  );

  //stores intake Client Form List
  // eslint-disable-next-line
  const [intakeClientFormList, setIntakeClientFormList] = useState<{
    forms: IFormCard[];
  }>();
  //#endregion

  //#region method region
  // handle active intake forms
  const handleSwitchChange = (item: IFormCard) => {
    dispatch(
      UpdateFormActiveById({
        id: item.id,
        value: !item.active,
      })
    );
  };

  // const handleTypeChange = (e: any) => {
  //   setSchoolAddressObj((prevState: any) => {
  //     return { ...prevState, [e.target.name]: e.target.value };
  //   });
  // };

  //for sorting data
  const sortData = (item: IFormCard[]) => {
    let data: IFormCard[] = [];
    if (item.length > 0) {
      let result = [...item].sort((a: IFormCard, b: IFormCard) => {
        return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
      });
      data = [...result];
    }
    return data;
  };

  const getFilteredList = (list: IFormCard[], formGroup: string) => {
    let result: IFormCard[] = [];
    if (list && list.length > 0) {
      if (formGroup === BusinessFormGroup.Intake) {
        result = list.filter(
          (item) =>
            item.formType === BusinessFormType.IntakeClientForm ||
            item.formType === BusinessFormType.IntakePacketForm
        );
        result = list.filter(
          (item) =>
            item.formType === BusinessFormType.IntakeClientForm ||
            item.formType === BusinessFormType.IntakePacketForm
        );
      } else if (formGroup === BusinessFormGroup.Onboarding) {
        result = list.filter(
          (item) =>
            item.formType === BusinessFormType.NewEmployeeForm ||
            item.formType === BusinessFormType.EmployeePacketForm
        );
        result = list.filter(
          (item) =>
            item.formType === BusinessFormType.NewEmployeeForm ||
            item.formType === BusinessFormType.EmployeePacketForm
        );
      } else if (formGroup === BusinessFormGroup.Assessment) {
        result = list.filter(
          (item) => item.formType === BusinessFormType.AssessmentForm
        );
        result = list.filter(
          (item) => item.formType === BusinessFormType.AssessmentForm
        );
      } else {
        result = list;
      }
    }
    return result;
  };

  //handles formType change
  const handleFormTypeChange = (item: IFormCard, value: string) => {
    dispatch(
      UpdateFormDataById({
        id: item.id,
        name: item?.name,
        jsonData: item.formSchema,
        formType: value,
      })
    );
  };

  // handle duplication form
  const handleDuplicateForm = (formId: string) => {
    dispatch(DuplicateFormDataById({ id: formId }));
  };
  //#endregion

  //#region useEffect region
  //used to fetch all intakeForms
  useEffect(() => {
    // dispatch(GetAllIntakeForms());
    dispatch(GetFormCards({}));
    // dispatch(GetCommunicationSetting({ communicationType: "Intake Packet Form" }));
  }, []);
  //#endregion

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12} lg={6}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">Form Upload</Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Require parents to Upload Insurance Card (Front)
                  </h6>
                  <Switch
                    checked={frontInsuranceCard}
                    name="UploadFrontInsuranceCard"
                    onChange={() => {
                      setFrontInsuranceCard(!frontInsuranceCard);

                      dispatch(
                        updateBusinessSettings({
                          UploadBackInsuranceCard: backInsuranceCard,
                          CompleteCustomIntakeForm: completeCustomIntakeForm,
                          UploadFrontInsuranceCard: !frontInsuranceCard,
                        })
                      );
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormGroup>
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Require parents to Upload Insurance Card (Back)
                  </h6>

                  <Switch
                    checked={backInsuranceCard}
                    name="UploadBackInsuranceCard"
                    onChange={() => {
                      setBackInsuranceCard(!backInsuranceCard);
                      dispatch(
                        updateBusinessSettings({
                          UploadBackInsuranceCard: !backInsuranceCard,
                          CompleteCustomIntakeForm: completeCustomIntakeForm,
                          UploadFrontInsuranceCard: frontInsuranceCard,
                        })
                      );
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormGroup>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={12} mt="22px">
        <div className="listingTable" style={{ marginBottom: 0 }}>
          <div
            className="listingTable__header"
            style={{ paddingBottom: "20px" }}
          >
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: "15px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                className="listingTable__title"
                gutterBottom
                // variant="h6"
                style={{ fontWeight: 500, fontSize: "20px" }}
              >
                Select Group:
              </Typography>
              <Box sx={{ display: "flex", gap: "15px" }} className= "form-tabs" >
                {Object.values(BusinessFormGroup)?.map((item, index) => (
                  <Box className="fromGroup-chips" key={item}>
                    <Card
                      key={index}
                      className={
                        filterFormGroup === item ? "fromGroup-chip-active" : ""
                      }
                      onClick={() => {
                        filterFormGroup === item
                          ? setFilterGroup("")
                          : setFilterGroup(item);
                      }}
                    >
                      {item}
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
            {filterFormGroup !== BusinessFormGroup.Assessment && (
              <div
                style={{
                  position: "relative",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    if (filterFormGroup === "Intake") {
                      navigate("/surveycreator/" + "newintakeform", {
                        state: {
                          type: "Intake Packet Form",
                        },
                      });
                    } else if (filterFormGroup === "Onboarding") {
                      navigate("/surveycreator/" + "newemployeeform", {
                        state: {
                          type: "Employee Packet Form",
                        },
                      });
                    }
                  }}
                  sx={{ margin: " 0px 5px 5px 5px" }}
                >
                  + Design Form
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditDialog(true);
                    setAddFormsDetails(true);
                  }}
                  sx={{ margin: "0px 5px 5px 5px" }}
                >
                  Link External Form
                </Button>
              </div>
            )}
          </div>

          <Grid item xs={12} className="listingTable">
            <TableContainer component={Paper} className="tableResponsive">
              <StyledTable
                sx={{
                  minWidth: 820,
                  borderCollapse: "separate",
                  cursor: "pointer",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Type</StyledTableCell>
                    <StyledTableCell align="center">Updated On</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {intakeForms?.length > 0 &&
                    (filterFormGroup
                      ? sortData(getFilteredList(intakeForms, filterFormGroup))
                      : sortData(intakeForms)
                    )?.map((item) => (
                      <StyledTableRow
                        style={{ borderRadius: 20 }}
                        key={item.id}
                      >
                        <StyledTableCell
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {item.formSchema !== null &&
                            item.formType !==
                              BusinessFormType.AssessmentForm ? (
                              <img
                                className="survetFormLogo"
                                src={surveyLogo}
                                alt="surveyLogo"
                              />
                            ) : item.isJot === true ? (
                              <img
                                className="jotformLogo"
                                src={jotformLogo}
                                alt="jotformLogo "
                              />
                            ) : (
                              <img
                                className="formLogo"
                                src={internetformLogo}
                                alt="internetformLogo "
                              />
                            )}
                          </span>
                          <Typography>{item.name}</Typography>
                          {item.url !== null &&
                            item.formType !== "Assessment Form" && (
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "black" }}
                              >
                                <LaunchIcon />
                              </a>
                            )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box className="fromGroup">
                            {item.formType ===
                              BusinessFormType.IntakePacketForm ||
                            item.formType ===
                              BusinessFormType.EmployeePacketForm ? (
                              <Select
                                className="form-control"
                                labelId="demo-simple-select-label"
                                value={item.formType}
                                name="formType"
                                onChange={(e) =>
                                  handleFormTypeChange(item, e.target.value)
                                }
                                MenuProps={MenuProps}
                                style={{ maxWidth: "200px", width: "200px" }}
                              >
                                <MenuItem
                                  value={BusinessFormType.IntakePacketForm}
                                >
                                  {BusinessFormType.IntakePacketForm}
                                </MenuItem>
                                <MenuItem
                                  value={BusinessFormType.EmployeePacketForm}
                                >
                                  {BusinessFormType.EmployeePacketForm}
                                </MenuItem>
                              </Select>
                            ) : (
                              <Box className="border-boxBlockForm">
                                {item.formType}
                              </Box>
                            )}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.updatedAt &&
                            `${moment(item.updatedAt as any).format("lll")}`}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ display: "flex", gap: "23px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "10px",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {item.formType !==
                              BusinessFormType.IntakeClientForm &&
                              item.formType !==
                                BusinessFormType.NewEmployeeForm && (
                                <Switch
                                  checked={item.active}
                                  onChange={() => {
                                    handleSwitchChange(item);
                                  }}
                                />
                              )}

                            {item.formType !==
                              BusinessFormType.AssessmentForm && (
                              <>
                                <BorderColor
                                  color="primary"
                                  onClick={() => {
                                    if (item.formSchema !== null) {
                                      navigate("/surveycreator/" + item.id, {
                                        state: {
                                          url: item.url,
                                          type: item.formType,
                                        },
                                      });
                                    } else {
                                      setEditDialog(true);
                                      setEditFormDetails(true);
                                      setParentFormID(item.id);
                                    }
                                  }}
                                />
                                {item.formType !==
                                  BusinessFormType.IntakeClientForm &&
                                  item.formType !==
                                    BusinessFormType.NewEmployeeForm && (
                                    <DeleteIcon
                                      color="primary"
                                      onClick={() => {
                                        setDeletePopup(true);
                                        setIntakeFormId(item.id);
                                      }}
                                    />
                                  )}

                                <ContentCopyIcon
                                  color="primary"
                                  onClick={() => handleDuplicateForm(item.id)}
                                ></ContentCopyIcon>
                              </>
                            )}
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Grid>
        </div>
        {editDialog && (
          <Dialog open={editDialog} className="largeModel">
            <Grid>
              <Box className="largeModel__head">
                <Typography variant="h5" className="largeModel__title">
                  Add Form / Edit Form
                </Typography>
                <Button
                  className="largeModel__closeBtn"
                  onClick={() => {
                    setEditDialog(false);
                    if (editFormDetails) {
                      setEditFormDetails(false);
                    } else {
                      setAddFormsDetails(false);
                    }
                  }}
                >
                  <Close />
                </Button>
              </Box>
            </Grid>

            <ParentAppForm
              editFormDetails={editFormDetails}
              setAddFormsDetails={setAddFormsDetails}
              setEditFormDetails={setEditFormDetails}
              parentformId={parentformId}
              setEditDialog={setEditDialog}
              setSelectedFormType={setSelectedFormType}
              selectedFormType={selectedFormType}
              formType={selectedFormType}
            />
          </Dialog>
        )}

        {/* <Dialog open={openPopup} className="smallModel">
          <div>
            <div style={{ float: "right", padding: "10px" }}>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenPopup(false)}
              />
            </div>
            <DialogContent>Select form type</DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate("/surveycreator", {
                    type: "Intake Packet Form",
                  });
                  setOpenPopup(false);
                }}
              >
                Intake Form
              </Button>
              <Button
                className="border-button"
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate("/surveycreator", {
                    type: "Employee Packet Form",
                  });
                  setOpenPopup(false);
                }}
              >
                Onboarding Form
              </Button>
            </DialogActions>
          </div>
        </Dialog> */}

        <Dialog open={deletePopup} className="smallModel">
          <div>
            <DialogContent>
              Do you want to delete this form record ?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setDeletePopup(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteIntake({
                      id: intakeFormId,
                      formType: "Intake Packet Form",
                    })
                  );
                  setDeletePopup(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default BusinessForm;
