import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ProviderForms } from "../../../types/provider";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import "../message-details/message-details.scss";
import { filterMessage } from "../../../helpers/message-filter-helper/messag-filter-helper";
import { Tooltip } from "@mui/material";
import { MailOutlineOutlined } from "@mui/icons-material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MessageFilter } from "../../../types/message";
import { useAppSelector } from "../../../redux/store";

interface MessageProviderListProps {
  providerListData: ProviderForms[];
  isMobileView: boolean;
  setSelectedUser: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
      entityType: string;
    }>
  >;
  selectedUser: {
    id: string;
    name: string;
  };
}

const MessageProviderList: React.FC<MessageProviderListProps> = ({
  providerListData,
  selectedUser,
  setSelectedUser,
  isMobileView,
}) => {
  //#region variable region

  //variable to store search value
  const searchValue = useAppSelector<string>(
    (state) => state.activeStep.searchValue
  );

  //variable to store filterValues
  const filterValues = useAppSelector<MessageFilter>(
    (state) => state.messageFilterSlice
  );

  //handles copy state
  const [isCopied, setIsCopied] = useState(false);

  const unreadUsers = useAppSelector<string[]>(
    (state) => state.messageSlice.unreadUserId
  );
  //#endregion

  // #region methods region

  // handle selected Provider
  const handleSelectorProvider = (ele: ProviderForms) => {
    setSelectedUser({
      id: ele.employmentDetails.id,
      name:
        ele.employmentDetails.firstName + " " + ele.employmentDetails.lastName,
      entityType: "Provider",
    });
  };

  //handle copy to clipboard
  const handleCopyToClipboard = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  //#endregion

  //#region useEffect region

  //selected provider on onload
  useEffect(() => {
    if (!isMobileView) {
      setSelectedUser({
        id: providerListData[0]?.employmentDetails.id,
        name:
          providerListData[0]?.employmentDetails.firstName +
          " " +
          providerListData[0]?.employmentDetails.lastName,
        entityType: "Provider",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  return (
    <div className="mapUserList">
      {providerListData.length > 0 &&
        filterMessage<ProviderForms>(
          filterValues,
          searchValue,
          providerListData
        ).map((item: ProviderForms, index: number) => {
          return (
            <div
              className={
                selectedUser.id === item.employmentDetails.id
                  ? "fromGroup-chip-active-message"
                  : "messageUser"
              }
              key={index}
              onClick={() => {
                handleSelectorProvider(item);
              }}
            >
              <div className="cardLevelBlock">
                <table>
                  <thead className="map-thead">
                    <tr>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {unreadUsers.includes(item?.employmentDetails.id) ? (
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                background: "#096dd9",
                                padding: "5px",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                background: "#096dd9",
                                padding: "5px",
                                visibility: "hidden",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          <span className="userMapCart__img">
                            <img
                              style={{ minWidth: "30px" }}
                              src={
                                item?.employmentDetails?.providerProfile?.url ||
                                ProviderImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="userMapCart__name">
                          <span>
                            {item?.employmentDetails?.firstName}{" "}
                            {item?.employmentDetails?.lastName}{" "}
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="userMapCart__contact">
                          <Tooltip
                            title={
                              isCopied ? (
                                "copied"
                              ) : (
                                <div className="userMapCart__contact">
                                  {item?.contactDetails?.phoneNumber}
                                  <ContentCopyIcon
                                    fontSize={"small"}
                                    onClick={() =>
                                      handleCopyToClipboard(
                                        item?.contactDetails?.phoneNumber
                                      )
                                    }
                                  />
                                </div>
                              )
                            }
                          >
                            <PhoneOutlinedIcon />
                          </Tooltip>

                          <Tooltip
                            title={
                              isCopied ? (
                                "copied"
                              ) : (
                                <div className="userMapCart__contact">
                                  {item?.contactDetails?.email}
                                  <ContentCopyIcon
                                    fontSize={"small"}
                                    onClick={() =>
                                      handleCopyToClipboard(
                                        item?.contactDetails?.email
                                      )
                                    }
                                  />
                                </div>
                              )
                            }
                          >
                            <MailOutlineOutlined />
                          </Tooltip>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MessageProviderList;
