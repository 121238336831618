import { useUser } from "@descope/react-sdk";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAllUserProfiles } from "../../../redux/features/user-slice";
import { IUserProfile } from "../../../types/user";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import { MailOutlineOutlined } from "@mui/icons-material";
import { MessageFilter } from "../../../types/message";
import { filterMessage } from "../../../helpers/message-filter-helper/messag-filter-helper";

interface IMessageAdminList {
  selectedUser: { id: string; name: string };
  setSelectedUser: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
      entityType: string;
    }>
  >;
  isMobileView: boolean;
}

const MessageAdminList: React.FC<IMessageAdminList> = ({
  selectedUser,
  setSelectedUser,
  isMobileView,
}) => {
  //#region variable region
  /* Descope Integration */
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const users = useAppSelector<IUserProfile[]>((state) =>
    state.user.tenantUsers.filter(
      (item: any) => item.authRole !== "Parent" && item.authRole !== "Employee"
    )
  );

  //variable to store unreadUsers
  const unreadUsers = useAppSelector<string[]>(
    (state) => state.messageSlice.unreadUserId
  );

  //variable to store search value
  const searchValue = useAppSelector<string>(
    (state) => state.activeStep.searchValue
  );

  //variable to store filterValues
  const filterValues = useAppSelector<MessageFilter>(
    (state) => state.messageFilterSlice
  );

  //#endregion

  //#region methods regions

  //handle selected users
  const handleSelectClient = (ele: IUserProfile) => {
    setSelectedUser({
      id: ele.id,
      name: ele.firstName + " " + ele.lastName,
      entityType: ele.authRole,
    });
  };

  //handle copy to clipboard
  const handleCopyToClipboard = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (!isMobileView) {
      setSelectedUser({
        id: users[0]?.id,
        name: users[0]?.firstName + " " + users[0]?.lastName,
        entityType: users[0]?.authRole,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(
        getAllUserProfiles({
          authTenantId: user.customAttributes?.currentTenantId,
          callerEmail: user.email as string,
          callerName: user.name as string,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length]);

  //#endregion

  return (
    <div className="mapUserList">
      {users?.length > 0 &&
        filterMessage<IUserProfile>(filterValues, searchValue, users).map(
          (item: IUserProfile, index: number) => {
            return (
              <div
                className={
                  selectedUser.id === item.id
                    ? "fromGroup-chip-active-message"
                    : "messageUser"
                }
                key={index}
                onClick={() => {
                  handleSelectClient(item);
                }}
              >
                <div className="cardLevelBlock">
                  <table>
                    <thead className="map-thead">
                      <tr>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {unreadUsers.includes(item?.id) ? (
                              <span
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  background: "#096dd9",
                                  padding: "5px",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <span
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  background: "#096dd9",
                                  padding: "5px",
                                  visibility: "hidden",
                                  marginRight: "10px",
                                }}
                              />
                            )}

                            <span className="userMapCart__img">
                              <img
                                style={{ minWidth: "30px" }}
                                src={item?.profilePictureUrl || ClientImg}
                                className="userSmallCircle"
                                alt=""
                              />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="userMapCart__name">
                            {item?.firstName} {item?.lastName}{" "}
                          </div>
                        </th>
                        <th>
                          <div className="userMapCart__contact">
                            <Tooltip
                              title={
                                isCopied ? (
                                  "copied"
                                ) : (
                                  <div className="userMapCart__contact">
                                    {item?.email}
                                    <ContentCopyIcon
                                      fontSize={"small"}
                                      onClick={() =>
                                        handleCopyToClipboard(item?.email)
                                      }
                                    />
                                  </div>
                                )
                              }
                            >
                              <MailOutlineOutlined />
                            </Tooltip>
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
};

export default MessageAdminList;
