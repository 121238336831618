import React from "react";
import {
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Button,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import { setClientBasicDetails } from "../../../redux/features/client-main/client-slice";

import { OrgBU } from "../../../types/company";
import { getBussinessName } from "../../../constants/common";

import "../../providers/provider/Providers.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  onSubmit?: () => void;
}
const AdminDetails: React.FC<Props> = ({ onSubmit }) => {
  // #region variables region

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  const { activeStep } = useAppSelector((state) => state.activeStep);

  const { clientBasicDetails } = useAppSelector((state) => state.client);

  const { isEditClient } = useAppSelector((state) => state.client);

  const dispatch = useAppDispatch();

  // const [hasError, setHasError] = useState(false);

  // #endregion

  // #region methods region
  const handleChange = (e: any) => {
    const obj = {
      name: e.target.name,
      value: e.target.value,
    };
    dispatch(setClientBasicDetails(obj));
  };
  const handleSubmit = () => {
    // if (clientBasicDetails.businessUnit.length === 0) {
    //   setHasError(true);
    // } else {
    if (isEditClient) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      dispatch(setActiveSteps(activeStep + 1));
    }
    // }
  };

  //used for removing focus
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion
  // console.log("clientBasicDetails.businessUnit:", clientBasicDetails.businessUnit);

  return (
    <React.Fragment>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          {/* <h1 className="stepperForm__title">Administrative Information</h1> */}
          <div className="stepperForm__inner">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box className="fromGroup">
                  <InputLabel
                    id="demo-simple-select-label"
                    className="fromLabel"
                  >
                    Business Unit
                  </InputLabel>
                  <Select
                    className="form-control"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="businessUnit"
                    multiple
                    value={
                      clientBasicDetails.businessUnit?.length > 0
                        ? clientBasicDetails.businessUnit
                        : []
                    }
                    placeholder="Select"
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("demo-simple-select");
                      }
                    }}
                    renderValue={(selected: any) => {
                      selected = selected.map((select: any) => {
                        return getBussinessName(select, businessUnits);
                      });
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                  >
                    {businessUnits.map((item: OrgBU) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={
                              clientBasicDetails.businessUnit.indexOf(item.id) >
                              -1
                            }
                          />
                          <ListItemText primary={item.businessUnitName} />
                        </MenuItem>
                      );
                    })}
                  </Select>

                  {/* {hasError && clientBasicDetails.businessUnit.length === 0 ? (
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      Please select unit
                    </FormHelperText>
                  ) : (
                    ""
                  )} */}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">Concerns</InputLabel>
                  <TextValidator
                    placeholder="Enter Parent’s concerns here..."
                    className="form-control"
                    onChange={handleChange}
                    name="concerns"
                    id="concerns"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("concerns");
                      }
                    }}
                    value={clientBasicDetails.concerns}
                    //change by Hui: make it as optional
                    //validators={["required"]}
                    //errorMessages={["Please enter concerns"]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    How did you hear about us?
                  </InputLabel>
                  <TextValidator
                    placeholder="Facebook, Google Ads, Current or Previous Client, etc."
                    className="form-control"
                    onChange={handleChange}
                    name="hearAboutUs"
                    id="hearAboutUs"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("hearAboutUs");
                      }
                    }}
                    value={clientBasicDetails.hearAboutUs}
                    //change by Hui: make it as optional
                    //validators={["required"]}
                    //errorMessages={["Please enter here about us"]}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            {!isEditClient ? (
              <Button
                type="submit"
                color="inherit"
                disabled={activeStep === 0 ? true : false}
                className="border-button"
                onClick={() => dispatch(setActiveSteps(activeStep - 1))}
              >
                Previous
              </Button>
            ) : (
              ""
            )}

            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              // onClick={() => setHasError(true)}
            >
              {isEditClient ? "Save Changes" : "Next"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </React.Fragment>
  );
};

export default AdminDetails;
