import moment from "moment";
import { convertTime12to24 } from "../constants/schedule";
import { Dispatch, SetStateAction } from "react";
import { ClientForms } from "../types/client";
import { ProviderForms } from "../types/provider";
import ClientImg from "../assets/images/images-svg/client-large.svg";
import ProviderImg from "../assets/images/images-svg/provider-large-vertical.svg";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { availabilityOverlap } from "./AvailabilityUtils";
import { ResourceDetails } from "@syncfusion/ej2-react-schedule";
import { createElement } from "@syncfusion/ej2-base";
import {
  EventRenderedArgs,
  ActionEventArgs,
  RenderCellEventArgs,
  PopupOpenEventArgs,
  // Resize,
  // DragAndDrop,
  // Day,
  // Week,
  // Month,
  // TimelineMonth,
  // Agenda,
  // Schedule,
  // NavigatingEventArgs,
  // CellClickEventArgs,
  // EventClickArgs,
  // ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import {
  // BookedSchedule,
  // MatchedProvider,
  ServiceColor,
} from "../types/schedule";

//component props interface
export interface IBookingService {
  selectedProviders: ProviderForms[];
  selectedClients: ClientForms[];
  clientList?: ClientForms[];
  providerList?: ProviderForms[];
  // setSelectedProviderIds: React.Dispatch<React.SetStateAction<string[]>>;
  // selectedProviderIds: string[];

  isDataEdited: boolean;
  setIsDataEdited: Dispatch<SetStateAction<boolean>>;
}

/*
Deprecated!! Don't why we need this!!
export function showReadOnly(endDate: Date): boolean {
  return endDate < new Date(2021, 6, 31, 0, 0);
}
*/

//handle booked data
export function populateBookedData(
  clientOrProvider: string,
  bookedSlots: any[],
  results: any[]
) {
  let specificDate = new Date();
  let fromTimeStr = "";
  let toTimeStr = "";
  let fromDateTime = new Date();
  let toDateTime = new Date();

  for (let i = 0; i < bookedSlots?.length; i++) {
    specificDate = new Date(
      moment.utc(bookedSlots[i]?.specificDate).format("MM/DD/YYYY")
    );
    fromTimeStr = convertTime12to24(bookedSlots[i]?.fromTime);
    toTimeStr = convertTime12to24(bookedSlots[i]?.toTime);
    const [fromHour, fromMin, fromSec] = fromTimeStr.split(":");
    const [toHour, toMin, toSec] = toTimeStr.split(":");

    fromDateTime = new Date(
      specificDate.getFullYear(),
      specificDate.getMonth(),
      specificDate.getDate(),
      +fromHour,
      +fromMin,
      +fromSec
    );

    toDateTime = new Date(
      specificDate.getFullYear(),
      specificDate.getMonth(),
      specificDate.getDate(),
      +toHour,
      +toMin,
      +toSec
    );

    var bookedBlock = {
      Subject: bookedSlots[i].bookedClientId
        ? `${bookedSlots[i].bookedClientId.childFirstName}${" "}${
            bookedSlots[i].bookedClientId.childLastName
          }${" - "}${bookedSlots[i].bookedProviderId.firstName}${" "}${
            bookedSlots[i].bookedProviderId.lastName
          }`
        : `${bookedSlots[i].bookedProviderId.firstName}${" "}${
            bookedSlots[i].bookedProviderId.lastName
          }`,
      StartTime: fromDateTime.toISOString(),
      EndTime: toDateTime.toISOString(),

      bookedClientId: bookedSlots[i].bookedClientId,
      bookedProviderId: bookedSlots[i].bookedProviderId,

      repeatWorkDays: bookedSlots[i].repeatWorkDays,
      repeatCustomEnd: bookedSlots[i].repeatCustomEnd,
      serviceID: bookedSlots[i].id,
      specificDate: bookedSlots[i].specificDate,
      whoCancelled: bookedSlots[i].whoCancelled,
      cancellationReason: bookedSlots[i].cancellationReason,
      authId: bookedSlots[i].authId,
      authCode: bookedSlots[i].authCode,

      clientId:
        clientOrProvider === "CLIENT" ? bookedSlots[i].bookedClientId?.id : "",

      Repeat: bookedSlots[i].repeatService,
      ServiceType: bookedSlots[i].serviceType,
      Location: bookedSlots[i].serviceLocation,
      Description: bookedSlots[i].description,
      status: bookedSlots[i].status,

      isAllDay: false,
      IsBlock: false, //clientOrProvider === "CLIENT" ? true : false,
      id:
        clientOrProvider === "CLIENT"
          ? bookedSlots[i].bookedClientId?.id
          : bookedSlots[i].bookedProviderId?.id,
      GroupId: clientOrProvider === "CLIENT" ? 3 : 4, //booked slots
    };

    results.push(bookedBlock);
  }
}

export function populateUnavailableData(
  clientOrProviderGroupId: number,
  clientOrProviderId: any,
  timeSlots: any[],
  results: any[]
) {
  const uniqueDates = timeSlots?.filter(
    (arr, index, self) => index === self.findIndex((t) => t.date === arr.date)
  );
  const collectedDates = [];
  for (let i = 0; i < uniqueDates?.length; i++) {
    var weekDayDate = new Date(
      moment(uniqueDates[i]?.date).format("MM/DD/YYYY")
    );

    //stores availblility of clients
    collectedDates.push(weekDayDate.toISOString());

    var selectedTimeSlots = timeSlots.filter(
      (item) => item.date === uniqueDates[i].date
    );

    //deal with multi-time-slots on the same date
    var sortedSlots = selectedTimeSlots;
    if (selectedTimeSlots.length > 1) {
      sortedSlots = selectedTimeSlots.sort((a, b) =>
        convertTime12to24(a.startTime) > convertTime12to24(b.startTime)
          ? 1
          : convertTime12to24(a.endTime) > convertTime12to24(b.endTime)
          ? 1
          : -1
      );
    }

    var midnightDateTime = new Date(
      weekDayDate.getFullYear(),
      weekDayDate.getMonth(),
      weekDayDate.getDate(),
      0,
      0,
      0
    );
    var tomorrowMidnight = new Date(
      midnightDateTime.getTime() + 24 * 60 * 60 * 1000
    );

    for (let s = 0; s < sortedSlots.length; s++) {
      var sBHour = convertTime12to24(sortedSlots[s]?.startTime);
      var sEhour = convertTime12to24(sortedSlots[s]?.endTime);
      const [beginHour, beginMin, beginSec] = sBHour.split(":");
      const [endHour, endMin, endSec] = sEhour.split(":");

      var sBeginDtm = new Date(
        weekDayDate.getFullYear(),
        weekDayDate.getMonth(),
        weekDayDate.getDate(),
        +beginHour,
        +beginMin,
        +beginSec
      );
      var sEndDtm = new Date(
        weekDayDate.getFullYear(),
        weekDayDate.getMonth(),
        weekDayDate.getDate(),
        +endHour,
        +endMin,
        +endSec
      );

      //first timeslot
      if (s === 0) {
        var firstBlock = {
          Subject: "Not Available",
          StartTime: midnightDateTime.toISOString(),
          EndTime: sBeginDtm.toISOString(),
          isAllDay: false,
          IsBlock: true,
          id: clientOrProviderId,
          GroupId: clientOrProviderGroupId,
        };
        results.push(firstBlock);
      }

      //deal with middle timeslots if exists
      if (s + 1 < sortedSlots.length) {
        var s1BHour = convertTime12to24(sortedSlots[s + 1]?.startTime);

        const [beginHour, beginMin, beginSec] = s1BHour.split(":");

        var s1BeginDtm = new Date(
          weekDayDate.getFullYear(),
          weekDayDate.getMonth(),
          weekDayDate.getDate(),
          +beginHour,
          +beginMin,
          +beginSec
        );

        var middleBlock = {
          Subject: "Not Available",
          StartTime: sEndDtm.toISOString(),
          EndTime: s1BeginDtm.toISOString(),
          isAllDay: false,
          IsBlock: true,
          id: clientOrProviderId,
          GroupId: clientOrProviderGroupId,
        };
        results.push(middleBlock);
      } else {
        //now deal with last timeslot
        var lastBlock = {
          Subject: "Not Available",
          StartTime: sEndDtm.toISOString(),
          EndTime: tomorrowMidnight.toISOString(),
          isAllDay: false,
          IsBlock: true,
          id: clientOrProviderId,
          GroupId: clientOrProviderGroupId,
        };
        results.push(lastBlock);
      }
    } //end of per day
  }

  for (
    let currentDate = new Date(collectedDates[0]);
    currentDate <= new Date(collectedDates[collectedDates.length - 1]);
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    if (!collectedDates.includes(currentDate.toISOString())) {
      // checks for non availbility for entire day
      var fullDayBlock = {
        Subject: "Not Available (Full Day)",
        StartTime: currentDate.toISOString(),
        EndTime: new Date(
          currentDate.getTime() + 24 * 60 * 60 * 1000
        ).toISOString(),
        isAllDay: true,
        IsBlock: true,
        id: clientOrProviderId,
        GroupId: clientOrProviderGroupId,
      };
      results.push(fullDayBlock);
    }
  }
}

export const handlePopulateResources = (
  selectedClients: any[],
  selectedProviders: any[],
  fromMatchResults: boolean = false
) => {
  let newList: any[] = [];

  let obj: any;

  //Add Client Into Calendar List
  // filterPlanners(filterValues, searchValue, selectedClients,  renderDateValue !== undefined ? renderDateValue :new Date())
  selectedClients.forEach((selectedClient: any) => {
    obj = {
      name: `${selectedClient?.clientBasicDetails?.childFirstName}${" "}${
        selectedClient?.clientBasicDetails?.childLastName
      }`,
      id: selectedClient?.clientBasicDetails?.id,
      GroupId: 1, //selected provider category
      color: "#865fcf",
      url: selectedClient?.clientBasicDetails?.clientProfile?.url,
      type: "Client",
      hasAuth: selectedClient?.authorizations?.length > 0 ? true : false,
      hasAvailability:
        selectedClient?.availabilityDetails?.length > 0 ? true : false,
      // workDays: selectedClient?.availabilityDetails?.length > 0
      //     ? [] : [1, 2, 3, 4, 5]
    };
    newList.push(obj);
  });

  let availabilityDetails: any;
  selectedProviders.forEach((selectedProvider: any) => {
    //handle both Provider & MatchProvider
    if (fromMatchResults) {
      availabilityDetails = selectedProvider?.providerAvailability;
    } else {
      availabilityDetails = selectedProvider?.availabilityDetails;
    }
    obj = {
      name: `${selectedProvider?.employmentDetails?.firstName}${" "}${
        selectedProvider?.employmentDetails?.lastName
      }`,
      id: selectedProvider?.employmentDetails?.id,
      GroupId: 1, //selected provider category
      color: "#865fcf",
      url: selectedProvider?.employmentDetails?.providerProfile?.url,
      type: "Provider",
      hasAvailability: availabilityDetails?.length > 0 ? true : false,
      // workDays: selectedProvider?.availabilityDetails?.length > 0
      // ? [] : [1, 2, 3, 4, 5]
    };
    newList.push(obj);
  });

  const uniqueValue = [
    ...new Map(newList.map((item) => [item.id, item])).values(),
  ]; //used to prevent duplication

  return uniqueValue;
};

export function getResourceName(value: ResourceDetails) {
  return (value as ResourceDetails).resourceData[
    (value as any).resource.textField
  ] as string;
}

export function getResourceAvailability(value: ResourceDetails) {
  return (value as ResourceDetails).resourceData.hasAvailability;
}

//handle profile img url
export function getResourceImage(value: ResourceDetails) {
  // console.log("value:", value);
  if (value.resourceData.type === "Client") {
    return value.resourceData?.url || ClientImg;
  } else {
    return value.resourceData?.url || ProviderImg;
  }
}

//handle background color for resource cell
export function getColor(value: ResourceDetails): string {
  if (value.resourceData.type === "Client") {
    return "#dafbeb";
  } else {
    return "#f1ecff";
  }
}

export const addgmt = (Time: string) => {
  // (parseInt(a.EndTime) + gmt).toString()
  let Timing = Time.split(":");
  let Hours = parseInt(Timing[0]);
  let Min = parseInt(Timing[1]);
  if (Hours > 0) {
    // Hours = Hours + gmtHours;
    // eslint-disable-next-line no-self-assign
    Hours = Hours;
  }
  let UpdatedHours = Hours.toString();
  let UpdatedMin = Min.toString();
  return `${UpdatedHours}:${Min === 0 ? "00" : UpdatedMin}`;
};

/* deprecate this!
// non availablity decide
export const NotAvailable = (renderedDates: any, matchValues: MatchOption) => {
  let result: boolean = false;

  if (
    matchValues.availability.beginDate !== null &&
    matchValues.availability.endDate !== null
  ) {
    let startDate = new Date(matchValues.availability.beginDate);
    let endDate = new Date(matchValues.availability.endDate);
    let renderDate = new Date(renderedDates[0]);
    if (startDate < renderDate) {
      if (endDate > renderDate) {
        return (result = true);
      }
    }
  }
  return result;
};
*/

//recreate work hours dynamically
export const workHoursDynamic = (
  d1: any,
  selectedClients: any,
  selectedProviders: any,
  fromMatchResults: boolean = false
) => {
  let results: any[] = [];
  let uniqueIds: string[] = [];
  selectedClients.forEach((item: any) => {
    if (uniqueIds.indexOf(item.clientBasicDetails.id) === -1) {
      uniqueIds.push(item.clientBasicDetails.id);
      results.push(
        availabilityOverlap(
          moment(d1).format("MM/DD/YYYY"),
          item?.availabilityDetails
        )
      );
    }
  });

  let availabilityDetails: any;
  selectedProviders.forEach((item: any) => {
    availabilityDetails = fromMatchResults
      ? item?.providerAvailability
      : item.availabilityDetails;

    if (uniqueIds.indexOf(item.employmentDetails.id) === -1) {
      uniqueIds.push(item.employmentDetails.id);
      results.push(
        availabilityOverlap(
          moment(d1).format("MM/DD/YYYY"),
          availabilityDetails
        )
      );
    }
  });

  return results;
};

export function onEventRendered(args: EventRenderedArgs): void {
  if (args.data.ServiceType) {
    let color = "";
    let border = "";
    let s = args.data.ServiceType;
    if (s === ServiceColor.Assessment) {
      if (args.data.status === "Pending") {
        color = "#E6CCFF";
        border = "2px dotted red";
      } else {
        color = "#E6CCFF";
      }
    } else if (s === ServiceColor.DirectTherapy) {
      if (args.data.status === "Pending") {
        color = "#E1F8DC";
        border = "2px dotted red";
      } else {
        color = "#E1F8DC";
      }
    } else if (s === ServiceColor.ParentTraining) {
      if (args.data.status === "Pending") {
        color = "#F2D1CB";
        border = "2px dotted red";
      } else {
        color = "#F2D1CB";
      }
    } else if (s === ServiceColor.ProtocolModification) {
      if (args.data.status === "Pending") {
        color = "#b7e2fc";
        border = "2px dotted red";
      } else {
        color = "#b7e2fc";
      }
    }
    args.element.style.backgroundColor = color;
    args.element.style.color = "#000";
    args.element.style.border = border;

    if (args.data.status === "Cancelled") {
      args.element.style.textDecorationLine = "line-through";
    }
  }
}

//onbinding data of working hours
export function handleDataBinding(
  islayoutChanged: boolean,
  scheduleObj: any,
  selectedClients: any,
  selectedProviders: any,
  fromMatchResults: boolean = false
) {
  if (islayoutChanged && scheduleObj && scheduleObj.current) {
    //1. get rendered dates in current view
    var renderedDates = scheduleObj.current.activeView.getRenderDates();

    //2. ensure to reset all working hours (Must Have!!)
    scheduleObj.current.resetWorkHours(
      "06:00", //same as calendar default start time
      "21:00" //same as calendar default end time;
    );

    //3. get working hours for each resource
    let workingHoursMaps: any[][] = [
      ...workHoursDynamic(
        renderedDates[0],
        selectedClients,
        selectedProviders,
        fromMatchResults
      ),
    ];
    // console.log("workingHoursMaps:", renderedDates, workingHoursMaps);

    for (var i = 0; i < renderedDates.length; i++) {
      try {
        var dayIndex = renderedDates[i].getDay();

        //Deprecate!! No need to control working hours like this!!
        // if (!NotAvailable(renderedDates, matchValues)) {
        //   workingHoursMap.splice(0, 1, NotAvailWork);
        // } else {
        //   workingHoursMap.splice(
        //     0,
        //     1,
        //     matchValues.availability.availabilitySelectedDays
        //   );
        // }

        //4. set working hours for each resource
        for (let j = 0; j < workingHoursMaps.length; j++) {
          if (scheduleObj.current && workingHoursMaps[j][dayIndex]) {
            // console.log("set working hours:", j, renderedDates[i],
            // workingHoursMaps[j][dayIndex].StartTime,
            // workingHoursMaps[j][dayIndex].EndTime);
            if (
              workingHoursMaps[j][dayIndex].StartTime === "00:00" &&
              workingHoursMaps[j][dayIndex].EndTime === "00:00"
            ) {
              scheduleObj.current.setWorkHours(
                [renderedDates[i]],
                "24:00",
                "24:00",
                j //groupIndex = resourceIndex
              );
              // console.log("set whole day: not available 1", j, renderedDates[i]);
            } else {
              // console.log("set working hours:", j,
              // renderedDates[i],
              // workingHoursMaps[j][dayIndex].StartTime,
              // workingHoursMaps[j][dayIndex].EndTime);

              scheduleObj.current.setWorkHours(
                [renderedDates[i]],
                workingHoursMaps[j][dayIndex].StartTime,
                workingHoursMaps[j][dayIndex].EndTime,
                j
              );
            }
          } else {
            scheduleObj.current.setWorkHours(
              [renderedDates[i]],
              "24:00",
              "24:00",
              j //groupIndex = resourceIndex
            );

            // console.log("set whole day: not available 2", j, renderedDates[i]);
          }
        }
      } catch (e: any) {
        console.log("e:", e);
      }
    }
  }
}

//render on every action
export function handleActionBegin(args: ActionEventArgs, scheduleObj: any) {
  try {
    if (args.data !== undefined && scheduleObj) {
      if (
        args.requestType === "eventCreate" ||
        args.requestType === "eventChange"
      ) {
        let data: Record<string, any> | undefined =
          args.data instanceof Array ? args.data[0] : args.data;
        // console.log("cancel data:", data);
        args.cancel = !scheduleObj.current.isSlotAvailable(data);
      }
    }

    //for export excel data
    // if (args.requestType === 'toolbarItemRendering' && args.items) {
    //   let exportItem: ItemModel = {
    //     align: 'Right', showTextOn: 'Both', prefixIcon: 'e-icon-schedule-excel-export',
    //     text: 'Excel Export', cssClass: 'e-excel-export',
    //     click: onExportClick.bind(scheduleObj.current)
    //   };
    //   args.items.push(exportItem);
    // }
  } catch (e: any) {
    console.log("e:", e);
  }
}

// const onExportClick = (scheduleObj: any): void => {
//   if (scheduleObj.current) {
//     scheduleObj.current.exportToExcel();
//   }
// };

export function handleRenderCell(args: RenderCellEventArgs): void {
  if (args.element.classList.contains("e-work-cells")) {
  }
  if (
    args.elementType === "emptyCells" &&
    args.element.classList.contains("e-resource-left-td")
  ) {
    let target: HTMLElement = args.element.querySelector(
      ".e-resource-text"
    ) as HTMLElement;
    target.innerHTML =
      '<div class="name">Person</div><div class="type">List</div>';
  }
}

//function for popup handler
export function handlePopupOpen(
  args: PopupOpenEventArgs,
  setargsData: any,
  setPopup: any,
  setIsDeletePopup: any,
  setIsNewPopUp: any,
  setIsRebooking?: any
): void {
  let data: Record<string, any> = args.data as Record<string, any>;
  setargsData(data);
  const popupFooterElement = args.element?.querySelector(".e-popup-footer");

  try {
    if (setIsRebooking && args.data?.serviceID) {
      const Reschedule = document.createElement("button");
      if (popupFooterElement) {

        Reschedule.innerText = "Re-Schedule";
        Reschedule.className = "e-btn e-css e-primary";
        Reschedule.style.marginLeft = "120px";
        popupFooterElement?.appendChild(Reschedule);
        Reschedule.onclick = () => {
          setIsRebooking(true);
          args.cancel = true;
        };
      }
    }
    // console.log("args:", args);

    if (args.type === "DeleteAlert") {
      setIsDeletePopup(true);
      args.cancel = true;
    }

    if (args.data?.Id) {
      setIsNewPopUp(false); // edit
    } else {
      setIsNewPopUp(true); // new
    }

    if (args.type === "Editor") {
      args.cancel = true;
      setPopup(true); // new

      if (!args.element?.querySelector(".custom-field-row")) {
        let row: HTMLElement = createElement("div", {
          className: "custom-field-row",
        });
        let formElement: any = args.element?.querySelector(".e-schedule-form");
        if (formElement?.firstChild) {
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector(".e-title-location-row")
          );
          let container: HTMLElement = createElement("div", {
            className: "custom-field-container",
          });
          let inputEle: HTMLInputElement = createElement("input", {
            className: "e-field",
            attrs: { name: "ServiceType" },
          }) as HTMLInputElement;
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList: DropDownList = new DropDownList({
            dataSource: [
              { text: "Assessment", value: "assessment" },
              { text: "Direct Therapy", value: "direct-therapy" },
              { text: "Parent Training", value: "parent-training" },
              { text: "Protocol modification", value: "protocol-modification" },
            ],
            fields: { text: "text", value: "value" },
            floatLabelType: "Always",
            placeholder: "Service Type",
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute("name", "ServiceType");
        } else {
          console.log("Form element's first child is not found.");
        }
      }
    }
  } catch (e) {
    console.log("exception:", e);
  }
}

/*
export const footerTemplate = (props) => {
  return (<div className="quick-info-footer">
    {props.elementType === "cell" ?
      <div className="cell-footer">
        <ButtonComponent id="more-details" cssClass='e-flat' content="More Details" onClick={buttonClickActions.bind(this)} />
        <ButtonComponent id="add" cssClass='e-flat' content="Add" isPrimary={true} onClick={buttonClickActions.bind(this)} />
      </div>
      :
      <div className="event-footer">
        <ButtonComponent id="delete" cssClass='e-flat' content="Delete" onClick={buttonClickActions.bind(this)} />
        <ButtonComponent id="more-details" cssClass='e-flat' content="More Details" isPrimary={true} onClick={buttonClickActions.bind(this).bind(this)} />
      </div>}
  </div>);
};

export const quickInfoTemplates = {
  header: headerTemplate,
  content: contentTemplate,
  footer: footerTemplate
};

export const headerTemplate = (props: any) => {
  return (<div className="quick-info-header">
    <div className="quick-info-header-content" style={getHeaderStyles(props)}>
      <div className="quick-info-title">{getHeaderTitle(props)}</div>
      <div className="duration-text">{getHeaderDetails(props)}</div>
    </div>
  </div>);
}

const getHeaderTitle = (data: any) => {
  return (data.elementType === 'cell') 
    ? 'Add Appointment' : 'Appointment Details';
}
const getHeaderDetails = (data: any) => {
  const intl = new Internationalization();
  return intl.formatDate(data.StartTime, { type: 'date', skeleton: 'full' }) + ' (' +
    intl.formatDate(data.StartTime, { skeleton: 'hm' }) + ' - ' +
    intl.formatDate(data.EndTime, { skeleton: 'hm' }) + ')';
}*/
