import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Close, Download } from "@mui/icons-material";

import { RootState } from "../../../redux/store";
import { getOrgInfo } from "../../../redux/features/organization/organization-slice";
import {
  getProducts,
  getTenantUsage,
  getTenantPayments,
  getTenantPlan,
  UpdateSubscription,
} from "../../../redux/features/plan-billing/plan-billing-slice";

import {
  IStripeProduct,
  ITenantUsage,
  IPayment,
  ICurrentPlan,
} from "../../../types/product";

import "./myplan.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

const StyledTable = styled(Table)(() => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

const MyPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orgData = useSelector(
    (state: RootState) => state.organizationSlice.orgData
  );
  const tenantUsage = useSelector<RootState, ITenantUsage>(
    (state) => state.planBillingSlice.tenantUsage
  );
  const products = useSelector<RootState, IStripeProduct[]>(
    (state) => state.planBillingSlice.products
  );
  const payments = useSelector<RootState, IPayment[]>(
    (state) => state.planBillingSlice.payments
  );
  const currentPlan = useSelector<RootState, ICurrentPlan>(
    (state) => state.planBillingSlice.currentPlan
  );

  const [updateAdmin, setUpdateAdmin] = useState(false);
  const [adminSeats, setAdminSeats] = useState<number>(0);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    console.log("calling 1111");
    dispatch(getTenantPlan());
    dispatch(getTenantUsage());
    dispatch(getTenantPayments());
  }, [orgData, products]);

  useEffect(() => {
    if (currentPlan && currentPlan.adminSeats > 0) {
      setAdminSeats(currentPlan.adminSeats);
    }
  }, [currentPlan]);

  // console.log("tenantUsage,currentPlan: ", tenantUsage, currentPlan);

  return (
    <>
      <div className="pageTemplate">
        <Grid container spacing={3}>
          <Grid item xs={12} display={"flex"} gap={"15px"}>
            <Typography variant="h6" className="DetailsPageTitle">
              My Plan
            </Typography>
            <Typography
              variant="h6"
              className="viewAllPlan"
              onClick={() => navigate("/view-plan")}
            >
              View All Plans
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="userPlanCard">
              <div className="userPlanCard__head">
                <Typography className="userPlanCard__title">
                  {currentPlan.currentPlanId !== ""
                    ? currentPlan?.planDetail?.title
                    : "Plan Name"}
                </Typography>
                <button className="userPlanCard__head-btn">
                  {currentPlan.currentPlanId !== ""
                    ? currentPlan?.planDetail?.title
                    : "no plan yet"}
                </button>
              </div>
              <div className="userPlanCard__footer">
                <div className="userPlanCard__price">
                  <strong>
                    {currentPlan.currentPlanId !== "" &&
                    currentPlan.planDetail?.price > 0
                      ? `$ ${currentPlan.planDetail?.price} `
                      : `- `}
                  </strong>
                  / month
                </div>
                <Button
                  className="userPlanCard__footer-btn"
                  onClick={() => navigate("/view-plan")}
                >
                  Update Plan
                </Button>
              </div>
              <div className="userPlanCard__footer">
                <div>
                  Total Admin Seats:{" "}
                  <strong style={{ fontSize: "18px" }}>
                    {currentPlan?.adminSeats > 0
                      ? `${currentPlan.adminSeats}`
                      : `-`}
                  </strong>{" "}
                </div>
                <Typography
                  variant="h6"
                  className="viewAllPlan"
                  onClick={() => setUpdateAdmin(true)}
                >
                  Update Seats
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="userPlanCard">
              <div className="userPlanCard__head">
                <Typography className="userPlanCard__title">
                  Current Usage
                </Typography>
              </div>
              <div className="userPlanCard__body">
                <div className="userPlanCard__body-row">
                  <span className="userPlanCard__body-row-title">
                    Admin Count / Allowed
                  </span>
                  <span className="userPlanCard__body-row-count">
                    {currentPlan && currentPlan.planDetail?.adminCovered > 0
                      ? `${tenantUsage.adminCount} / ${currentPlan.adminSeats}`
                      : `${tenantUsage.adminCount} / no plan yet`}
                  </span>
                </div>
                <div className="userPlanCard__body-row">
                  <span className="userPlanCard__body-row-title">
                    Client Count / Allowed
                  </span>
                  <span className="userPlanCard__body-row-count">
                    {currentPlan && currentPlan.planDetail?.clientLimit > 0
                      ? `${tenantUsage.clientCount} /
                    ${currentPlan.planDetail?.clientLimit}`
                      : `${tenantUsage.clientCount} / no plan yet`}
                  </span>
                </div>
                <div className="userPlanCard__body-row">
                  <span className="userPlanCard__body-row-title">
                    Employee Count
                  </span>
                  <span className="userPlanCard__body-row-count">
                    {tenantUsage.providerCount}
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="listingTable">
            <Typography
              variant="h6"
              className="DetailsPageTitle"
              marginTop={"25px"}
            >
              Payment
            </Typography>
            <TableContainer className="tableResponsive">
              <StyledTable
                sx={{
                  minWidth: 820,
                  borderCollapse: "separate",
                  cursor: "pointer",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Invoice ID</StyledTableCell>
                    <StyledTableCell>Plan</StyledTableCell>
                    <StyledTableCell>Cycle Start</StyledTableCell>
                    <StyledTableCell>Cycle End</StyledTableCell>
                    <StyledTableCell>Invoice Amount</StyledTableCell>
                    <StyledTableCell>Payment Method</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Download</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments?.map((item, index) => (
                    <StyledTableRow style={{ borderRadius: 20 }} key={index}>
                      <StyledTableCell component="th">
                        {item.invoiceNumber}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        {products.find(
                          (product: IStripeProduct) =>
                            product.productId === item.productId
                        )?.title || "Additional Charge"}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        {new Date(item.createdAt).toLocaleDateString("en-US")}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        {new Date(item.endDate).toLocaleDateString("en-US")}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        {item.invoiceTotalAmount < 0
                          ? `-$${Math.abs(item.invoiceTotalAmount / 100)}`
                          : `$${item.invoiceTotalAmount / 100}`}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        Credit Card
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        <Box className="statusChip">{item.invoiceStatus}</Box>
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        <a href={item.pdfUrl}>
                          <IconButton style={{ background: "transparent" }}>
                            <Download />
                          </IconButton>
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={updateAdmin}
        // onClose={() => setUpdateAdmin(false)}
        className="smallModel upgradeModal"
      >
        <div>
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Update Admin Seats
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setUpdateAdmin(false);
                }}
              >
                <Close />
              </Button>
            </Box>
          </Grid>

          <DialogContent>
            <Box>
              You current plan is{" "}
              <b>{`${currentPlan?.planDetail?.title}`} Plan. </b>$
              {currentPlan?.planDetail?.price} / month, up to{" "}
              {currentPlan?.planDetail?.clientLimit} clients,{" "}
              {currentPlan?.planDetail?.adminCovered === 1
                ? "1 admin seat"
                : currentPlan?.planDetail?.adminCovered + " admin seats"}
              . You can add more admin seats if you want.
              <p></p>
            </Box>

            <Box mt={1}>
              <TextField
                value={adminSeats}
                onChange={(e) => {
                  const count = parseInt(e.target.value);
                  if (
                    currentPlan &&
                    count < currentPlan?.planDetail?.adminCovered
                  )
                    return;
                  setAdminSeats(count);
                }}
                label="Total Admin Seats"
                variant="outlined"
                type="number"
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={() => setUpdateAdmin(false)}
            >
              Cancel
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={async () => {
                //update admin seats
                dispatch(
                  UpdateSubscription({
                    productId: currentPlan?.planDetail?.productId,
                    adminSeats: adminSeats,
                  })
                );
                dispatch(getOrgInfo({ useLoader: true }));
                setUpdateAdmin(false);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default MyPlan;
