import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as VectorUp } from "../../../assets/images/images-svg/VectorUp.svg";
import FileUpload from "react-material-file-upload";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  SelectionSettingsModel,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { PostImportRequest } from "../../../redux/features/import-export/import-export-slice";
const ImportDetails: React.FC = () => {
  // #region variables region

  //dispatch
  const dispatch = useAppDispatch();
  const [hasError, setHasError] = useState<boolean>(false);

  //stores files
  const [files, setFiles] = useState<File[]>([]);

  //temp static data
  const pendingReview = [
    {
      dataName: "First Last",
      currentData: "<not exist>",
      file: "abc.csv",
    },
  ];
  // #endregion

  //#region grid region
  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  function dataBound() {
    // console.log("[dataBound]");
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }
  const HeaderAllTemplate = () => {
    return (
      <div style={{ display: "flex", height: " 17px" }}>
        <Checkbox />
        <Typography style={{ fontSize: "14px", fontWeight: "700" }}>
          All
        </Typography>
      </div>
    );
  };
  const Alltemplate = (props: any) => {
    return (
      <div>
        <Checkbox />
      </div>
    );
  };
  const TextTemplate = (props: any) => {
    return (
      <div>
        {props.column.field === "dataName" && (
          <Typography>{props.dataName} </Typography>
        )}
        {props.column.field === "currentData" && (
          <Typography>{props.currentData}</Typography>
        )}
        {props.column.field === "file" && <Typography>{props.file}</Typography>}
      </div>
    );
  };

  const ReviewActionTemplate = (props: any) => {
    return (
      <div>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="Keep Import"
              control={<Radio />}
              label="Keep Import"
            />
            <FormControlLabel
              value=""
              control={<Radio />}
              label="Keep Current"
            />
            {/* <FormControlLabel
              value="Ignore"
              control={<Radio />}
              label="Ignore"
            /> */}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };
  // #endregion

  //#region methods region
  const handleImportFile = (files: any[]) => {
    console.log({ files });
    if (files.length === 1) {
      const formData = new FormData();
      //static data for now will change later.-----
      formData.append("entityType", "Provider");
      formData.append("needReview", "true");
      //-------------------------------------------
      formData.append("file", files[0]);
      dispatch(PostImportRequest(formData));
    } else {
      setHasError(true);
    }
  };
  // #endregion

  return (
    // new UI start
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <div
            className="detailsCard import-card"
            onClick={() => {}}
            style={{ position: "relative" }}
          >
            <div className="detailsCard__header">
              <Typography className="detailsCard__title">
                Upload File
              </Typography>
            </div>
            <div className="detailsCard__body ">
              <Box className="smallCardIcons">
                <VectorUp />
              </Box>
              <Box className="importFileUplaod-block">
                <FileUpload
                  value={files}
                  onChange={(e: File[]) => {
                    setHasError(false);
                    handleImportFile(e);
                  }}
                />
                {hasError && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select one file at a time.
                  </FormHelperText>
                )}
              </Box>
              {/* <p className="smallCardText">{item.desciption}</p> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} className="review-policy">
          <div className="detailsCard" onClick={() => {}}>
            <div className="detailsCard__header">
              <Typography className="detailsCard__title">
                Review Policy
              </Typography>
            </div>
            <Box className="detailsCard__body">
              <Box className="settingBox">
                <div className="settingBox__part">
                  <FormGroup className="settingBox__field field-part">
                    <h6 className="settingBox__field-title">
                      If current data "not exist",set review action
                    </h6>
                    <Box className="customSelectDrp">
                      <Select
                        className="customSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Assessment Request"
                        style={{ minWidth: "190px" }}
                        value={"Load Import"}
                        onChange={(e: SelectChangeEvent<string>) => {}}
                      >
                        <MenuItem value={"Load Import"}>Load Import</MenuItem>
                      </Select>
                    </Box>
                  </FormGroup>
                  <FormGroup className="settingBox__field field-part">
                    <h6 className="settingBox__field-title">
                      If Import data has the same match,set review action
                    </h6>
                    <Box className="customSelectDrp">
                      <Select
                        className="customSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Assessment Request"
                        style={{ minWidth: "190px" }}
                        value={"Keep Current"}
                        onChange={(e: SelectChangeEvent<string>) => {}}
                      >
                        {/* {NewMessageTemplate.filter(
                            (i) => i.methods === "InMail"
                          ).map((item) => ( */}

                        <MenuItem value={"Keep Current"}>Keep Current</MenuItem>
                      </Select>
                    </Box>
                  </FormGroup>
                  <FormGroup className="settingBox__field field-part">
                    <h6 className="settingBox__field-title">
                      If import data has the similar match ,set review action
                    </h6>
                    <Box className="customSelectDrp">
                      <Select
                        className="customSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Assessment Request"
                        style={{ minWidth: "190px" }}
                        value={"Hold For Review"}
                        onChange={(e: SelectChangeEvent<string>) => {}}
                      >
                        {/* {NewMessageTemplate.filter(
                            (i) => i.methods === "InMail"
                          ).map((item) => ( */}

                        <MenuItem value={"Hold For Review"}>
                          Hold For Review
                        </MenuItem>
                      </Select>
                    </Box>
                  </FormGroup>
                </div>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>

      <div className="pending-reviews">
        <Typography className="pendingReview__title">
          Pending Reviews
        </Typography>
        <GridComponent
          id="grid" //must have!!
          className="gridTable"
          dataSource={pendingReview}
          dataBound={dataBound.bind(this)}
          allowPaging={true}
          ref={(grid) => (gridInstance = grid as GridComponent)}
          statelessTemplates={["directiveTemplates"]}
          load={load}
          width="auto"
          height="650"
          allowSelection={false}
          allowGrouping={true}
          groupSettings={groupOptions}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          allowTextWrap={true}
          filterSettings={{ type: "Menu" }}
          textWrapSettings={textWrapSettings}
          allowPdfExport={true}
          allowExcelExport={true}
          pdfExportComplete={pdfExportComplete}
          excelExportComplete={excelExportComplete}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              visible={false}
              headerText="ID"
              isPrimaryKey={true}
              width="130"
            />
            <ColumnDirective
              width="150"
              textAlign="Left"
              field="name"
              template={Alltemplate}
              headerTemplate={HeaderAllTemplate}
            />
            <ColumnDirective
              headerText="Import Data"
              width="150"
              textAlign="Left"
              field="dataName"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="Current Data"
              width="80"
              textAlign="Left"
              field="currentData"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="Import File"
              width="100"
              textAlign="Left"
              field="file"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="Review Action"
              width="230"
              textAlign="Left"
              field="Review Action"
              template={ReviewActionTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Group,
              ContextMenu,
              Toolbar,
              Resize,
              DetailRow,
              Filter,
              Sort,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent>
      </div>

      {/* Previous UI START */}
      {/* <Grid> */}
      {/* <div className="backHead">
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            backToTools(e)
          }
          className="backBtn"
          startIcon={<KeyboardBackspace />}
          style={{ marginBottom: 0 }}
        >
          Back
        </Button>
      </div>

      <div className="detailsCard" style={{ marginBottom: "48px" }}>
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">Import Details</Typography>
        </div>
        <div className="detailsCard__body">
          <div className="detailsCard__stepper">
            <Stepper activeStep={activeStep} setActiveStep={setActiveStep} />
          </div>
          <Box gridColumn="span 12">
            {activeStep === 2 ? <CountsList /> : <ImportUpload setActiveStep={setActiveStep}/>}
          </Box>
        </div>
      </div> */}
      {/* {activeStep === 2 ? <ReviewList /> : ""} */}
      {/* </Grid> */}

      {/* Previous UI END  */}
    </div>
  );
};

export default ImportDetails;
