import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  GetFormById,
  GetFormCards,
} from "../../../redux/features/forms/form-slice";

import AssessmentModel from "../subComponent/AssessmentModel";

import { IFormCard } from "../../../types/forms";

import { ReactComponent as LeftArrow } from "../../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/images-svg/rightArrow.svg";
import settingsIcon from "../../../assets/images/images-svg/SettingsIcon.svg";

import "survey-core/defaultV2.min.css";
import "survey-react/survey.css";
import "../Assessment.scss";

const AssessmentForms: React.FC = () => {
  //#region variable region
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  //store value open popup
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  //handles preview modal
  const [open, setOpen] = useState<boolean>(false);

  //used to display number cards based on screen size
  const [isDesktop, setDesktop] = useState(4);

  //set active Index for show and hide scroll buttons
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  //fetch Assessmentforms data
  const formData = useAppSelector((state) => state.formSlice.AssessmentCards);

  //fetch hasSetting data
  const hasSetting = useAppSelector((state) => state.formSlice.hasSetting);

  //stores assessment form data
  const [survey, setSurvey] = useState<any>(null);

  //stores selected formId
  const [selectedForm, setSelectedForm] = useState({
    id: "",
    name: "",
    url: "",
    embeddedCode: null,
    isJot: true,
    isComplete: false,
    formType: "",
    formCategory: "",
    formSchema: {},
    frontegg_tenantId: "",
    descope_tenantId: "",
    active: false,
    createdAt: "",
    updatedAt: "",
  });

  //#endregion

  //#region methods region

  //handles assessment form preview
  const handlePreview = async (id: string) => {
    const data = await dispatch(GetFormById({ formId: id }));
    setSurvey(new Model(data.payload.formSchema));

    setOpen(true);
  };

  //Left scroll button popup handle
  const funcLeft = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < isDesktop) {
      return true;
    } else {
      return true;
    }
  };

  // Right scroll button popup handle
  const funcRight = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < formData?.length) {
      return true;
    }
    return true;
  };

  //Right scroll button Click handle
  const handleRight = () => {
    if (activeIndex) {
      if (activeIndex + isDesktop < formData?.length) {
        setActiveIndex(activeIndex + isDesktop);
      } else {
        setActiveIndex(formData?.length - 1);
      }
    }
  };

  //Left scroll button Click handle
  const handleLeft = () => {
    if (activeIndex) {
      if (activeIndex - isDesktop + 1 >= isDesktop) {
        setActiveIndex(activeIndex - isDesktop);
      } else {
        setActiveIndex(isDesktop - 1);
      }
    }
  };

  //#endregion

  //#region useEffect region

  // fetch AssessmentForm Data
  useEffect(() => {
    dispatch(GetFormCards({ formType: "Assessment Form" }));
  }, [dispatch, formData?.length]);

  //hanles survey modal display mode
  useEffect(() => {
    if (survey) {
      survey.questionsOnPageMode = "singlePage";
      survey.mode = "display";
    }
  }, [survey]);

  useEffect(() => {
    if (formData && formData?.length > isDesktop) {
      setActiveIndex(isDesktop - 1);
    }
    // eslint-disable-next-line
  }, [formData?.length, isDesktop]);

  //handle inner width
  useEffect(() => {
    if (window.innerWidth > 1240) {
      setDesktop(4);
    } else if (window.innerWidth > 991) {
      setDesktop(3);
    } else if (window.innerWidth > 650) {
      setDesktop(2);
    } else {
      setDesktop(1);
    }

    const updateMedia = () => {
      if (window.innerWidth > 1240) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  //#endregion

  return (
    <>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            fontWeight="bold"
            className="listingTable__title"
          >
            Assessment Forms
          </Typography>
          {/* {!hasSetting && ( */}
          <Button
            type="submit"
            style={{ backgroundColor: "white", gap: "5px" }}
            onClick={() =>
              navigate("/business-settings", {
                state: {
                  tab: "Communication",
                  type: "Assessment Form", //must match with group name!
                },
              })
            }
          >
            <img
              className="settingsIcon"
              src={settingsIcon}
              alt="settingsIcon"
            />
            <span style={{ color: "#096dd9" }}>Communication Setting</span>
          </Button>
          {/* )} */}
        </div>
      </Grid>

      <Grid container spacing={3} mt="-5px">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={130}
          totalSlides={formData?.length}
          visibleSlides={isDesktop}
          step={isDesktop}
          orientation={"horizontal"}
          className="formCardSlider"
        >
          <Slider>
            {formData
              ?.filter((item) => item.active)
              .map((item: IFormCard, index: number) => {
                return (
                  <Slide
                    index={index}
                    key={index}
                    style={{ margin: "20px -1px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "15px", boxShadow: "none" }}
                      key={index}
                    >
                      <div className="documentCard">
                        <div className="documentCard__img">
                          <div className="documentCard__img-card">
                            <p>{item.formCategory}</p>
                          </div>
                        </div>
                        <div className="documentCard__btn">
                          <span
                            style={{
                              backgroundColor: "white",
                              color: "#096dd9",
                              border: "1px solid #096dd9",
                            }}
                            onClick={() => {
                              handlePreview(item.id);
                            }}
                          >
                            Preview
                          </span>

                          <span
                            style={{ backgroundColor: "#096dd9" }}
                            onClick={() => {
                              setOpenPopup(true);
                              setSelectedForm(item);
                            }}
                          >
                            Send
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Slide>
                );
              })}
          </Slider>
          {funcLeft() && (
            <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
              <LeftArrow />
            </ButtonBack>
          )}
          {funcRight() && (
            <ButtonNext className="sliderNavBtn" onClick={handleRight}>
              <RightArrow />
            </ButtonNext>
          )}
        </CarouselProvider>
      </Grid>

      {openPopup && (
        <Dialog open={openPopup} className="smallModel--assessment">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              {"Send Assessment -"}
              {selectedForm.formCategory}
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <AssessmentModel
            selectedFormId={selectedForm.id}
            setOpenPopup={setOpenPopup}
          />
        </Dialog>
      )}

      {open && (
        <Dialog open={open} className="smallModel--assessment">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              Preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <div>
            <Survey id="id" model={survey} className="survey" />
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              // margin: "10px 20px",
              padding: "7px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default AssessmentForms;
