import React, { useState } from "react";
import { Box } from "@mui/material";
import SelectedTeam from "./SelectedTeam";
import { IPlanDetail } from "../../types/planning";
import InLoader from "../../layouts/loader/InLoader";

interface IPlanningChart {
  planDetail: IPlanDetail;
}
const PlanningChart: React.FC<IPlanningChart> = ({ planDetail }) => {
  //#region variable region

  //used to access the loader
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoader, setIsLoader] = useState<boolean>(false);

  //fetch planner by Id
  // const getAllPlan = useCallback(async () => {
  //   setIsLoader(true);

  //   try {
  //     const { data } = await getPlanById(planId);
  //     if (data) {
  //       setPlanChart(data);
  //     }
  //   } catch (error: any) {
  //     console.log(error);
  //   } finally {
  //     setTimeout(() => {
  //       setIsLoader(false);
  //     }, 500);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //#endregion

  //#region useEffect region
  // useEffect(() => {
  //   if (planId !== "1") {
  //     getAllPlan();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //#endregion

  return (
    <Box p={"40px 25px"}>
      {isLoader ? (
        <InLoader isShow={isLoader} />
      ) : (
        //<SelectedTeam planDetail={planId !== "1" ? planDetail : ""} />
        <SelectedTeam key={planDetail.plan.id} planDetail={planDetail} />
      )}
    </Box>
  );
};

export default PlanningChart;
