import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { ClientForms } from "../../../types/client";
import { batch, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { IPlanDetail, PlannerFilter } from "../../../types/planning";
import {
  BookedSchedule,
  // MatchOption,
  MatchedProvider,
} from "../../../types/schedule";
import {
  ScheduleList,
  getAllBookingsData,
} from "../../../redux/features/schedules-main/schedule-list-slice";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Inject,
  ResourcesDirective,
  ResourceDirective,
  // ActionEventArgs,
  RenderCellEventArgs,
  PopupOpenEventArgs,
  Resize,
  DragAndDrop,
  Day,
  Week,
  Month,
  Year,
  TimelineViews,
  // TimelineMonth,
  Agenda,
  Schedule,
  NavigatingEventArgs,
  DragEventArgs,
  // CellClickEventArgs,
  // EventClickArgs,
  // ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import {
  populateBookedData,
  IBookingService,
  getResourceName,
  getResourceImage,
  getColor,
  onEventRendered,
  handleDataBinding,
  // handleActionBegin,
  handleRenderCell,
  handlePopupOpen,
  handlePopulateResources,
} from "../../../utils/CalendarUtils";
import { Box, Switch, Typography } from "@mui/material";
import DiscoverBookingEditModal from "../../schedules-main/booking-scheduler/DiscoverBookingEditModal";
import { setSelectedPlan } from "../../../redux/features/plannings/planning-slice";
import { filterSchedulesByIds } from "../../../utils/ScheduleUtils";
import { Internationalization } from "@syncfusion/ej2-base";
import Loader from "../../../layouts/loader/Loader";
import { getPlanById } from "../../../service/planning.service";
import { editService } from "../../../service/schedules.service";
import { NewDataCatalog } from "../../../types/businessSettings";
import moment from "moment";

//client specific calendar
const PlanningCalenderNew: React.FC<IBookingService> = ({
  selectedClients,
  selectedProviders,
  isDataEdited,
  setIsDataEdited,
}) => {
  //#region variable region

  //dispatch for redux
  // eslint-disable-next-line
  const dispatch = useDispatch();

  //used to access the client data
  const clientData = useSelector<RootState, ClientForms>(
    (state) => state.clientListSlice.singleClient
  );

  //stores current plan
  const currentPlan = useSelector<RootState, IPlanDetail>(
    (state) => state.planSlice.currentPlan
  );

  //stores list data
  const Bookeddata = useSelector<RootState, BookedSchedule[]>(
    (state) => state.scheduleListSlice.bookedListData
  );

  //used to store data local booking
  const localBooked = useSelector<RootState, ScheduleList>(
    (state) => state.scheduleListSlice
  );

  //Variable stores filtervalues
  const filterValues = useSelector<RootState, PlannerFilter>(
    (state) => state.planningFilterSlice.PlanningFilter
  );

  //Variable stores searchValues
  // eslint-disable-next-line
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  // const renderDateValue = useSelector<RootState, Date>(
  //   (state) => state.planningFilterSlice.changedDate
  // );

  //store all billable data
  const BillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.BillableCatalog
  );

  var islayoutChanged = true;
  // const todayValue = new Date(renderDateValue).getDay();

  //for loader component
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //show all booking data
  const [showAllBookings, setShowAllBookings] = useState<boolean>(false);

  //argdata store
  const [argsdata, setargsData] = useState<Record<string, any>>();

  //stores booking data for calendar
  const [eventData, setEventData] = useState<Record<string, any>[]>([]);

  //new and edit popup handler
  const [popup, setPopup] = useState<boolean>(false);

  //delete popup handler
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);

  //decide new and edit true value
  const [isNewPopup, setIsNewPopUp] = useState<boolean>(false);

  //for selected provider
  // eslint-disable-next-line
  const [providers, setProviders] = useState<MatchedProvider>();

  //***********populate data *******************/
  // unique combined list for calendar
  const [listForCalendar, setListForCalendar] = useState<any[]>([]);

  // // Booked Block Data
  // const [clientBooked, setClientBooked] = useState<any[]>([]);

  // //handle Provider Booked data
  // const [providerBooked, setProviderBooked] = useState<any[]>([]);

  //handle workHours mapping
  // const [workingHoursMap, setWorkingHoursMap] = useState<any[][]>([]);

  const [weekController, setWeekController] = useState(true);

  const [isAPICalled, setIsAPICalled] = useState(false);
  //match values
  // const matchValues = useSelector<RootState, MatchOption>(
  //   (state) => state.matchSlice
  // );

  // eslint-disable-next-line
  // const [work, setWork] = useState<any[][]>([]);

  //#region methods region

  //--------------------------------------------------------------
  //Customize calendar resource headers
  function resourceHeaderTemplate(props: any): JSX.Element {
    return (
      <div className="template-wrap">
        <div
          className="person-name meetingPersonBox"
          style={{
            background: getColor(props),
          }}
        >
          <img src={getResourceImage(props)} height={30} width={30} />
          <span>{getResourceName(props)}</span>
        </div>
      </div>
    );
  }

  // const headerTooltipTemplate = (props: any): string => {
  //   return `<div className="template-wrap">
  //     <div className="room-name">${getResourceName(props)}</div>
  //     </div>`;
  // };

  //--------------------------------------------------------------
  //used for access features
  let schedule: any = new Schedule({
    height: "650px",
    width: "100%",
  });
  //ref for scheduleObj
  let scheduleObj = useRef<ScheduleComponent>(schedule);

  //----------------------------------------------------------------
  //this is to populate work hours!!
  const onDataBinding = useCallback(() => {
    handleDataBinding(
      islayoutChanged,
      scheduleObj,
      selectedClients,
      selectedProviders
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islayoutChanged, selectedClients, selectedProviders, Bookeddata]);

  //render every cell
  const onRenderCell = useCallback((args: RenderCellEventArgs) => {
    handleRenderCell(args);
  }, []);

  const onPopupOpen = useCallback((args: PopupOpenEventArgs) => {
    handlePopupOpen(
      args,
      setargsData,
      setPopup,
      setIsDeletePopup,
      setIsNewPopUp
    );
  }, []);

  let instance = useMemo(() => {
    return new Internationalization();
  }, []);
  const getDateHeaderText = useCallback(
    (value: any) => {
      return instance.formatDate(value, { skeleton: "Ed" });
    },
    [instance]
  );
  const dateHeaderTemplate = (props: any) => {
    return (
      <div>
        <div>{getDateHeaderText(props.date)}</div>
      </div>
    );
  };

  const onDragStart = (args: DragEventArgs) => {
    args.navigation = { enable: true, timeDelay: 4000 };
  };

  const onDragStop = async (args: DragEventArgs): Promise<void> => {
    if (isAPICalled) return;
    setIsAPICalled(true);

    let category;
    let result = BillableCatalog.find(
      (item: any) => item.conceptValue === args.data.ServiceType
    );
    if (result !== undefined) {
      category = "Billable";
    } else {
      category = "Non Billable";
    }

    let bookingData = {
      serviceId: args.data?.serviceID,
      fromTime: moment(args.data.StartTime).format("hh:mm A"),
      toTime: moment(args.data.EndTime).format("hh:mm A"),
      updateOption: args.data?.updateOption ? args.data.updateOption : 0,
      specificDate: args.data.specificDate, // must pass it as local dtm!!!
      bookedClientId: args.data.bookedClientId?.id,
      bookedProviderId: args.data.bookedProviderId?.id,
      serviceType: args.data.ServiceType,
      serviceLocation: args.data.Location,
      description: args.data.Description,
      authId: args.data.authId,
      authCode: args.data.authCode,
      category: category,
    };

    const updatedEventData = eventData.map((item) => {
      if (item.serviceID === args.data.serviceID) {
        return {
          ...item,
          StartTime: args.data.StartTime,
          EndTime: args.data.EndTime,
        };
      } else {
        return item;
      }
    });
    setEventData(updatedEventData);
    try {
      await editService(bookingData, true);
      await dispatch(getAllBookingsData());
      // Allow the default action to place the appointment in the new slot
    } catch (error) {
      console.error("Error updating service:", error);
      args.cancel = true; // Cancel the drop action if there is an error
    } finally {
      args.cancel = false;
      setIsAPICalled(false);
    }
  };

  // schedule component
  // const Scheduler = (data: Record<string, any>[]) => {
  // return (
  const Scheduler = React.useMemo(
    () => (
      <ScheduleComponent
        cssClass="block-events"
        ref={scheduleObj}
        width="100%"
        height="650px"
        rowAutoHeight={true}
        allowMultiDrag={true}
        // workDays={weekController ? [0, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5]}
        showWeekend={weekController}
        // workHours={{ highlight: true, start: "06:00", end: "21:00" }}
        //timezone="UTC"
        currentView="TimelineDay"
        resourceHeaderTemplate={resourceHeaderTemplate}
        eventSettings={{
          dataSource: eventData, //[...eventData],
          fields: {
            id: "Id",
            subject: { title: "Summary", name: "Subject" },
            isAllDay: { name: "IsAllDay" },
            location: { title: "Location", name: "Location" },
            endTimezone: { name: "TimeZone" },
            startTimezone: { name: "TimeZone" },
            description: { title: "Description", name: "Description" },
            startTime: { title: "From", name: "StartTime" },
            endTime: { title: "To", name: "EndTime" },
          },
        }}
        // showQuickInfo={false}
        // eventClick={onEventClick.bind(this)}
        // cellClick={onCellClick.bind(this)}
        eventRendered={onEventRendered.bind(this)}
        popupOpen={onPopupOpen.bind(this)}
        renderCell={onRenderCell.bind(this)}
        dataBinding={onDataBinding.bind(this)}
        dragStart={onDragStart}
        dragStop={onDragStop}
        group={{
          resources: ["CalendarList"],
          //enableCompactView: true,
          // headerTooltipTemplate: headerTooltipTemplate.bind(this), //CTO Review: didn't work? why
        }}
        dateHeaderTemplate={dateHeaderTemplate}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="id"
            title="Provider"
            name="CalendarList"
            allowMultiple={true}
            dataSource={listForCalendar}
            textField="name"
            idField="id"
            groupIDField="GroupId"
            colorField="color"
            workDaysField="workDays"
            startHourField="startHour"
            endHourField="endHour"
          ></ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective
            option="TimelineDay"
            startHour="06:00"
            endHour="21:00"
            timeScale={{ interval: 30, slotCount: 2 }}
          />
          {/* <ViewDirective 
            option="TimelineWeek" 
            startHour="06:00" endHour="21:00"
          />
          <ViewDirective 
            option="TimelineMonth" 
            showWeekNumber={true}
            // startHour="06:00" endHour="21:00"
          /> */}
          <ViewDirective
            option="Day"
            startHour="06:00"
            endHour="21:00"
            timeScale={{ interval: 30, slotCount: 2 }}
          />
          <ViewDirective
            option="Week"
            startHour="06:00"
            endHour="21:00"
            timeScale={{ interval: 30, slotCount: 2 }}
          />
          <ViewDirective option="Month" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Year" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Agenda" startHour="06:00" endHour="21:00" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            Month,
            Year,
            TimelineViews, //day & week
            // TimelineMonth,
            Resize,
            DragAndDrop,
            Agenda,
          ]}
        />
      </ScheduleComponent>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData, listForCalendar]
  );

  //--------------------------------------------------------------
  const filteredClientSchedules = useSelector((state: RootState) => {
    // console.log("[filteredClientSchedules]");
    const today = new Date();
    const clientsId = selectedClients.map(
      (item) => item?.clientBasicDetails?.id
    );

    return filterSchedulesByIds(
      Bookeddata,
      clientsId, //client Id List
      [], //providerId List
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );
  });

  const filteredProviderSchedules = useSelector((state: RootState) => {
    // console.log("[filteredProviderSchedules]");
    const today = new Date();
    const providersId = selectedProviders.map(
      (item: any) => item?.employmentDetails?.id
    );
    return filterSchedulesByIds(
      Bookeddata,
      [], //client Id List
      providersId, //providerId List
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );
  });

  // The rows we need to show in the calendar
  const populateCalendarListData = useCallback(() => {
    // console.log("[populateCalendarListData]");
    const resourceList = handlePopulateResources(
      selectedClients,
      selectedProviders
    );
    return resourceList;
  }, [selectedClients, selectedProviders]);

  const populateClientBookedData = useCallback(() => {
    // console.log("[populateClientBookedData]");
    let clientResults: any[] = [];
    let bookSchedule: any[] = [];

    if (localBooked.BookedStore.localBookedStore.length > 0) {
      // let localSchedule = [localBooked.localBookedStore];
      bookSchedule = [
        ...filteredClientSchedules,
        ...localBooked.BookedStore.localBookedStore,
      ];
    } else {
      bookSchedule = [...filteredClientSchedules];
    }

    populateBookedData(
      "CLIENT",
      //clientData.bookedServices,
      // filteredClientSchedules,
      bookSchedule,
      clientResults
    );

    return clientResults;

    //setClientBooked(clientResults);
  }, [localBooked.BookedStore?.localBookedStore, filteredClientSchedules]);

  //handle provider booked data
  const populateProviderBookedData = useCallback(() => {
    // console.log("[populateProviderBookedData]");
    let providerResults: any[] = [];

    let bookSchedule: any[] = [];
    if (localBooked.BookedStore.localBookedStore.length > 0) {
      // let localSchedule = [...localBooked.localBookedStore];
      bookSchedule = [
        ...filteredProviderSchedules,
        ...localBooked.BookedStore.localBookedStore,
      ];
    } else {
      bookSchedule = [...filteredProviderSchedules];
    }

    populateBookedData("PROVIDER", bookSchedule, providerResults);

    return providerResults;
    // setProviderBooked(providerResults);
  }, [localBooked.BookedStore?.localBookedStore, filteredProviderSchedules]);

  //#region end region

  //#region useEffect region

  useEffect(() => {
    let providerList: string[] = [];
    let clientList: string[] = [];

    selectedClients.forEach((item: any) => {
      clientList.push(item?.clientBasicDetails?.id);
    });

    selectedProviders.forEach((item: any) => {
      providerList.push(item?.employmentDetails?.id);
    });

    dispatch(
      setSelectedPlan({
        providerList: providerList,
        clientList: clientList,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients, selectedProviders]);

  //----------------------------------------------------------------
  //Stage 1: render resource list first
  useEffect(() => {
    batch(() => {
      setIsLoading(false);
      setShowAllBookings(false); //must have!!
    });

    if (clientData !== undefined) {
      // console.log("populate resources...");
      const resourceList = populateCalendarListData();

      batch(() => {
        setListForCalendar(resourceList);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.ScheduleStatus, selectedClients, selectedProviders]);

  //Stage 2: wait for resourceList & bookeddata complete, setIsLoading
  useEffect(() => {
    if (clientData !== undefined) {
      // console.log("reset isLoading...");

      batch(() => {
        setIsLoading(true); //set ready to load scheduler data
      });
    }
  }, [listForCalendar, Bookeddata]);

  //Stage 3a: wait for isLoading complete, populate event data
  //Stage 3b: wait for isLoading complete, populate work hours in OnDataBinding
  useEffect(() => {
    if (isLoading === true) {
      // console.log("begin scheduler...");

      const clientResults = populateClientBookedData();
      const providerResults = populateProviderBookedData();

      batch(() => {
        // setEventData(()=>{return[...clientResults, ...providerResults]}); //note: function update safer!!
        setEventData([...clientResults, ...providerResults]);
        setIsLoading(false);
        setShowAllBookings(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  //#endregion
  // console.log("isLoading:", resourceListChanged, isLoading, renderComplete,
  // listForCalendar?.length, Bookeddata?.length, eventData?.length);

  // console.log("currentPlan:", currentPlan);

  return (
    <>
      <div className="schedule-control-section">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Typography sx={{ padding: "0 10px", justifyContent: "start" }}>
            {currentPlan?.pendingCount} Pending Schedules
          </Typography>
          <Typography sx={{ padding: "0 10px", fontWeight: "bold" }}>
            Show Weekend
          </Typography>
          <Switch
            sx={{
              "--Switch-thumbSize": "28px",
            }}
            checked={weekController}
            onChange={() => setWeekController(!weekController)}
          />
        </Box>
        {isLoading && <Loader isShow={isLoading} />}
        {/* after loading, show calendar component */}
        {showAllBookings === true && (
          <div className="col-lg-12 control-section">
            <div className="control-wrapper">{Scheduler}</div>
          </div>
        )}
        {popup && (
          <DiscoverBookingEditModal
            pageTitle={isNewPopup ? "Add Booking" : "Update Booking"}
            clientData={clientData}
            provider={selectedProviders[0]}
            isOpen={popup}
            isEdit={isNewPopup ? false : true}
            isNew={isNewPopup ? true : false}
            closePopup={setPopup}
            argsdata={argsdata}
            // Bookeddata={Bookeddata}
            isDataEdited={isDataEdited}
            setIsDataEdited={setIsDataEdited}
            allSelectedClients={selectedClients}
            providersData={selectedProviders}
            fromPlanning={true}
            isAutoPlanned={false}
          />
        )}
        {isDeletePopup && (
          <>
            <DiscoverBookingEditModal
              pageTitle={"Cancel Booking"}
              clientData={clientData}
              provider={selectedProviders[0]}
              isOpen={popup}
              isEdit={false}
              isNew={false}
              isDelete={true}
              closePopup={setIsDeletePopup}
              argsdata={argsdata}
              // Bookeddata={Bookeddata}
              isDataEdited={isDataEdited}
              setIsDataEdited={setIsDataEdited}
              allSelectedClients={selectedClients}
              providersData={selectedProviders}
              fromPlanning={true}
              isAutoPlanned={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PlanningCalenderNew;
