import {
  ClientAuthPayload,
  ClientBasicDetails,
  ClientContactDetails,
  ClientFilters,
} from "./client";
import { AvailabilityDetails, SelectedDay } from "./availability";
import { ContactDetails, EmploymentDetails } from "./provider";
import { UtilizationResult } from "./utilization";
import { ISchedules } from "./planning";

//Insurance Authorization Interface
export interface Insurance {
  key: number;
  value: string;
}

//ProviderList Dropdown interface
export interface ProviderType {
  id: string;
  conceptValue: string;
}

//Distance Interface
export interface Miles {
  key: number;
  value: number;
}

//Distance Interface
export interface Durations {
  key: number;
  value: number;
}
// export interface ClientAuth {
//   authCode: string;
//   units: string;
// }

//SchedulesSlice InitialState Interface
export interface MatchOption {
  insuranceAuthorization: string;
  selectedAvailability: (SelectedDay | null)[];
  availability: AvailabilityDetails; //for 1 selected availability detail
  insuranceProvider: string;
  miles: number;
  providerType: string[];
  specialities: string[];
  selectedProvider: any[];
  businessUnit: string; //id version
  businessUnitName: string; //name version
  providerOnboardingStatus: string;
  providerOnboardingStages: string[];

  //allocation preference
  duration: number; //in hours
  timeOfDay: string; //morning, afternoon, any
  earliestStartTime: string;
  // newSessionsPerWeek: number;         //# of sessions per week we can allocate
  newSessionsPerDay: number; //# of sessions we can allocate per day
  newSessionsPerDayMorning: number; //# of sessions we can allocate in the morning
  newSessionsPerDayAfternoon: number; //# of sessions we can allocate in the morning

  //client auth matching params
  clientAuthorization: string; //design 1 dropdown string
  clientAuthStartDate: string;
  clientAuthEndDate: string;
  clientAuthId: string;
  clientAuthInsuranceName: string;
  clientAuthCode: string;
  clientSelectedAuthorizationName: string;
  clientAuthUnits: string; //remaining units

  clientAuthCodeHours: number; //for visibility purpose
  clientAuthCodeRemainingHours: number; //for visibility purpose

  clientAuthUtilization: UtilizationResult;
  clientUtilization: UtilizationResult;
  providerUtilization: UtilizationResult;
}

//Enum to get days from the selected days
export enum Days {
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
}

//Availability heatmap Values Interface
export interface ScheduleDates {
  date: string;
  time: string;
  //count: number;
}

//Providers Types Interface
// export interface ProviderType {
//   key: number;
//   value: string;
// }

//short months enum
export enum Months {
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
}

//long month enums
export enum FullMonths {
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
}

export interface ScheduleFilters extends ClientFilters {
  providerStatus: string[];
  onBoardingStage: string[];
}

// export interface ScheduleEmployment extends EmploymentDetails {
//   matchOn: string[];
//   distance: string;
//   providerRemainingHours: number;
//   providerTotalAvailableHours: number;
//   providerTotalBookedHours: number;
//   providerWeeksHaveBooked: number;
//   providerTotalWeeksChecked: number;
// }

export interface MatchedProvider {
  matchOn: string[];
  distance: string;
  score: number;
  employmentDetails: EmploymentDetails;
  // providerAvailability: AvailabilityDetails[]; //show all availability details for provider
  providerContact: ContactDetails;
  providerUtilization: UtilizationResult; //show all utilization for provider
  availabilityDetails: AvailabilityDetails[];
}

export interface IAutoPlanData {
  checkStartDate: Date;
  checkEndDate: Date;
  remainingAuthHours: number;
  clientUtilization: UtilizationResult; //request=client match
  providerUtilization: UtilizationResult; //request=provider match

  totalProvidersCount: number;
  baseProvidersCount: number;
  baseClientsCount: number;
  totalClientsCount: number;
  baseClients: BaseClient[];
  baseProviders: BaseProvider[];
  providerFitResults: AutoPlanResult[];

  weekDayResults: WeekDayResult[];
}

export interface WeekDayResult {
  clientId: string;
  clientAuth: any;
  authCode: string;
  remainingAuthHours: number;
  authorizations: ClientAuthPayload[];

  providerId: string;
  dayOfWeek: string;
  weeks: number;
  dates: string[];
  time: string[];
}

export interface Slot {
  date: string;
  dayOfWeek: string;
  startTime: string;
  endTime: string;
}

export interface BaseProvider {
  employmentDetails: EmploymentDetails;
  distance: string;
  matchOn: string[];
  score: number;
  providerContact: ContactDetails;
  availabilityDetails: AvailabilityDetails[];
  providerUtilization: UtilizationResult;
}

export interface BaseClient {
  clientBasicDetails: ClientBasicDetails;
  availabilityDetails: AvailabilityDetails[];
  clientContactDetails: ClientContactDetails;
  authorizations: ClientAuthPayload[];
  clientUtilization: UtilizationResult;
  clientAuthUtilization: UtilizationResult;
  remainingAuthHours: number;
  distance: string;
  matchOn: string[];
  score: number;
}
export interface AutoPlanResult {
  providerId: string;
  isSlotAvailable: boolean;
  errorMessage: string;
  notFitBookSlots: any[];
  fitBookSlots: any[];
}

export interface MatchResult {
  providerName: string;
  clientName: string;
  dayOfWeek: string;
  distance: string;
  weeks: number; //# of weeks
  dates: string[];
  time: string[];
  matchOn: string[];
  score: number;
  baseProvider: BaseProvider | null;
  baseClient: BaseClient | null;

  authCode: string; //lookup auth code
  remainingAuthHours: number;
  authorizations: ClientAuthPayload[];

  weeklyAvgRemainedByAvailabilityForProvider: number;
  weeklyAvgBookedForProvider: number;
  utilizationPcntByAvailabilityForProvider: number;

  weeklyAvgRemainedAuthForClient: number;
  weeklyAvgBookedForClient: number;
  utilizationPcntByAuthorizationForClient: number;
}

export interface BookedSchedule {
  bookedClientId: ClientBasicDetails;
  bookedProviderId: EmploymentDetails;
  createdBy: string;
  createdAt: string;
  fromTime: string;
  toTime: string;
  id: string;
  repeatEndsOn: string;
  //repeatOn: string[];
  repeatCustomEnd: string;
  repeatService: number;
  repeatWorkDays: string;
  specificDate: string;
  status: string;
  updatedAt: string;
  serviceType: string;
  serviceLocation: string;
  description: string;
  whoCancelled: string;
  cancellationReason: string;
  authId: string;
  authCode: string;
}

export enum ServiceColor {
  Assessment = "Assessment",
  DirectTherapy = "Direct Therapy",
  ParentTraining = "Parent Training",
  ProtocolModification = "Protocol modification",
}

export interface ICalenderlist {
  name: string;
  id: string;
  GroupId: number;
  color: string;
  type: string;
}

export interface IBookedSchedulerMatch {
  listForCalendar: ICalenderlist[];
  clientBooked: ISchedules[];
  providerBooked: ISchedules[];
  rebookingOptions: ReBookingOptionsResult;
  isLoading: boolean;
}

export interface AutoPlanBookingData {
  bookedClientId: string;
  bookedProviderId: string;
  userTodayDate: Date;
  repeatService: number;
  fromTime: string;
  toTime: string;
  specificDate: string;
  createdBy: string;
  repeatWorkDays: string[];
  serviceType: string;
  serviceLocation: string;
  description: string;
  authId: string;
  authCode: string;
  repeatCustomEnd: null;
  //repeatOn: daysSelect,
  category: string;
}
export interface TimeSlot {
  date: string;
  dateOfWeek: string;
  startTime: string;
  endTime: string;
}
export interface RebookingOptionPerDate {
  date: string;
  dayOfWeek: string;
  provider: EmploymentDetails;

  isSlotAvailable: boolean;
  errorMessage: string;
  fitBookSlots: TimeSlot[];
  notFitBookSlots: TimeSlot[];
}

export interface ReBookingOptionsResult {
  errorMessage: string; //cancel error or other errors before rebooking
  baseProvidersCount: number;
  beginDate: string;
  endDate: string;
  authStartDate: string;
  authEndDate: string;
  options: {
    dateOptions: RebookingOptionPerDate[]; //show rebookingOption based on date & provider
    uniqueProviderIdsCount: number; //total unique provider ids count
    uniqueLimitProviderIdsCount: number; //total unique limit provider ids count
    uniqueDatesCount: number; //total unique dates count
  };
}
