import { useMemo, useState } from "react";
import ReactFlow from "reactflow";
import "reactflow/dist/style.css";

import SingleCard from "./SingleCard";
import { IEdgePlan, INodes, IPlanDetail } from "../../types/planning";

interface ISelectedTree {
  planDetail: IPlanDetail | string;
}

const SelectedTeam: React.FC<ISelectedTree> = ({ planDetail }) => {
  //#region variable region

  const initNodes: INodes[] = [
    {
      id: "a3533479-ce6c-41df-bd9e-45a502b25669",
      data: {
        label: "Provider-1",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "33.42",
      },
      position: {
        x: 400,
        y: 50,
      },
      type: "SingleCard",
    },
    {
      id: "48c437eb-5541-45a8-9e02-38709e09de6a",
      data: {
        label: "Provider-2",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "42.54",
      },
      position: {
        x: 400,
        y: 150,
      },
      type: "SingleCard",
    },
    {
      id: "162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      data: {
        label: "Provider-3",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "10.19",
      },
      position: {
        x: 400,
        y: 250,
      },
      type: "SingleCard",
    },
    {
      id: "162638ca-1fa1-4cbf-af96-453cf4f0c5f6",
      data: {
        label: "Provider-4",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "10.19",
      },
      position: {
        x: 400,
        y: 350,
      },
      type: "SingleCard",
    },
    {
      id: "162638ca-1fa1-4cbf-af96-453cf4f0c5f5",
      data: {
        label: "Client-1",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "10.19",
      },
      position: {
        x: 800,
        y: 250,
      },
      type: "SingleCard",
    },
    {
      id: "162638ca-1fa1-4cbf-af96-453cf4f0c5f1",
      data: {
        label: "Client-2",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "10.19",
      },
      position: {
        x: 800,
        y: 150,
      },
      type: "SingleCard",
    },
    {
      id: "162638ca-1fa1-4cbf-af96-453cf4f0c5f2",
      data: {
        label: "Client-1",
        super: false,
        profile: {},
        bookedHours: "0.00",
        remainingHours: "10.19",
      },
      position: {
        x: 800,
        y: 50,
      },
      type: "SingleCard",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e",
      data: {
        label: "Supervisor",
        super: true,
        profile: {},
        bookedHours: "0.5",
        remainingHours: "39",
      },
      position: {
        x: 50,
        y: 150,
      },
      type: "SingleCard",
    },
  ];

  const initEdges: IEdgePlan[] = [
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-a3533479-ce6c-41df-bd9e-45a502b25669",
      source: "753e8397-ffe1-41e0-878d-c77853cec08e",
      target: "a3533479-ce6c-41df-bd9e-45a502b25669",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-48c437eb-5541-45a8-9e02-38709e09de6a",
      source: "753e8397-ffe1-41e0-878d-c77853cec08e",
      target: "48c437eb-5541-45a8-9e02-38709e09de6a",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      source: "753e8397-ffe1-41e0-878d-c77853cec08e",
      target: "162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      source: "753e8397-ffe1-41e0-878d-c77853cec08e",
      target: "162638ca-1fa1-4cbf-af96-453cf4f0c5f6",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      source: "162638ca-1fa1-4cbf-af96-453cf4f0c5f6",
      target: "162638ca-1fa1-4cbf-af96-453cf4f0c5f5",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      source: "162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      target: "162638ca-1fa1-4cbf-af96-453cf4f0c5f1",
    },
    {
      id: "753e8397-ffe1-41e0-878d-c77853cec08e-162638ca-1fa1-4cbf-af96-453cf4f0c5f4",
      source: "a3533479-ce6c-41df-bd9e-45a502b25669",
      target: "162638ca-1fa1-4cbf-af96-453cf4f0c5f2",
    },
  ];

  //handle nodes data
  // eslint-disable-next-line
  const [nodes, setNodes] = useState(
    // initNodes
    typeof planDetail === "string" ? initNodes : planDetail?.Nodes
  );
  //handle edge data
  // eslint-disable-next-line
  const [edges, setEdges] = useState(
    // initEdges
    typeof planDetail === "string" ? initEdges : planDetail?.Edges
  );

  //#endregion

  //#region method region
  const nodeType = useMemo(
    () => ({
      SingleCard,
    }),
    []
  );

  const flowChart = useMemo(
    () => (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeType}
        zoomOnScroll={false}
        panOnDrag={false}
        zoomOnPinch={false} 
        edgesUpdatable={false}
        edgesFocusable={false}
        nodesDraggable={false}
        nodesConnectable={false}
        nodesFocusable={false}
        draggable={false}
        elementsSelectable={false}
      ></ReactFlow>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planDetail]
  );
  //#endregion

  return (
    <div
      style={{
        height: `calc(100px * ${
          typeof planDetail === "string"
            ? nodes.length - 2
            : planDetail.providerCount > planDetail.clientCount
            ? (planDetail.providerCount)
            : planDetail.clientCount > planDetail.providerCount
            ? (planDetail.clientCount)
            : (planDetail.providerCount)
        } + 50px )`,
      }}
    >
      <br />
      {flowChart}
      {/* {nodes?.length > 0 && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeType}
          zoomOnScroll={false}
          panOnDrag={false}
          zoomOnPinch={false}
        ></ReactFlow>
      )} */}
    </div>
  );
};

export default SelectedTeam;
