import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
  Box,
  Dialog,
  Button,
  Typography,
  ButtonGroup,
  MenuItem,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  ListItemText,
  Grid,
  TextField,
} from "@mui/material";
import { Close, KeyboardBackspace, Refresh } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  updatePlanList,
  resetPlan,
} from "../../../redux/features/plannings/planning-slice";
import {
  setClearFilterValues,
  setPlanningFilterValues,
} from "../../../redux/features/plannings/planning-filter-slice";
import { dataUpdated } from "../../../redux/features/client-main/client-list-slice";

import PlanningFilter from "../../filter-main/PlanningFilter";
import Publish from "../../planningTwo/Publish";

import { OrgBU } from "../../../types/company";
import { EntityScheduleStatus } from "../../../constants/providers";
import { getBussinessName } from "../../../constants/common";

import { getBusinessUnitName } from "../../../utils/EntityUtils";

import { ReactComponent as FilterIcon } from "../../../assets/images/images-svg/filterIcon.svg";
import { GetAuthcode } from "../../../redux/features/client-main/client-insurance-slice";
import { AuthCode } from "../../../types/client";
import { canFilterPlanners } from "../../../helpers/planner-filter-helper/planners-filter-helper";
//interface for planningHeading
interface IPlanningHeading {
  setchangedList: React.Dispatch<React.SetStateAction<boolean>>;
  changedList: boolean;
}
type Params = ReturnType<typeof useParams>;
const PlanningsHeading: React.FC<IPlanningHeading> = ({
  changedList,
  setchangedList,
}) => {
  // #region variables region
  const dispatch = useAppDispatch();

  //routing object
  const navigate = useNavigate();

  //used to store path location
  const { pathname, state } = useLocation();

  // used to access state data
  const myObject = state;

  //controls filter modal visibility
  const [filterModal, setFilterModal] = useState<boolean>(false);

  //filter values
  const filterTypes = useAppSelector(
    (state) => state.planningFilterSlice.PlanningFilter
  );

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //stores authcode
  const authcode = useAppSelector<AuthCode[]>(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );
  //used to access the selected Planner Data
  const planSelector = useAppSelector((state) => state.planSlice);

  //stores current plan
  const currentPlan = useAppSelector((state) => state.planSlice.currentPlan);

  //handles open saveChanges dialog
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // store errormessage
  const [errorMessage] = useState<string>("");

  //handle error message
  const [hasError] = useState<boolean>(false);
  // #endregion
  const [range, setRange] = useState({
    startDate: filterTypes.startDate ? dayjs(filterTypes.startDate) : null,
    endDate: filterTypes.endDate ? dayjs(filterTypes.endDate) : null,
  });
  // #region methods region

  //returns the business unit name

  // #endregion

  //handle reset
  const handleReset = () => {
    if (currentPlan.plan?.id) {
      setOpenDialog(false);
      dispatch(
        resetPlan({
          id: currentPlan.plan?.id,
        })
      );
    }

    //note: handle inside resetPlan already
    //dispatch(resetSchedules([]));     //to reset Schedule
  };

  //handles close single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    if (key === "insuranceProvider") {
      let value = filterTypes.insuranceProvider.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, insuranceProvider: value })
      );
    } else if (key === "businessUnit") {
      let value = filterTypes.businessUnit.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, businessUnit: value })
      );
    } else if (key === "clientStatus") {
      let value = filterTypes.clientStatus.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, clientStatus: value })
      );
    } else if (key === "providerStatus") {
      let value = filterTypes.providerStatus.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, providerStatus: value })
      );
    } else if (key === "intakeStage") {
      let value = filterTypes.intakeStage.filter((i) => i !== item);
      dispatch(setPlanningFilterValues({ ...filterTypes, intakeStage: value }));
    } else if (key === "onBoardingStage") {
      let value = filterTypes.onBoardingStage.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, onBoardingStage: value })
      );
    } else if (key === "ScheduleStatus") {
      dispatch(setPlanningFilterValues({ ...filterTypes, ScheduleStatus: "" }));
    } else if (key === "startDate") {
      dispatch(setPlanningFilterValues({ ...filterTypes, startDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "endDate") {
      dispatch(setPlanningFilterValues({ ...filterTypes, endDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "age") {
      dispatch(setPlanningFilterValues({ ...filterTypes, age: null }));
    } else if (key === "providerSpeciality") {
      let value = filterTypes.providerSpeciality.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, providerSpeciality: value })
      );
    } else if (key === "providerType") {
      let value = filterTypes.providerType.filter((i) => i !== item);
      dispatch(
        setPlanningFilterValues({ ...filterTypes, providerType: value })
      );
    } else if (key === "months") {
      let value = filterTypes.months.filter((i) => i !== item);
      dispatch(setPlanningFilterValues({ ...filterTypes, months: value }));
    } else if (key === "authCode") {
      let value = filterTypes.authCode.filter((i) => i !== item);
      dispatch(setPlanningFilterValues({ ...filterTypes, authCode: value }));
    }
  };

  const handleSavePlan = () => {
    const isFilterApplied = canFilterPlanners(filterTypes,"")
    // console.log("need to save plan:", changedList,isFilterApplied);
    if (changedList || isFilterApplied) {
      dispatch(
        updatePlanList({
          id: currentPlan.plan?.id,
          clientList: planSelector.clientList,
          providerList: planSelector.providerList,
          appliedFilters:filterTypes
        })
      );
      dispatch(setClearFilterValues({}));
      setchangedList(false);
    }
  };

  useEffect(() => {
    dispatch(GetAuthcode());
  }, [currentPlan]);

  return (
    <div className="listingTable">
      <div className="listingTable__header">
        <div className="backBtnArrow">
          {currentPlan && (
            <>
              <Button
                onClick={() => {
                  handleSavePlan();
                  navigate("/planning");
                }}
                className="backBtn"
                startIcon={<KeyboardBackspace />}
                style={{ marginBottom: 0 }}
              >
                Back
              </Button>
              <Typography
                className="listingTable__title"
                gutterBottom
                variant="h6"
              >
                {pathname === "/planning-detail/" + currentPlan.plan?.id &&
                typeof myObject === "string"
                  ? `${myObject}`
                  : `${
                      myObject?.plan?.planName || currentPlan?.plan?.planName
                    }`}
              </Typography>
            </>
          )}
        </div>
        <div className="planningTwo">
          <div className="select-filters-buttons" style={{ width: "100%" }}>
            <div
              className="selected-filtersButtons"
              style={{ minWidth: "320px" }}
            >
         
              <Box display={"flex"} gap={1}>
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  className="filterGroup-btn"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          paddingTop: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <DatePicker
                          className="singleDatePicker DatePickerFilter"
                          label="Start Date"
                          value={filterTypes.startDate}
                          onChange={(e) => {
                            dispatch(
                              setPlanningFilterValues({
                                ...filterTypes,
                                startDate: dayjs(e).format("MM/DD/YYYY"),
                              })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField size={"small"} {...params} />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          paddingTop: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <DatePicker
                          className="singleDatePicker DatePickerFilter"
                          label="End Date"
                          // style={{ height: '300px' }}
                          value={filterTypes.endDate}
                          onChange={(e: Dayjs | null) => {
                            dispatch(
                              setPlanningFilterValues({
                                ...filterTypes,
                                endDate: dayjs(e).format("MM/DD/YYYY"),
                              })
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                  <FormControl
                    className="planning-filter-select"
                    size="small"
                    sx={{ width: "120px" }}
                  >
                    <InputLabel id="all-bu">All BU</InputLabel>
                    <Select
                      labelId="all-bu"
                      id="all-bu-select"
                      multiple
                      label="All BU"
                      value={filterTypes?.businessUnit}
                      renderValue={(selected: any) => {
                        selected = selected.map((select: any) => {
                          return getBussinessName(select, businessUnits);
                        });
                        return selected.join(", ");
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                      onChange={(e: any) => {
                        dispatch(
                          setPlanningFilterValues({
                            ...filterTypes,
                            businessUnit: e.target.value,
                          })
                        );
                      }}
                    >
                      {businessUnits.map((unit: OrgBU, index: number) => {
                        return (
                          <MenuItem key={index} value={unit.id}>
                            <Checkbox
                              checked={
                                filterTypes.businessUnit.indexOf(unit.id) > -1
                              }
                            />
                            {unit.businessUnitName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    className="planning-filter-select"
                    size="small"
                    sx={{ width: "120px" }}
                  >
                    <InputLabel id="all-bu">Auth Code</InputLabel>
                    <Select
                      labelId="all-bu"
                      id="all-bu-select"
                      multiple
                      label="All BU"
                      value={filterTypes?.authCode}
                      renderValue={(selected: any) => {
                        return selected.join(", ");
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                      onChange={(e: any) => {
                        dispatch(
                          setPlanningFilterValues({
                            ...filterTypes,
                            authCode: e.target.value,
                          })
                        );
                      }}
                    >
                      {authcode.map((auth) => {
                        return (
                          <MenuItem key={auth.id} value={auth.conceptValue}>
                            <Checkbox
                              checked={
                                filterTypes.authCode.indexOf(
                                  auth.conceptValue
                                ) > -1
                              }
                            />
                            {auth.conceptValue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    className="planning-filter-select"
                    size="small"
                    sx={{ minWidth: "170px" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Schedule Status
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      value={filterTypes.ScheduleStatus}
                      labelId="demo-simple-select-label"
                      onChange={(e) => {
                        dispatch(
                          setPlanningFilterValues({
                            ...filterTypes,
                            ScheduleStatus: e.target.value,
                          })
                        );
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                      autoWidth
                    >
                      {Object.values(EntityScheduleStatus).map(
                        (unit, index) => (
                          <MenuItem key={index} value={unit}>
                            <ListItemText primary={unit} />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      setFilterModal(true);
                    }}
                  >
                    <FilterIcon />
                  </Button>
                  <Button
                    className="btn publishBtn"
                    onClick={() => setOpenDialog(true)}
                  >
                    <Refresh />
                  </Button>

                  <Publish data={currentPlan} />
                </ButtonGroup>
              </Box>
              <div className="selected-filtersButtonsGroup">
                {Object.entries(filterTypes)
                  .filter(([_, value], idx) => {
                    if (value && typeof value === "string") return true;
                    if (value?.length > 0 && typeof value === "object")
                      return true;
                    return false;
                  })
                  .map(([key, value], index) => {
                    if (typeof value === "object") {
                      return value.map((subItem: string, subIndex: number) => {
                        if (
                          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                            subItem
                          )
                        ) {
                          return (
                            <Button className="border-button" key={subIndex}>
                              {getBussinessName(subItem, businessUnits)}
                              <span className="closeBtn">
                                <Close
                                  fontSize="small"
                                  onClick={() => {
                                    handleCloseSingleFilter(key, subItem);
                                  }}
                                />
                              </span>
                            </Button>
                          );
                        }
                        return (
                          <Button className="border-button" key={subIndex}>
                            {subItem}
                            <span className="closeBtn">
                              <Close
                                fontSize="small"
                                onClick={() => {
                                  handleCloseSingleFilter(key, subItem);
                                }}
                              />
                            </span>
                          </Button>
                        );
                      });
                    } else {
                      return (
                        <Button className="border-button" key={index}>
                          {key === "businessUnit"
                            ? getBusinessUnitName(businessUnits, [value])
                            : key === "age"
                            ? `Age:${value}`
                            : value}
                          <span className="closeBtn">
                            <Close
                              fontSize="small"
                              onClick={() => {
                                handleCloseSingleFilter(key, value);
                              }}
                            />
                          </span>
                        </Button>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          {hasError && <span style={{ color: "red" }}>{errorMessage}</span>}
        </div>
      </div>
      <Dialog open={filterModal} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Set Filters
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setFilterModal(false)}
          >
            <Close />
          </Button>
        </Box>
        <PlanningFilter
          closeModal={(val, filterObject) => {
            dispatch(setPlanningFilterValues({ ...filterObject }));
            setFilterModal(val);
          }}
        />
      </Dialog>
      <Dialog open={openDialog} className="smallModel">
        <Box className="smallModel__head">
          <Typography variant="h5" className="smallModel__title">
            Reset Plan
          </Typography>
          <Button
            className="smallModel__closeBtn"
            onClick={() => setOpenDialog(false)}
          >
            <Close />
          </Button>
        </Box>
        <Box px={2} py={4} display={"flex"} flexDirection={"column"} gap={2}>
          {currentPlan.pendingCount > 0 ? (
            <>
              <Typography variant="h6">
                There are
                <Box component={"span"} fontWeight={"bold"}>
                  {" " + currentPlan.pendingCount + " "}
                </Box>
                pending schedule(s) found. Do you want to remove all the pending
                schedules?
              </Typography>
              <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                <Box>
                  <Button
                    className="button"
                    variant="contained"
                    onClick={handleReset}
                  >
                    {currentPlan.pendingCount > 0 ? "Reset Plan" : "Cancel"}
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <Typography variant="h6">
              No pending schedules found in this plan.
            </Typography>
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default PlanningsHeading;
