import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IIntegrationSetting,
  ISyncDataRequests,
} from "../../../types/businessSettings";
import { setResponseValue } from "../api-response/api-response";
import BusinessSetting from "../../../service/business-setting.service";

interface IntegrationSlice {
  intergrationSettings: IIntegrationSetting[];
  syncDataLogs: { [appName: string]: ISyncDataRequests[] };
}

const initialState: IntegrationSlice = {
  intergrationSettings: [],
  syncDataLogs: {}, 
};

const integrationSettingSlice = createSlice({
  name: "integrationSettingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetIntegrationSetting.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intergrationSettings: action.payload,
        };
      }
    });
    // builder.addCase(SwitchIntegrationSetting.fulfilled, (state, action) => {
    //   if (action.payload !== undefined) {
    //     return {
    //       ...action.payload,
    //     };
    //   }
    // });
    builder.addCase(ConnectApp.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intergrationSettings: action.payload,
        };
      }
    });
    builder.addCase(DisconnectApp.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intergrationSettings: action.payload,
        };
      }
    });
    builder.addCase(GetSyncRequestbyAppName.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const { appName } = action.meta.arg; // Extract appName from the thunk argument
        state.syncDataLogs[appName] = action.payload; // Set or update the syncDataLogs for the appName
      }
    });
  },
});

export const GetIntegrationSetting = createAsyncThunk(
  "/getIntegrationSetting",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data } = await BusinessSetting.getIntegrationSetting();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

/*
export const SwitchIntegrationSetting = createAsyncThunk(
  "/switchIntegrationSetting",
  async ({ isActive }: { isActive: boolean }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data, message } =
        await BusinessSetting.switchIntegrationSetting(isActive);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
*/

// export const TestConnectApp = createAsyncThunk(
//   "/integrateApp",
//   async (
//     { appName, appCredential }: { appName: string; appCredential: any },
//     { dispatch }
//   ) => {
//     dispatch(setResponseValue({ name: "pending", value: true }));
//     try {
//       const { status, data, message } = await BusinessSetting.testConnectApp(
//         appName,
//         appCredential
//       );
//       if (status) {
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: message }));
//         return data;
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(setResponseValue({ name: "message", value: e?.message }));
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

export const ConnectApp = createAsyncThunk(
  "/integrateApp",
  async (
    {
      appName,
      appCredential,
      testLogin,
    }: { appName: string; appCredential: any; testLogin: boolean },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data, message } = await BusinessSetting.connectApp(
        appName,
        appCredential,
        testLogin
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const DisconnectApp = createAsyncThunk(
  "/disconnectApp",
  async ({ appName }: { appName: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data, message } = await BusinessSetting.disconnectApp(
        appName
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetSyncRequestbyAppName = createAsyncThunk(
  "/getSyncRequests",
  async ({ appName }: { appName: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data } =
        await BusinessSetting.getSyncDataRequestsByAppName(appName);
      if (status) {
        console.log(data, "IntegrationData");
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const RefreshSyncRequests = createAsyncThunk(
  "/getIntegrationSetting",
  async ({ appName }: { appName: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      await BusinessSetting.refreshUnlinkedClients(appName);
      await BusinessSetting.refreshUnlinkedProviders(appName);
      await BusinessSetting.refreshUnlinkedSchedules(appName);
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default integrationSettingSlice;
