import { async } from "q";
import axios from "../axios/axios";
import { AvailabilityDetails } from "../types/availability";
import { BoardStage } from "../types/board";
import {
  EmploymentDetails,
  ContactDetails,
  Credentials,
  ProviderForms,
  // BoardStage,
  ProviderMessages,
  ISupervisorList,
} from "../types/provider";
import {
  Response,
  SingleResponse,
  DeleteResponse,
  UpdateResponse,
} from "../types/response";
// import ProviderInsuranceDetailsForm from "../pages/providers/providers-forms/ProviderInsuranceDetailsForm";
import _ from "lodash";

export const postEmploymentProviderDetails = async (
  reqData: EmploymentDetails
) => {
  try {
    const { data } = await axios.post<SingleResponse<EmploymentDetails>>(
      `/provider`,
      {
        ...reqData,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postSupervisorTeam = async (obj: ISupervisorList) => {
  try {
    const { data } = await axios.post<SingleResponse<ISupervisorList>>(
      `/provider/supervisor-team`,
      {
        ...obj,
      }
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postSupervisorCases = async (obj: ISupervisorList) => {
  try {
    const { data } = await axios.post<SingleResponse<ISupervisorList>>(
      `/provider/supervisor-cases`,
      {
        ...obj,
      }
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postContactProviderDetails = async (reqData: ContactDetails) => {
  try {
    const { data } = await axios.post<SingleResponse<ContactDetails>>(
      `/contact`,
      {
        ...reqData,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postCredentialProviderDetails = async (reqData: Credentials) => {
  try {
    const { data } = await axios.post<SingleResponse<Credentials>>(
      `/provider/providerInsurance`,
      {
        ...reqData,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//Deprecate!! use availability.service now!
// export const postAvailabilityProviderDetails = async (
//   reqData: AvailabilityDetails[]
// ) => {
//   try {
//     const { data } = await axios.post<SingleResponse<AvailabilityDetails>>(
//       `/availability`,
//       {
//         availabilities: reqData,
//       }
//     );

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export const GetAllProvidersEndpoint = async () => {
  try {
    const { data } = await axios.get<Response<ProviderForms>>(`/providers`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetTitleNames = async () => {
  try {
    const { data } = await axios.get(`/getTitleNames`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetProviderById = async (id: string) => {
  try {
    const { data } = await axios.get<SingleResponse<ProviderForms>>(
      `/provider/${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const DeleteProviderById = async (id: string) => {
  try {
    const { data } = await axios.delete<DeleteResponse>(`/provider/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
export async function deleteCredentialById(id: string) {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/provider/providerInsurance/${id}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const deleteCredentialFileById = async (fileObj: {
  id: string;
  key: string;
}) => {
  try {
    const { data } = await axios.post<DeleteResponse>("file/delete-files", {
      ...fileObj,
      type: "provider-insurance",
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateProviderEmploymentById = async (
  id: string,
  reqData: EmploymentDetails
) => {
  try {
    const { data } = await axios.put<UpdateResponse>(`/provider/${id}`, {
      ...reqData,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateProviderContactById = async (
  id: string,
  reqData: ContactDetails
) => {
  try {
    let data = null;
    if (id) {
      data = await axios.put<UpdateResponse>(`/contact/${id}`, {
        ...reqData,
      });
    } else {
      data = await axios.post(`/contact/`, {
        ...reqData,
      });
    }
    return { status: data.data.status, message: data.data.message };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const buildFormData = (data: any) => {
  const formData = new FormData();
  _.forOwn({ ...data }, function (value, key) {
    formData.append(key, value);
  });
  return formData;
};

const generateFormData = (pD: Credentials) => {
  const data = new FormData();
  Object.entries(pD).forEach((item) => {
    if (item[0] !== "files") {
      data.append(item[0], item[1]);
    } else {
      item[1].forEach((item: any) => {
        data.append("file", item);
      });
    }
  });
  return data;
};

export const updateProviderCredentialById = async (
  id: string,
  providerInsurance: Credentials[]
) => {
  try {
    const data = await Promise.all(
      providerInsurance.map((item: Credentials) => {
        const provider: any = {
          id: item.id,
          providerId: id,
          expirationDate: item.expirationDate,
          complete: item.complete,
          files: item.files,
          name: item.name,
        };
        if (item.id) {
          return axios.put(
            `/provider/${id}/providerInsurance/${item.id}`,
            generateFormData(provider)
          );
        } else {
          return axios.post(
            `/provider/providerInsurance`,
            generateFormData(provider)
          );
        }
      })
    );
    if (data) {
      return {
        status: 1,
        message: "Successfully updated the provider credentials",
      };
    } else {
      // IMPORTANT - THIS will never be returned but important to remove the warning and return type error.
      return {
        status: 0,
        message: "Nothing",
      };
    }
  } catch (e) {
    throw e;
  }
};

//Deprecate!! use availability.service now!
// export async function updateProviderAvailabilityById(availability: any) {
//   try {
//     let data = null;
//     // if (availability.id?.length > 0) {
//     //   data = await axios.put(`/availability/${availability.id}`, {
//     //     ...availability,
//     //   });
//     // } else {
//     data = await axios.post(`/availability`, {
//       availabilities: availability,
//     });
//     // }
//     return { status: data.data.status, message: data.data.message };
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// }

// export const GetfromDynamicEntityId = async (
//   clientAvailabilityId: string,
//   providerAvailabilityIds: string[]
// ) => {
//   try {
//     const data = await axios.post(`/schedules/remainingHours`, {
//       clientAvailabilityId,
//       providerAvailabilityIds,
//     });

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export async function uploadProviderImage(fileObj: any, id: string) {
  try {
    const formData = new FormData();
    formData.append("file", fileObj as any);
    const { data } = await axios.post<Response<ProviderForms>>(
      `/provider/` + id + `/upload-profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getProviderOnBoardingStages() {
  try {
    const { data } = await axios.get("/board/onboarding");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getProviderBoards() {
  try {
    const { data } = await axios.get<Response<BoardStage>>(
      "/board?type=Provider"
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

// export async function deleteAvailabilityById(id: string) {
//   try {
//     const { data } = await axios.delete<DeleteResponse>(`/availability/${id}`);

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// }

export const getProviderMessagesById = async (providerId: string) => {
  try {
    const { data } = await axios.get<Response<ProviderMessages>>(
      `/comments/provider?entityId=${providerId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postProviderMessageById = async (
  message: string,
  providerId: string
) => {
  try {
    const { data } = await axios.post<SingleResponse<ProviderMessages>>(
      "/comment/addProviderComment",
      {
        message,
        providerId,
        commentType: "User",
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateProviderMessageById = async (
  selectedMessage: string,
  commentId: string
) => {
  try {
    const { data } = await axios.put<UpdateResponse>(
      `/comment/provider/${commentId}`,
      {
        message: selectedMessage,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const deleteProviderMessageById = async (commentId: string) => {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/comment/provider/${commentId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getInactiveProviders = async () => {
  try {
    const { data } = await axios.get(`/inactive/provider`);
    return data ;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const ProviderService = {
  updateProviderContactById,
  updateProviderCredentialById,
  updateProviderEmploymentById,

  // postAvailabilityProviderDetails,
  // updateProviderAvailabilityById,
  // deleteAvailabilityById,

  updateProviderMessageById,
  postEmploymentProviderDetails,
  postContactProviderDetails,
  postCredentialProviderDetails,
  postSupervisorCases,
  postSupervisorTeam,

  GetAllProvidersEndpoint,
  GetProviderById,
  DeleteProviderById,
  deleteProviderMessageById,
  deleteCredentialById,
  deleteCredentialFileById,

  //GetfromDynamicEntityId,
  getProviderBoards,
  getProviderOnBoardingStages,
  // postProviderCardToBoard,
  uploadProviderImage,
  //moveProviderToAnotherBoard,
  //getProviderFilesById,
  //postProviderFiles,
  //deleteProviderFileById,
  getProviderMessagesById,
  postProviderMessageById,
  GetTitleNames,
  getInactiveProviders
};

export default ProviderService;
