import axios from "../axios/axios";
import {
  IAutoPlanData,
  MatchedProvider,
  MatchOption,
} from "../types/schedule";
import { Response, SingleResponse } from "../types/response";

export const AutoPlanForClient = async (
  clientId: string,
  matchValues: MatchOption
) => {
  try {
    const { data } = await axios.post<SingleResponse<IAutoPlanData>>(
      `/auto-plan/client/${clientId}`,
      {
        // availability: [...matchValues.selectedAvailability], //needed to tell selected days
        dayOfWeeks: matchValues.selectedAvailability?.map(
          (day) => day?.dayOfWeek
        ),
        clientAuthId: matchValues.clientAuthId,
        authCode: matchValues.clientAuthCode, //need to pass this authCode!!
        distance: matchValues.miles,
        providerType: matchValues.providerType,
        businessUnit: matchValues.businessUnit,
        onboardingStage: matchValues.providerOnboardingStages,
        onboardingStatus: matchValues.providerOnboardingStatus,
        insuranceProvider: matchValues.insuranceProvider,
        specialities: matchValues.specialities,

        //allocation preference
        duration: matchValues.duration,
        timeOfDay: matchValues.timeOfDay,
        // newSessionsPerWeek: matchValues.newSessionsPerWeek,
        newSessionsPerDay: matchValues.newSessionsPerDay,
        newSessionsPerDayMorning: matchValues.newSessionsPerDayMorning,
        newSessionsPerDayAfternoon: matchValues.newSessionsPerDayAfternoon,

        earliestStartTime: matchValues.earliestStartTime,
      }
    );
    // console.log("AutoPlanResult:", data);
    return data;
  } catch (e: any) {
    console.log("error:", e);
    throw e?.response?.data;
  }
};

export const AutoPlanForProvider = async (
  providerId: string,
  matchValues: MatchOption
) => {
  try {
    const { data } = await axios.post<SingleResponse<IAutoPlanData>>(
      `/auto-plan/provider/${providerId}`,
      {
        // availability: [...matchValues.selectedAvailability], //needed to tell selected days
        dayOfWeeks: matchValues.selectedAvailability?.map(
          (day) => day?.dayOfWeek
        ),
        authCode: matchValues.clientAuthCode, //need to pass this authCode!!
        distance: matchValues.miles,
        businessUnit: matchValues.businessUnit,
        intakeStage: matchValues.providerOnboardingStages,
        intakeStatus: matchValues.providerOnboardingStatus,
        insuranceProvider: matchValues.insuranceProvider,

        //allocation preference
        duration: matchValues.duration,
        timeOfDay: matchValues.timeOfDay,
        // newSessionsPerWeek: matchValues.newSessionsPerWeek,
        newSessionsPerDay: matchValues.newSessionsPerDay,
        newSessionsPerDayMorning: matchValues.newSessionsPerDayMorning,
        newSessionsPerDayAfternoon: matchValues.newSessionsPerDayAfternoon,

        earliestStartTime: matchValues.earliestStartTime,
        availabilityId:matchValues.availability.id
      }
    );
    // console.log("AutoPlanResult:", data);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetAllProvidersByMatchValues = async (
  clientId: string,
  matchValues: MatchOption
) => {
  try {
    const { data } = await axios.post<Response<MatchedProvider>>(
      `/match/${clientId}`,
      {
        availability: [...matchValues.selectedAvailability], //needed to tell selected days
        clientAuthId: matchValues.clientAuthId,
        authCode: matchValues.clientAuthCode, //need to pass this authCode!!
        distance: matchValues.miles,
        providerType: matchValues.providerType,
        businessUnit: matchValues.businessUnit,
        onboardingStage: matchValues.providerOnboardingStages,
        onboardingStatus: matchValues.providerOnboardingStatus,
        insuranceProvider: matchValues.insuranceProvider,
        specialities: matchValues.specialities,
        //   clientAvailableDate:{
        //        startDate: matchValues.availability.beginDate,
        //        endDate: matchValues.availability.endDate
        //   }
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const MatchService = {
  GetAllProvidersByMatchValues,
  AutoPlanForClient,
  AutoPlanForProvider,
};

export default MatchService;
