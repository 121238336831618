import axios from "../axios/axios";

export const getProducts = async () => {
  try {
    const { data } = await axios.get("/products");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const redirectToCheckout = async (
  productId: string,
  adminSeats: number
) => {
  try {
    const data = await checkoutPayment(productId, adminSeats);
    if (data?.url) {
      window.location.replace(data.url);
    }
  } catch (e) {
    return { error: e };
  }
};

export const checkoutPayment = async (
  productId: string,
  adminSeats: number
) => {
  try {
    const { data } = await axios.post("/checkout", { productId, adminSeats });
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateSubscription = async (
  productId: string,
  adminSeats: number
) => {
  const { data } = await axios.put("/updateSubscription", { productId, adminSeats });
  return data;
};

// export const updateAdminSeatPLan = async (adminCount: number) => {
//   const { data } = await axios.put("/adminSeat", { adminCount });
//   return data;
// };

export const getTenantUsage = async () => {
  try {
    const { data } = await axios.get("/getTenantUsage");
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getTenantPayments = async () => {
  try {
    const { data } = await axios.get("/getTenantPayment");
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getTenantPlan = async () => {
  try {
    const { data } = await axios.get("/getTenantPlan");
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const PaymentSystemService = {
  getProducts,
  checkoutPayment,
  updateSubscription,
  // updateAdminSeatPLan,
  getTenantUsage,
  getTenantPayments,
  getTenantPlan,
};

export default PaymentSystemService;
