import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import ImportExportService from "../../../service/importExportRequest.service";
import {
  IExportedData,
  IExportTemplate,
  IImportExportRequest,
} from "../../../types/tools";

interface IImportExportSlice {
  template: IExportTemplate[];
  exportedData: IExportedData[];
  requestLogs: IImportExportRequest[];
}

const initialState: IImportExportSlice = {
  template: [],
  exportedData: [],
  requestLogs: [],
};

const importExportSlice = createSlice({
  name: "importExportSlice",
  initialState: initialState,
  reducers: {
    setExportedData: (state, action) => {
      if (action.payload !== undefined) {
        const value = action.payload;
        let result: IExportedData[] = [];
        if (state.exportedData.length > 0) {
          // Iterate over the existing exportedData and check if the dataset exists
          result = state.exportedData.map((item) => {
            if (
              value.some((ele: IExportedData) => ele.entityType === item.entityType)
            ) {
              return value;
            } else {
              return item;
            }
          });
          // Add new datasets that aren't in the existing exportedData
          value.forEach((element: IExportedData) => {
            if (!result.find((item) => item.entityType === element.entityType)) {
              result.push(element);
            }
          });
        } else {
          // If exportedData is empty, add all datasets
          result = value;
        }
        return {
          ...state,
          exportedData: result,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetExportTemplates.fulfilled, (state, action) => {
      return {
        ...state,
        template: action.payload,
      };
    });
    builder.addCase(GetImportExportRequest.fulfilled, (state, action) => {
      return {
        ...state,
        requestLogs: action.payload.sort(
          (a: IImportExportRequest, b: IImportExportRequest) => {
            return  (new Date(b.updatedAt) as any) -  (new Date(a.updatedAt) as any);
          }
        ),
      };
    });
  },
});

export const PostExportRequest = createAsyncThunk(
  "/PostExportRequest",
  async (
    { entityTypes, fileFormat }: { entityTypes: string[]; fileFormat: string },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));

      const { data, message } = await ImportExportService.postExportRequest(
        entityTypes,
        fileFormat
      );
      console.log("PostExportRequest", data, message);
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const PostImportRequest = createAsyncThunk(
  "/PostImportRequest",
  async (formData: FormData, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));

      const { data, message } = await ImportExportService.postImportRequest(
        formData
      );
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetExportTemplates = createAsyncThunk(
  "/exportTemplates",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await ImportExportService.getExportTemplates();
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetImportExportRequest = createAsyncThunk(
  "/importExportRequests",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } =
        await ImportExportService.getImportExoportRequest();
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setExportedData } = importExportSlice.actions;
export default importExportSlice;
