import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AllTemplates } from "../../../constants/parent-settings";
import {
  ICommmunication,
  NewDataCatalog,
} from "../../../types/businessSettings";
import { setResponseValue } from "../api-response/api-response";
import BusinessSetting from "../../../service/business-setting.service";

const initialState: ICommmunication = {
  ...AllTemplates,
};

const dataCatalogSlice = createSlice({
  name: "dataCatalogSlice",
  initialState: initialState,
  reducers: {
    setBillableCatalog: (state, action) => {
      let Billable = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Billable Activity"
      );
      return {
        ...state,
        BillableCatalog: Billable,
      };
    },
    setNonBillableCatalog: (state, action) => {
      let NonBillable = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Non Billable Activity"
      );
      return {
        ...state,
        NonBillableCatalog: NonBillable,
      };
    },
    setClientCancelReason: (state, action) => {
      let cancelReason = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Client Cancel Reason"
      );
      return {
        ...state,
        ClientReasonCatalog: cancelReason,
      };
    },
    setProviderCancelReason: (state, action) => {
      let cancelReason = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Employee Cancel Reason"
      );
      return {
        ...state,
        ProviderReasonCatalog: cancelReason,
      };
    },
    setCurrentTemplate: (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          currentMessageTemplate: action.payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataCatalog.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          NewDataCatalog: [...action.payload],
        };
      }
    });
    builder.addCase(postDataCatalogForm.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          NewDataCatalog: [action.payload, ...state.NewDataCatalog],
        };
      }
    });
    builder.addCase(updateDataCatalogById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const newCatalog = state.NewDataCatalog.map((catalog) => {
          if (catalog.id === action.payload.id) {
            return action.payload;
          } else {
            return catalog;
          }
        });
        return {
          ...state,
          NewDataCatalog: newCatalog,
        };
      }
    });
    builder.addCase(deleteCatalogTemplate.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          NewDataCatalog: state.NewDataCatalog.filter(
            (catalog) => catalog.id !== action.payload
          ),
        };
      }
    });
    builder.addCase(getParentPortalSchedule.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          ParentSchedule: action.payload,
        };
      }
    });
    builder.addCase(getEmployeeScheduleSettings.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          EmployeeSetting: action.payload,
        };
      }
    });
    builder.addCase(updateParentScheduling.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload.type === "parent") {
          return {
            ...state,
            ParentSchedule: action.payload.data,
          };
        } else if (action.payload.type === "employee") {
          return {
            ...state,
            EmployeeSetting: action.payload.data,
          };
        }
      }
    });
    builder.addCase(GetAdminScheduleSetting.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          adminScheduleSettings: action.payload,
        };
      }
    });
    builder.addCase(UpdateAdminScheduleSetting.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          adminScheduleSettings: action.payload,
        };
      }
    });
  },
});

export const getDataCatalog = createAsyncThunk(
  "/getCatalog",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await BusinessSetting.getDataCatalog();
      if (data) {
        dispatch(setBillableCatalog(data));
        dispatch(setNonBillableCatalog(data));
        dispatch(setProviderCancelReason(data));
        dispatch(setClientCancelReason(data));
      }
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const postDataCatalogForm = createAsyncThunk(
  "/createCatalog",
  async (
    {
      catalogName,
      conceptValue,
      isActive,
      description,
      data,
    }: {
      catalogName: string;
      conceptValue: string;
      isActive: boolean;
      description: string;
      data?: {
        minsPerUnit: number;
        unitsPerDay: number;
        rules: {
          groupCode: boolean;
          noOfClients: number;
          doubleBookings: boolean;
          parallelCodes: string[];
          insurance: string[];
        }[];
      };
    },
    { dispatch }
  ) => {
    const payloadData = {
      catalogName: catalogName,
      conceptValue: conceptValue,
      isActive: isActive,
      description: description,
      data,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message, data } =
        await BusinessSetting.postDataCatalogForm(payloadData);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateDataCatalogById = createAsyncThunk(
  "/updateCatalog",
  async (
    {
      id,
      catalogName,
      conceptValue,
      isActive,
      description,
      data,
    }: {
      id: string;
      catalogName: string;
      conceptValue: string;
      isActive: boolean;
      description: string;
      data?: {
        minsPerUnit: number;
        unitsPerDay: number;
        rules?: {
          groupCode: boolean;
          noOfClients: number;
          doubleBookings: boolean;
          parallelCodes: string[];
          insurance: string[];
        }[];
      };
    },
    { dispatch }
  ) => {
    const payloadData = {
      id: id,
      catalogName: catalogName,
      conceptValue: conceptValue,
      isActive: isActive,
      description: description,
      data,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message, data } =
        await BusinessSetting.updateDataCatalogById(id, payloadData);
      if (status) {
        // dispatch(getDataCatalog());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteCatalogTemplate = createAsyncThunk(
  "/deletedatacatalog/",
  async ({ id }: { id: string }, { dispatch, getState }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } =
        await BusinessSetting.deleteDataCatalogTemplateById(id);
      if (status) {
        // dispatch(getDataCatalog());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return id;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getParentPortalSchedule = createAsyncThunk(
  "/getParentPortalSchedule",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await BusinessSetting.getParentSetting();
      if (data) {
        return data;
      }
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.message?.data || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getEmployeeScheduleSettings = createAsyncThunk(
  "/getEmployeePortalSchedule",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await BusinessSetting.getEmployeeSetting();
      if (data) {
        return data;
      }
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.message?.data || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateParentScheduling = createAsyncThunk(
  "updateScheduling",
  async (
    {
      type,
      data,
    }: {
      type: "parent" | "employee";
      data:
        | {
            allowParentToCancel: boolean;
            allowParentToRebook: boolean;
            maxBookingToCancel: number;
            maxHrsToCancle: number;
            maxProviderToRebook: number;
            rebookProviderType: string;
            serviceType: string[];
            showProviderName: boolean;
            showProviderType: boolean;
            maxDaysToRebook: number;
            minHrsToCancel: number;
            showProvider: boolean;
          }
        | {
            allowEmployeeToSeeAvailablity: boolean;
            allowEmployeeToUpdateAvailablity: boolean;
          };
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data: resData } = await BusinessSetting.updateScheduleSetting(
        type,
        data
      );
      if (resData) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({ name: "message", value: "Updated successfully" })
        );
        return { data: resData, type };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response.data.message || "error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetAdminScheduleSetting = createAsyncThunk(
  "/adminSetting",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await BusinessSetting.getAdminScheduleSettings();
      if (data) {
        return data;
      }
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.message?.data || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateAdminScheduleSetting = createAsyncThunk(
  "updateAdminScheduling",
  async (
    {
      rebookProviderType,
      maxProviderToRebook,
      maxDaysToRebook,
    }: {
      rebookProviderType: string;
      maxProviderToRebook: number;
      maxDaysToRebook: number;
    },
    { dispatch }
  ) => {
    const payloadData = {
      rebookProviderType: rebookProviderType,
      maxProviderToRebook: maxProviderToRebook,
      maxDaysToRebook: maxDaysToRebook,
    };
    dispatch(setResponseValue({ name: "pending", value: true }));
    console.log(payloadData,"payloard")
    try {
      const { data } = await BusinessSetting.updateAdminScheduleSetting(
        payloadData
      );
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response.data.message || "error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setBillableCatalog,
  setNonBillableCatalog,
  setProviderCancelReason,
  setClientCancelReason,
  setCurrentTemplate,
} = dataCatalogSlice.actions;

export default dataCatalogSlice;
