import {
  Box,
  Button,
  Card,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { ProviderForms } from "../../types/provider";
import { useDispatch } from "react-redux";
import {
  updatePlanName,
  createPlan,
  setPlanName,
} from "../../redux/features/plannings/planning-slice";
import { useNavigate } from "react-router-dom";
import { IPlanDetail } from "../../types/planning";
import PlanService from "../../service/planning.service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface INewPlan {
  providerData: ProviderForms[];
  setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  Editpopup: boolean;
  setEditPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setAddPopup: React.Dispatch<React.SetStateAction<boolean>>;
  planId: string;
  planList: IPlanDetail[];
}

//used for NewPlan & EditPlan
const NewPlan: React.FC<INewPlan> = ({
  providerData,
  setOpenPopUp,
  Editpopup,
  setEditPopup,
  planId,
  planList,
  setAddPopup,
}) => {
  //#region variable region

  // used to dispatch
  const dispatch = useDispatch();

  //routing object
  const navigate = useNavigate();

  //used to store plan name
  const [planName, setName] = useState<string>("");

  //used to map
  const PlanType = ["Supervisor", "BU"];

  //used to handle supervisor list
  const [typelist, setTypeList] = useState<string[]>([]);

  //used to handle error message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //used to handle duplicate name
  const [duplicateName, setDuplicateName] = useState<string>("");

  //used to filter names
  const foundDuplicatePlan = planList.filter(
    (item) => item.plan.planName === planName
  )[0];

  //#endregion

  //#region method region
  const handleSubmit = async () => {
    if (
      foundDuplicatePlan &&
      (!Editpopup || (Editpopup && foundDuplicatePlan?.plan.id !== planId))
    ) {
      setDuplicateName("Plan name already exist");
      return;
    } else {
      setDuplicateName("");
      setErrorMessage("");
    }

    if (planName.trim().length !== 0) {
      if (Editpopup) {
        setEditPopup(false);
        setOpenPopUp(false);
        dispatch(updatePlanName({ Id: planId, planName: planName }));
      } else {
        //create plan
        // dispatch(
        //   createPlan({
        //     planName: planName,
        //     providerList: [],
        //     clientList: [],
        //     schedulesIds: [],
        //   })
        // )
        //dispatch(setPlan(true));
        const { data } = await PlanService.createPlan({
          planName: planName,
          providerList: [],
          clientList: [],
          // schedulesIds: [],
        });
        dispatch(setPlanName(planName));
        setOpenPopUp(false);
        setAddPopup(false);
        setErrorMessage("");

        navigate("/planning-detail/" + data.id);
      }
    } else {
      setErrorMessage("Please enter plan name");
    }
  };

  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (Editpopup) {
      let selectedPlan: IPlanDetail = planList.filter(
        (item) => item.plan.id === planId
      )[0];
      setName(selectedPlan.plan.planName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Editpopup]);

  //#endregion

  return (
    <div className="addPlanForm">
      <FormGroup className="formBox">
        <div className="addPlanForm__field">
          <Box className="fromGroup">
            <InputLabel className="fromLabel">Plan Name</InputLabel>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              placeholder="Enter new plan name..."
              value={planName.trimStart()}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setName(e.target.value);
                if (e.target.value.trim().length !== 0) {
                  setErrorMessage("");
                } else {
                  //setErrorMessage("Please enter plan name");
                }
              }}
              onKeyUp={(e) => {
                if (
                  foundDuplicatePlan &&
                  (!Editpopup ||
                    (Editpopup && foundDuplicatePlan?.plan?.id !== planId))
                ) {
                  setDuplicateName("Plan name already exist");
                  return;
                } else {
                  setDuplicateName("");
                }
              }}
            />
            <span style={{ color: "#d32f2f" }}>{errorMessage}</span>
            <span style={{ color: "#d32f2f" }}>{duplicateName}</span>
          </Box>
          {/* temp commented by Hui
          <Box>
            <InputLabel className="detailsCard-part__title">
              Plan Type
            </InputLabel>
            
            <Box className="fromGroup-chips" style={{ marginBottom: "15px" }}>
              {PlanType.map((item) => (
                <Card
                  className={"detailsCard-part__chip"}
                  onClick={() => {}}
                  key={item}
                >
                  {item}
                </Card>
              ))}
            </Box>
          </Box>
          */}
          {/* <Box className="fromGroup">
            <div className="detailsCard-part">
              <InputLabel className="fromLabel">Type</InputLabel>
              <Select
                placeholder="Type"
                className="form-control"
                labelId="demo-simple-select-label"
                value={typelist.length > 0 ? typelist[0] : ""}
                name="type"
                onChange={(e) => {
                  setTypeList([e.target.value]);
                }}
                MenuProps={MenuProps}
              >
                {providerData.map((item: ProviderForms, index: number) => {
                  if (item.employmentDetails.isSupervisor) {
                    return (
                      <MenuItem key={index} value={item.employmentDetails.id}>
                        {item.employmentDetails.firstName}{" "}
                        {item.employmentDetails.lastName}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </div>
          </Box> */}
        </div>
        <div className="largeModel__footer">
          <Button
            className="button"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            {Editpopup ? "Save Change" : "Add Plan"}
          </Button>
        </div>
      </FormGroup>
    </div>
  );
};

export default NewPlan;
