import React, { useState, Dispatch, useEffect } from "react";
import { ProviderForms } from "../../../types/provider";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { ReactComponent as UserVerified } from "../../../assets/images/images-svg/verified.svg";
import PlannerSupervisorList from "./PlannerSupervisorList";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Popover,
  Typography,
} from "@mui/material";
import AvailabilityDetailsNew from "../../providers/providers-forms/AvailabilityDetailsFormNew";
import { Close } from "@mui/icons-material";
import {
  deleteSingleAvailability,
  setAvailabilityDetails,
  setProviderId,
  updateProviderAvailabilityById,
} from "../../../redux/features/providers-main/provider-slice";
import { GetAllProviders } from "../../../redux/features/providers-main/provider-list-slice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IPlanninglistSelect } from "../planning-Details/PlanningsDetails";

interface PlannerProviderListProps {
  providerListData: ProviderForms[];
  activeTab: boolean;
  setBookingChange: Dispatch<React.SetStateAction<boolean>>;
  setSelectedProviders: (item: ProviderForms[]) => void;
  selectedProvider: ProviderForms[];
  setchangedList: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProviderIds: IPlanninglistSelect[];
  filteredProviderData: ProviderForms[];
  setSelectedProviderIds: React.Dispatch<
    React.SetStateAction<IPlanninglistSelect[]>
  >;
  isFilterApplied: boolean;
  setIsAllSelected: React.Dispatch<
    React.SetStateAction<{
      client: boolean;
      provider: boolean;
    }>
  >;
  isAllSelected: {
    client: boolean;
    provider: boolean;
  };
}

const PlannerProviderList: React.FC<PlannerProviderListProps> = ({
  providerListData,
  activeTab,
  setBookingChange,
  setSelectedProviders,
  selectedProvider,
  setchangedList,
  selectedProviderIds,
  setSelectedProviderIds,
  setIsAllSelected,
  isAllSelected,
  filteredProviderData,
  isFilterApplied,
}) => {
  //#region variable region

  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isEdit, setIsEdit] = useState<
    Record<"auth" | "availability", boolean>
  >({
    auth: false,
    availability: false,
  });
  const [isDataEdited, setIsDataEdited] = useState<boolean>(false);
  const [supervisorLength, setSupervisorLength] = useState<number>(0);
  const [availabilityId, setAvailabilityId] = useState<string>();
  const open = Boolean(anchorEl);

  // sort the list from A-Z
  const sortedList = filteredProviderData?.sort(
    (a: ProviderForms, b: ProviderForms) => {
      const nameA = a?.employmentDetails?.firstName?.toUpperCase();
      const nameB = b?.employmentDetails?.firstName?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
  );
  //#endregion

  // #region methods region

  const handleMultiple = (item: ProviderForms, supervisorId: string) => {
    setBookingChange(true);

    const providers = providerListData.filter((ele) =>
      item?.employmentDetails.providerList.includes(ele.employmentDetails.id)
    );
    const providerIds = providers.map((ele) => ele.employmentDetails.id);

    let newSelection = [...selectedProviderIds];
    let provider = selectedProvider;

    [...providerIds, supervisorId].forEach((Id) => {
      const isExist = selectedProviderIds.find(
        (selected) => selected.id === Id
      );
      const isSelected = selectedProvider.some(
        (selected) => selected.employmentDetails.id === Id
      );
      newSelection = newSelection.map((pro) =>
        pro.id === Id ? { ...pro, group: !pro.group } : pro
      );
      if (!isExist) {
        newSelection.push({
          id: Id,
          group: true,
          single: false,
          clientGroup: false,
        });
      }
      if (isSelected && !isExist?.clientGroup && !isExist?.single) {
        provider = provider.filter((i) => i.employmentDetails.id !== Id);
      } else {
        const data = providerListData.find(
          (emp) => emp.employmentDetails.id === Id && !isSelected
        );
        if (data) {
          provider.push(data);
        }
      }
    });
    if (isFilterApplied) {
      provider = provider.filter((item) =>
        filteredProviderData.some(
          (ele) => ele.employmentDetails.id === item.employmentDetails.id
        )
      );
    }
    setSelectedProviderIds(newSelection);
    checkAllSelection(newSelection);
    setSelectedProviders([...provider]);
  };
  const checkAllSelection = (selection: IPlanninglistSelect[]) => {
    const singleSelection = selection.filter((item) => item.single);
    const groupSelection = selection.filter((item) => item.group);
    if (
      ((singleSelection.length < filteredProviderData.length &&
        groupSelection.length === supervisorLength) ||
        (singleSelection.length === filteredProviderData.length &&
          groupSelection.length < supervisorLength)) &&
      isAllSelected.provider
    ) {
      setIsAllSelected({ ...isAllSelected, provider: false });
    } else if (
      singleSelection.length === filteredProviderData.length &&
      groupSelection.length === supervisorLength &&
      !isAllSelected.provider
    ) {
      setIsAllSelected({ ...isAllSelected, provider: true });
    }
  };
  //handle select provider data
  const handleClick = (item: ProviderForms) => {
    console.log("handle click...");
    setBookingChange(true);

    if (!activeTab) {
      const isSelected = selectedProvider.some(
        (pro) => pro.employmentDetails.id === item.employmentDetails.id
      );
      const isExist = selectedProviderIds.find(
        (pro) => pro.id === item.employmentDetails.id
      );

      let newSelection = selectedProviderIds;
      if (isExist) {
        newSelection = selectedProviderIds.map((pro) =>
          pro.id === item.employmentDetails.id
            ? { ...pro, single: !pro.single }
            : pro
        );
      } else {
        newSelection.push({
          id: item.employmentDetails.id,
          group: false,
          single: true,
          clientGroup: false,
        });
      }
      setSelectedProviderIds(newSelection);
      checkAllSelection(newSelection);
      //for single Selection
      if (isSelected) {
        if (!isExist?.clientGroup && !isExist?.group) {
          setSelectedProviders(
            selectedProvider.filter(
              (pro) => pro.employmentDetails.id !== item.employmentDetails.id
            )
          );
        }
      } else {
        setSelectedProviders([...selectedProvider, item]);
      }
    }
  };

  //#endregion

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmitAvailabilityInfo = async () => {
    setIsEdit({ auth: false, availability: false }); //close the dialog first!
    if (availabilityId) {
      await dispatch(
        deleteSingleAvailability({
          availableId: availabilityId,
        })
      );
    }
    await dispatch(updateProviderAvailabilityById());
    await dispatch(GetAllProviders());
    setIsDataEdited(true);
  };

  const handleEdit = (item: ProviderForms) => {
    dispatch(setProviderId({ id: item.employmentDetails.id }));
    dispatch(setAvailabilityDetails(item.availabilityDetails));
  };
  //#region useEffect region

  useEffect(() => {
    if (isDataEdited) {
      const data = providerListData.filter((item) =>
        selectedProvider.some(
          (ele) => ele.employmentDetails.id === item.employmentDetails.id
        )
      );
      setSelectedProviders(data);
      setIsDataEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataEdited]);

  useEffect(() => {
    let count = 0;
    filteredProviderData.forEach((item) => {
      if (item.employmentDetails.isSupervisor) {
        count = 1 + item.employmentDetails.providerList.length;
      }
    });
    setSupervisorLength(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProviderData]);

  //#endregion

  return (
    <div className="mapUserList">
      {sortedList?.map((item: ProviderForms, index: number) => {
        const isSupervisor = item.employmentDetails?.isSupervisor;

        return (
          <>
            {!activeTab && item && (
              <>
                <div
                  className={
                    !activeTab
                      ? selectedProviderIds.some(
                          (pro) =>
                            item.employmentDetails.id === pro.id && pro.single
                        )
                        ? "fromGroup-chip-active"
                        : "userMapCart"
                      : "userMapCart "
                  }
                  key={item.employmentDetails.id}
                >
                  <div className="cardLevelBlock">
                    <table>
                      <thead className="plan-thead">
                        <tr>
                          <th>
                            <Checkbox
                              checked={
                                !activeTab &&
                                selectedProviderIds.some(
                                  (pro) =>
                                    item.employmentDetails.id === pro.id &&
                                    pro.single
                                )
                              }
                              onChange={() => {
                                setchangedList(true);
                                handleClick(item);
                              }}
                            />
                          </th>
                          <th>
                            <span className="userMapCart__img">
                              <img
                                src={
                                  item?.employmentDetails?.providerProfile
                                    ?.url || ProviderImg
                                }
                                className="userSmallCircle"
                                alt=""
                              />
                              {isSupervisor ? (
                                <span className="verifiedIcon">
                                  <UserVerified />
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                          <th style={{ minWidth: "132px" }}>
                            <div className="userMapCart__statusBox">
                              <span className="top-level-title">
                                {item?.providerUtilization?.status}
                              </span>
                              <strong>
                                {item?.employmentDetails?.firstName}{" "}
                                {item?.employmentDetails?.lastName}
                              </strong>
                            </div>
                          </th>
                          <th>
                            {!activeTab && (
                              <div className="userMapCart__statusBox">
                                <span className="top-level-title">
                                  Available{" "}
                                </span>{" "}
                                <strong>
                                  {item?.providerUtilization?.weeklyAvgAvailability?.toFixed(
                                    1
                                  )}
                                </strong>
                              </div>
                            )}
                          </th>
                          <th>
                            {!activeTab && (
                              <div className="userMapCart__statusBox">
                                <span className="top-level-title">Booked </span>{" "}
                                <strong>
                                  {item?.providerUtilization?.weeklyAvgBooked?.toFixed(
                                    1
                                  )}
                                </strong>
                              </div>
                            )}
                          </th>
                          <th>
                            {!activeTab && (
                              <div className="userMapCart__statusBox">
                                <span className="top-level-title">
                                  Remaining{" "}
                                </span>{" "}
                                <strong>
                                  {item?.providerUtilization?.weeklyAvgRemainedByAvailability?.toFixed(
                                    1
                                  )}
                                </strong>
                              </div>
                            )}
                          </th>
                          <th className="p-0">
                            <div style={{ marginBottom: "8px" }}>
                              <button
                                onClick={(
                                  event: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                  handleEdit(item);
                                  setAnchorEl(event.currentTarget);
                                }}
                                style={{
                                  border: "none",
                                  background: "none",
                                  float: "right",
                                  cursor: "pointer",
                                  padding: "0px",
                                }}
                              >
                                <MoreVertIcon color="info" />
                              </button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                {isSupervisor &&
                  item.employmentDetails?.supervisor?.team?.length > 0 && (
                    <div
                      className={
                        !activeTab
                          ? selectedProviderIds.some(
                              (pro) =>
                                item.employmentDetails.id === pro.id &&
                                pro.group
                            )
                            ? "fromGroup-chip-active"
                            : "userMapCart"
                          : // : selectedProviderlist.includes(
                            //     item.employmentDetails.id
                            //   )
                            // ? "fromGroup-chip-active"
                            "userMapCart "
                      }
                      key={(index + 1) * 100}
                    >
                      <div className="cardLevelBlock">
                        <table>
                          <thead className="plan-thead">
                            <tr>
                              <th>
                                <Checkbox
                                  checked={
                                    !activeTab &&
                                    selectedProviderIds.some(
                                      (pro) =>
                                        item.employmentDetails.id === pro.id &&
                                        pro.group
                                    )
                                  }
                                  onChange={() => {
                                    setchangedList(true);
                                    handleMultiple(
                                      item,
                                      item?.employmentDetails?.id
                                    );
                                  }}
                                />
                              </th>
                              <th id="userMapCart">
                                <span className="userMapCart__img">
                                  <img
                                    src={
                                      item?.employmentDetails?.providerProfile
                                        ?.url || ProviderImg
                                    }
                                    className="userSmallCircle"
                                    alt=""
                                  />
                                  {item.employmentDetails?.isSupervisor ? (
                                    <span className="verifiedIcon">
                                      <UserVerified />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                              <th style={{ minWidth: "132px" }}>
                                <div className="userMapCart__statusBox">
                                  <span className="top-level-title">
                                    {item?.providerUtilization?.status}
                                  </span>
                                  <strong>
                                    {item?.employmentDetails?.firstName}{" "}
                                    {item?.employmentDetails?.lastName}
                                  </strong>
                                </div>
                              </th>
                              <th>
                                {!activeTab && (
                                  <div className="userMapCart__statusBox">
                                    <span className="top-level-title">
                                      Available{" "}
                                    </span>{" "}
                                    <strong>
                                      {item?.providerUtilization?.weeklyAvgAvailability?.toFixed(
                                        1
                                      )}
                                    </strong>
                                  </div>
                                )}
                              </th>
                              <th>
                                {!activeTab && (
                                  <div className="userMapCart__statusBox">
                                    <span className="top-level-title">
                                      Booked{" "}
                                    </span>{" "}
                                    <strong>
                                      {item?.providerUtilization?.weeklyAvgBooked?.toFixed(
                                        1
                                      )}
                                    </strong>
                                  </div>
                                )}
                              </th>
                              <th>
                                {!activeTab && (
                                  <div className="userMapCart__statusBox">
                                    <span className="top-level-title">
                                      Remaining{" "}
                                    </span>{" "}
                                    <strong>
                                      {item?.providerUtilization?.weeklyAvgRemainedByAvailability?.toFixed(
                                        1
                                      )}
                                    </strong>
                                  </div>
                                )}
                              </th>
                              <th className="p-7"></th>
                            </tr>
                          </thead>
                          <tbody className="plan-tbody">
                            <PlannerSupervisorList
                              supervisorId={item?.employmentDetails?.id}
                              showSupervisor={false}
                              providerList={
                                item.employmentDetails.supervisor.team || null
                              }
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </>
            )}
          </>
        );
      })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ul className="popoverLinkList">
          <li
            onClick={() => {
              handleClose();
              setAvailabilityId(undefined)
              setIsEdit({ auth: false, availability: true });
            }}
          >
            Edit Availability
          </li>
          {/* <li onClick={() => setIsEdit({ auth: true, availability: false })}>
            Edit Authorization
          </li> */}
        </ul>
      </Popover>
      {isEdit.availability && (
        <Dialog open={isEdit.availability} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Availability
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                handleClose();
                setIsEdit({ auth: false, availability: false });
              }}
            >
              <Close />
            </Button>
          </Box>
          <AvailabilityDetailsNew
            isBoardPage={false}
            isNew={false}
            updateHandler={onSubmitAvailabilityInfo}
            setAvailabilityId={setAvailabilityId}
          />
        </Dialog>
      )}
    </div>
  );
};

export default PlannerProviderList;
