import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvailabilityDetails, SelectedDay } from "../../../types/availability";
import {
  ContactDetails,
  EmploymentDetails,
  Credentials,
  ProviderOnBoardingStages,
  ListData,
} from "../../../types/provider";
import { setAPIStatus } from "../active-steps/active-slice";
import { ProvidersInitialState } from "../../../constants/providers";
import ProviderService from "../../../service/provider.service";
import CommonService from "../../../service/common.service";
import BoardService from "../../../service/customBoard.service";
import { getProviderFilesById, setListDataChange } from "./provider-list-slice";
import { setResponseValue } from "../api-response/api-response";
import { getProviderBoards } from "./provider-board-slice";
// import { id } from "date-fns/locale";
import { ProviderForms } from "../../../types/provider";
// import { FileModelType } from "../../../types/files";
// import moment from "moment";
import { formattedDate } from "../../../constants/common";
import AvailabilityService from "../../../service/availability.service";
// import ProviderTypeService from "../../../service/provider-type.service";

const initialState: ProviderForms = {
  ...ProvidersInitialState,
};

const providerSlice = createSlice({
  name: "employees",
  initialState: initialState,
  reducers: {
    setEmploymentDetailsFromForm: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | string[] | boolean;
      }>
    ) => {

      return {
        ...state,
        employmentDetails: {
          ...state.employmentDetails,
          [action.payload.name]: action.payload.value,
        },
      };
    },
    setLanguages: (state, action: PayloadAction<string>) => {
      let lang = state.employmentDetails.languages.includes(action.payload);
      let newArr: string[] = [];
      if (lang) {
        newArr = state.employmentDetails.languages.filter(
          (item: any) => item !== action.payload
        );
      } else {
        newArr = [...state.employmentDetails.languages];
        newArr.push(action.payload);
      }
      return {
        ...state,
        employmentDetails: {
          ...state.employmentDetails,
          languages: [...newArr],
        },
      };
    },
    //contact section
    setContactDetailsFromForm: (
      state,
      action: PayloadAction<{
        name: string;
        value:
          | string
          | { lat: number; lng: number; fullAddress: string }
          | undefined;
      }>
    ) => {
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          [action.payload.name]: action.payload.value,
        },
      };
    },
    setAddressForProvider: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          address: {
            ...state.contactDetails.address,
            fullAddress: action.payload,
          },
        },
      };
    },
    setAddressProvider: (state, action) => {
      state.contactDetails.address = { ...action.payload };
    },
    /*****availability section **********/
    setBeginDate: (
      state,
      action: PayloadAction<{ availabilityIndex: number; beginDate: string }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].beginDate =
        action.payload.beginDate;
      return state;
    },

    setEndDate: (
      state,
      action: PayloadAction<{ availabilityIndex: number; endDate: string }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].endDate =
        action.payload.endDate;
      return state;
    },

    setDay: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        index: number;
        value: string;
      }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays[
        action.payload.index
      ].dayOfWeek = action.payload.value;
      return state;
    },

    setProviderSelectedDays: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        newAvailability: SelectedDay[];
      }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays =
        action.payload.newAvailability;
      return state;
    },

    removeAvailability: (state, action) => {
      let newAvailability: AvailabilityDetails[] = [];
      if (action.payload?.id !== undefined) {
        newAvailability = state.availabilityDetails.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        newAvailability = state.availabilityDetails.filter(
          (_, index: number) => index !== action.payload.index
        );
      }
      state.availabilityDetails = newAvailability;
    },

    addNewAvailability: (state, action) => {
      return {
        ...state,
        availabilityDetails: [
          ...state.availabilityDetails,
          {
            id: "",
            entityType: "Provider",
            entityId: null, //MUST BE null, cannot be "",
            beginDate: action.payload.beginDate,
            endDate: action.payload.endDate,
            createdAt: "",
            updatedAt: "",
            selectedDays: [
              {
                dayOfWeek: "",
                startTime: "",
                endTime: "",
              },
            ],
            heatMap: [],
            timeSlots: [],
            availabilitySelectedDays: [],
            availabilityName: "",
          },
        ],
      };
    },

    addAvailability: (state, action: PayloadAction<null>) => {
      return {
        ...state,
        availabilityDetails: {
          ...state.availabilityDetails,
          selectedDays: [
            ...(state.availabilityDetails[0].selectedDays
              ? state.availabilityDetails[0].selectedDays
              : []),
            {
              dayOfWeek: "",
              startTime: "",
              endTime: "",
            },
          ],
        },
      };
    },

    deleteAvailability: (
      state,
      action: PayloadAction<{ availabilityIndex: number; index: number }>
    ) => {
      const newAvailability = state.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays.filter(
        (_: any, index: number) => action.payload.index !== index
      );
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays =
        newAvailability;
      // return {
      //   ...state,
      //   availabilityDetails: {
      //     ...state.availabilityDetails,
      //     selectedDays: newAvailability,
      //   },
      // };
      return state;
    },
    // setIsAvailable: (state, action: PayloadAction<number>) => {
    //   state.availabilityDetails.selectedDays[action.payload].isAvailable =
    //     !state.availabilityDetails.selectedDays[action.payload].isAvailable;
    //   return state;
    // },
    setFromTime: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        index: number;
        value: string;
      }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays[
        action.payload.index
      ].startTime = action.payload.value;
      return state;
    },

    setToTime: (
      state,
      action: PayloadAction<{
        index: number;
        value: string;
        availabilityIndex: number;
      }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays[
        action.payload.index
      ].endTime = action.payload.value;
      return state;
    },

    setEmploymentDetails: (state, action: PayloadAction<EmploymentDetails>) => {
      return {
        ...state,
        employmentDetails: {
          ...action.payload,
        },
      };
    },
    setContactDetails: (state, action: PayloadAction<ContactDetails>) => {
      return {
        ...state,
        contactDetails: {
          ...action.payload,
        },
      };
    },
    setProviderId: (state,action) => {
      state.employmentDetails.id = action.payload.id;
      return state;
    },
    setEntityId: (state, action) => {
      state.availabilityDetails[0].entityId = action.payload.id;
      return state;
    },
    setAvailabilityDetails: (
      state,
      action: PayloadAction<AvailabilityDetails[]>
    ) => {
      let data: AvailabilityDetails[] = [];
      if (action.payload?.length > 0) {
        data = [...action.payload].sort(
          (a, b) =>
            (new Date(formattedDate(a.beginDate)) as any) -
            (new Date(formattedDate(b.beginDate)) as any)
        );
      }
      return {
        ...state,
        availabilityDetails: data,
      };
      // return {
      //   ...state,
      //   availabilityDetails: {
      //     ...action.payload,
      //   },
      // };
    },
    setInitialState: (state, action: PayloadAction<null>) => {
      return {
        ...initialState,
      };
    },

    /**********************Credential**********************/
    removeCredential: (state, action) => {
      const num =
        state.providerInsurance.length > 0
          ? state.providerInsurance.length - 1
          : 0;
      let newInsurances: Credentials[] = [];
      if (num > -1) {
        newInsurances = state.providerInsurance.filter(
          (_, index: number) => num !== index
        );
      }
      state.providerInsurance = newInsurances;
    },

    addCredential: (state) => {
      return {
        ...state,
        providerInsurance: [
          ...state.providerInsurance,
          {
            name: "",
            id: "",
            insuranceId: "",
            providerId: "",
            complete: false,
            files: [],
            expirationDate: "",
          },
        ],
      };
    },
    setCredentialInsuranceProvider: (
      state,
      action: PayloadAction<{ index: number; value: string; name: string }>
    ) => {
      state.providerInsurance[action.payload.index].insuranceId =
        action.payload.value;
      state.providerInsurance[action.payload.index].name = action.payload.name;
      return state;
    },
    setCredentialFiles: (state, action) => {
      state.providerInsurance[action.payload.index].files =
        action.payload.files;
      return state;
    },
    setCredentialDetails: (state, action) => {
      return {
        ...state,
        providerInsurance: [...action.payload.providerInsurance],
      };
    },
    setExpirationDate: (
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) => {
      state.providerInsurance[action.payload.index].expirationDate =
        action.payload.value;
      return state;
    },
    deleteCredential: (state, action) => {
      const newCredential = state.providerInsurance.filter(
        (_: any, index: number) => index !== action.payload.index
      );
      return {
        ...state,
        providerInsurance: newCredential,
      };
    },
    setCredentialCheckBox: (
      state,
      action: PayloadAction<{ index: number; value: boolean }>
    ) => {
      state.providerInsurance[action.payload.index].complete =
        action.payload.value;
      return state;
    },
    setInitialStateForCredentialEdit: (state, action) => {
      return {
        ...ProvidersInitialState,
        isEditClient: action.payload.isEditClient,
      };
    },
    deleteCredentialFiles: (state, action) => {
      state.providerInsurance[action.payload.index].files =
        state.providerInsurance[action.payload.index].files.filter(
          (_, index: number) => index !== action.payload.fileIndex
        );
      return state;
    },
    setProviderData: (state, action: PayloadAction<ProviderForms>) => {
      return {
        ...action.payload,
        providerFiles: [...state.providerFiles],
        providerMessages: [...state.providerMessages],
      };
    },
    setProviderFiles: (state, action) => {
      if (!state.providerFiles) {
        state.providerFiles = [];
      }
      state.providerFiles = [...state.providerFiles, ...action.payload];
      return state;
    },
    deleteProviderFile: (state, action) => {
      state.providerFiles = state.providerFiles.filter(
        (item, index) => item.id !== action.payload
      );
      return state;
    },
    setProviderFilesFromResponse: (state, action) => {
      /*if (!state.providerFiles) {
        state.providerFiles = [];
      }
      state.providerFiles = action.payload;*/
      state.providerFiles = [...action.payload];
      return state;
    },
    setProviderFileCategory: (state, action) => {
      state.providerFiles[action.payload.index].category = action.payload.value;
      return state;
    },
    setProviderMessages: (state, action) => {
      state.providerMessages = [...action.payload];
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(submitProvider.pending, (state, action) => {});
    builder.addCase(submitProvider.rejected, (state, action) => {
      setAPIStatus(false);
    });
    builder.addCase(submitProvider.fulfilled, (state, action) => {});
  },  
});


export const submitProvider = createAsyncThunk(
  "providers/addNew",
  async ({ isBoardPage }: { isBoardPage: boolean }, { getState, dispatch }) => {
    dispatch(
      setResponseValue({
        name: "pending",
        value: true,
      })
    );
    let id: string | null = null;
    let { providerSlice } = getState() as { providerSlice: ProviderForms };
    let { providerOnBoardingStage } = getState() as {
      providerOnBoardingStage: ProviderOnBoardingStages;
    };

    try {
      const { data, status, message } =
        await ProviderService.postEmploymentProviderDetails({
          ...providerSlice.employmentDetails,
        });

      if (status) {
        id = data.id;

        //Populate EmploymentDeteail --> Populate BoardCard
        /* 
            Comment out!! since createProvider API will auto-create Provider Card
        const { status: boardStatus } =
          await ProviderService.postProviderCardToBoard(
            id,
            providerOnBoardingStage.onBoardingStages[0].id
          );

        if (boardStatus) {
          dispatch(getProviderBoards({ useLoader: isBoardPage }));
        }
        */
        dispatch(getProviderBoards({ useLoader: isBoardPage }));

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setInitialState(null));
        dispatch(setListDataChange(null));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );

        //Populate contactStatus
        const { status: contactStatus } =
          await ProviderService.postContactProviderDetails({
            ...providerSlice.contactDetails,
            entityId: id,
          });
        if (contactStatus) {
          var newAvailabilityArray: any[] = [];
          providerSlice.availabilityDetails.forEach((item) => {
            const newObj: any = {
              entityId: id || providerSlice.employmentDetails.id,
              beginDate: item.beginDate,
              endDate: item.endDate,
              selectedDays: item.selectedDays,
              availabilitySelectedDay: item.availabilitySelectedDays,

              entityType: "Provider",
              heatMap: item.heatMap,
              id: item.id,
              timeSlots: item.timeSlots,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
            };
            newAvailabilityArray.push(newObj);
          });

          //Populate availability
          const { status: availabilityStatus } =
            await AvailabilityService.createAvailability(
              newAvailabilityArray
            );
        }
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      if (!isBoardPage) {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

export const postSupervisorCases = createAsyncThunk(
  "/provider/supervisor-cases",
  async (
    {
      clientList,
      providerList,
      supervisorId,
    }: { clientList: string[] | string; providerList: string[] | string ; supervisorId: string },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    
    let obj = {
      supervisorId:supervisorId,
      providerList:providerList,
      clientList:clientList
    };
    try {
      const { status, message: responseMessage } =
        await ProviderService.postSupervisorCases(obj);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);


export const postSupervisorTeam = createAsyncThunk(
  "/provider/supervisor-team",
  async (
    {
      clientList,
      providerList,
      supervisorId,
    }: { clientList: string[] | string; providerList: string[] | string ; supervisorId: string },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    
    let obj = {
      supervisorId:supervisorId,
      providerList:providerList,
      clientList:clientList
    };
    try {
      const { status, message: responseMessage } =
        await ProviderService.postSupervisorTeam(obj);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderEmploymentById = createAsyncThunk(
  "providers/updateEmployment",
  async (
    //{ boardId, files }: { boardId?: string; files?: any[] }, //commented by Hui
    { stageId }: { stageId?: string },
    { getState, dispatch }
  ) => {
    let { providerSlice } = getState() as { providerSlice: ProviderForms };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } =
        await ProviderService.updateProviderEmploymentById(
          providerSlice.employmentDetails.id,
          {
            ...providerSlice.employmentDetails,
          }
        );
      if (stageId) {
        const { status } = await BoardService.postCardToBoardStage(
          "Provider", //entityType
          providerSlice.employmentDetails.id,
          stageId
        );
        if (status) {
          dispatch(getProviderBoards({ useLoader: true }));
        }
        /*if (files && files.length > 0) {
            const { status: filesStatus } =
              await CommonService.postFiles(
                "OnBoarding",
                "Provider",
                FileModelType.Provider,
                providerSlice.employmentDetails.id,
                files
              );
            if (filesStatus) {
              //just waiting for the api to finish
            }
          }*/
      }
      if (status) {
        dispatch(setListDataChange(null));
        dispatch(setInitialState(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(getProviderBoards({ useLoader: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderContactById = createAsyncThunk(
  "providers/updateContact",
  async (_, { getState, dispatch }) => {
    let { providerSlice } = getState() as { providerSlice: ProviderForms };
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } =
        await ProviderService.updateProviderContactById(
          providerSlice.contactDetails.id,
          {
            ...providerSlice.contactDetails,
            entityId:providerSlice.employmentDetails.id
          }
        );
      if (status) {
        dispatch(setListDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
        dispatch(setInitialState(null));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderAvailabilityById = createAsyncThunk(
  "providers/updateAvailability",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      let { providerListSlice } = getState() as { providerListSlice: ListData };
      let { providerSlice } = getState() as {
        providerSlice: ProviderForms;
      };

      dispatch(setResponseValue({ name: "pending", value: true }));
      var newAvailabilityArray: any[] = [];
      providerSlice.availabilityDetails.forEach((item) => {
        if (item.entityId === undefined || item.entityId === null) {
          const newObj: any = {
            entityId:
              providerListSlice.singleProvider.employmentDetails.id ||
              providerSlice.employmentDetails.id,
            beginDate: item.beginDate,
            endDate: item.endDate,
            selectedDays: item.selectedDays,
            availabilitySelectedDay: item.availabilitySelectedDays,

            entityType: "Provider",
            heatMap: item.heatMap,
            id: item.id,
            timeSlots: item.timeSlots,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          };
          newAvailabilityArray.push(newObj);
        } else {
          newAvailabilityArray.push(item);
        }
      });

      const { status, message } =
        await AvailabilityService.updateAvailabilityDetails(
          newAvailabilityArray
        );
      if (status) {
        dispatch(setListDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(setInitialState(null));
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderCredentialById = createAsyncThunk(
  "providers/updateCredential",
  async ({ providerId }: { providerId: string }, { getState, dispatch }) => {
    let { providerSlice } = getState() as { providerSlice: ProviderForms };
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } =
        await ProviderService.updateProviderCredentialById(
          providerId,
          providerSlice.providerInsurance
        );
      if (status) {
        dispatch(setListDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
        dispatch(setInitialState(null));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteProviderById = createAsyncThunk(
  "providers/deleteProvider",
  async ({ providerId }: { providerId: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await ProviderService.DeleteProviderById(
        providerId
      );
      if (status) {
        dispatch(setListDataChange(null));
        dispatch(setInitialState(null));
        dispatch(getProviderBoards({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderType = createAsyncThunk(
  "providers/updateProviderType",
  async (
    {
      providerType,
      reqData,
      stageId,
    }: {
      providerType: { name: string; value: string | string[] };
      reqData: any;
      stageId: string | null;
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } =
        await ProviderService.updateProviderEmploymentById(reqData.id, {
          ...reqData,
          [providerType.name]: providerType.value,
        });

      if (stageId) {
        const { status } = await BoardService.postCardToBoardStage(
          "Provider", //entityType
          reqData.id,
          stageId
        );
        if (status) {
          dispatch(getProviderBoards({ useLoader: false }));
        }
      }

      if (status) {
        dispatch(setListDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderProfile = createAsyncThunk(
  "providers/updateProviderProfile",
  async (
    { fileObj, id }: { fileObj: any; id: string },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await ProviderService.uploadProviderImage(
        fileObj,
        id
      );

      if (status) {
        dispatch(getProviderBoards({ useLoader: false }));
        dispatch(setListDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(getProviderBoards({ useLoader: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteSingleAvailability = createAsyncThunk(
  "/deleteavailability/id",
  async ({ availableId }: { availableId: string }, { dispatch, getState }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    let { providerSlice } = getState() as { providerSlice: ProviderForms };

    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await AvailabilityService.deleteAvailabilityById(
        availableId
      );
      if (status) {
        const newAvailability = providerSlice.availabilityDetails.filter(
          (item) => item.id !== availableId
        );
        dispatch(setAvailabilityDetails(newAvailability));

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteProviderFileById = createAsyncThunk(
  "providers/deleteProviderFile",
  async ({ fileId }: { fileId: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await CommonService.deleteFileById(fileId);
      if (status) {
        dispatch(deleteProviderFile(fileId));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateProviderFileNameById = createAsyncThunk(
  "provider/updateProviderFileName",
  async (
    {
      id,
      fileName,
      providerId,
    }: { id: string; fileName: string; providerId: string },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await CommonService.updateFileNameById(
        id,
        fileName
      );
      if (status) {
        dispatch(getProviderFilesById({ providerId }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setLanguages,
  setEndDate,
  setBeginDate,
  // setIsAvailable,
  setFromTime,
  setToTime,
  setEntityId,
  setInitialState,
  setEmploymentDetails,
  setContactDetails,
  setAvailabilityDetails,
  setEmploymentDetailsFromForm,
  setContactDetailsFromForm,
  setAddressForProvider,
  setDay,
  removeAvailability,
  addNewAvailability,
  addAvailability,
  deleteAvailability,
  //credential
  addCredential,
  setCredentialInsuranceProvider,
  setCredentialFiles,
  deleteCredential,
  deleteCredentialFiles,
  setExpirationDate,
  setCredentialCheckBox,
  setCredentialDetails,
  setInitialStateForCredentialEdit,
  setAddressProvider,
  removeCredential,
  setProviderData,
  setProviderFiles,
  deleteProviderFile,
  setProviderFilesFromResponse,
  setProviderFileCategory,
  setProviderMessages,
  setProviderSelectedDays,
  setProviderId
} = providerSlice.actions;

export default providerSlice;
