import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { GetRuns, IRuns } from "../../../../redux/features/runs/runs-slice";

// Custom Table Col style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

// Custom Table Row style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

// Custom Table
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

const Runs: React.FC = () => {
  //#region Variable region
  const dispatch = useAppDispatch();
  const runs = useAppSelector<IRuns[]>(
    (state) => state.runsSlice.filter((item) => 
      item.jobType === "Board Automation" || 
      item.jobType === "Alert Automation"));
  //#endregion

  //#region useEffect region

  //featch intial run data
  useEffect(() => {
    dispatch(GetRuns());
  }, []);

  // console.log("Runs", runs);

  //#endregion

  return (
    <div className="listingTable">
      {/* <div className="listingTable__header">
        <Typography className="listingTable__title" gutterBottom variant="h6">
          Runs
        </Typography>
      </div> */}
      <TableContainer component={Paper} className="tableResponsive">
        <StyledTable
          sx={{
            minWidth: 820,
            borderCollapse: "separate",
            cursor: "pointer",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Job Type</StyledTableCell>
              <StyledTableCell>Job Name</StyledTableCell>
              <StyledTableCell>Entity Type</StyledTableCell>
              <StyledTableCell>Entity Name</StyledTableCell>
              <StyledTableCell align="center">Start Time</StyledTableCell>
              <StyledTableCell align="center">Duration</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runs
              ?.sort((a, b) => {
                return (
                  new Date(b.updatedAt).getTime() -
                  new Date(a.updatedAt).getTime()
                );
              })
              ?.map((run: any) => (
                <StyledTableRow style={{ borderRadius: 20 }}>
                  <StyledTableCell component="th">
                    {run.jobType}
                  </StyledTableCell>
                  <StyledTableCell component="th">
                    {run.jobName}
                  </StyledTableCell>
                  <StyledTableCell component="th">
                    {run.entityType}
                  </StyledTableCell>
                  <StyledTableCell component="th">
                    {run.entityName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(run.createdAt).toLocaleDateString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {(
                      Math.abs(
                        new Date(run.updatedAt).getTime() -
                          new Date(run.createdAt).getTime()
                      ) / 1000
                    ).toFixed(2) + " "}
                    Sec
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box className="fromGroup-chips">
                      <Card>{run.status}</Card>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </div>
  );
};
export default Runs;
