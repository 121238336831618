import React, { useCallback, useEffect, useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { Box, Button, FormHelperText, Grid } from "@mui/material";
import "./surveyCreate.scss";
import { setLicenseKey } from "survey-core";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  PostCustomForm,
  UpdateFormDataById,
} from "../../../redux/features/intake/intake-form-slice";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GetFormById } from "../../../redux/features/forms/form-slice";
import { ReactElementFactory } from "survey-react-ui";
import { MyCoolCodeBlock } from "./Snippets";
import { surveyComponent } from "./surveydata";
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";
import "survey-core/i18n/spanish";
import axios from "axios";
import { Serializer } from "survey-core";

//Added template for embed survey
class TabTemplateComponent extends React.Component {
  render() {
    //acesss survey data
    const { url }: any = this.props;

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    var mainDivStyle = {
      padding: "7px",
      width: "100%",
    };

    //used to update url in embeddedcode
    const updateUrl = surveyComponent.markup.replace(
      '<embed id="embedFrame" src="" />',
      url === undefined
        ? ` <embed id="embedFrame" src="${BASE_URL}/{FormId}" />`
        : ` <embed id="embedFrame" src="${BASE_URL}${url}" />`
    );

    //used to handle previous location
    // let jsonData = {
    //   data: `export const json = ${creator.text}`,
    // };

    var titleClassName = "sd-title sd-page__title sdMainTitle";
    return (
      <React.StrictMode>
        <div style={mainDivStyle}>
          <h2 className={titleClassName}>SurveyComponent.html</h2>
          {url === undefined && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              Embedded code will generated after creation of form.
            </FormHelperText>
          )}
          <MyCoolCodeBlock
            code={updateUrl}
            language={"markup"}
            showLineNumbers={false}
            startingLineNumber={0}
          />
        </div>
      </React.StrictMode>
    );
  }
}

const SurveyCreate = () => {
  //#region variable region
  const { id } = useParams();
  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //track path
  const navigate = useNavigate();

  //stores form type
  const [formtype, setFormType] = useState<string>("");

  //stores translated json data
  const [translatedCreator] = useState<any>();

  //stores if translation is done or not
  const [isCompleteTransalte] = useState(false);

  //Added data on embed survey tab
  ReactElementFactory.Instance.registerElement("svc-tab-template", (props) => {
    return React.createElement(TabTemplateComponent, {
      ...props,
      url: state?.url,
    });
  });
  //set commercial license
  setLicenseKey(
    "ODU2MjZmNjUtNGI0Ny00OGJlLWJkNmMtYjg0OGY3NGY5MDllOzE9MjAyNC0wOS0yNw=="
  );
  //used to handle previous location
  const { state } = useLocation();

  //passes event from creator for handle tabs
  const options = {
    // haveCommercialLicense: true,
    showLogicTab: true,
    showJSONEditorTab: false,
    showTranslationTab: true,
  };
  surveyLocalization.supportedLocales = ["en", "es"];

  Serializer.findProperty("file", "allowMultiple").defaultValue = true;
  Serializer.findProperty("file", "waitForUpload").defaultValue = true;
  Serializer.findProperty("file", "needConfirmRemoveFile").defaultValue = true;
  Serializer.findProperty("file", "storeDataAsText").defaultValue = false;
  
  //call and create surveyCreator instant
  const creator = new SurveyCreator(options);
  // creator.locale = "es";
  //template plugin
  const templatesPlugin: any = {
    activate: () => {},
    deactivate: () => {
      return true;
    },
  };

  //Add plug-in. We do nothing on activate/deactivate. Place it as first tab and set to "svc-tab-template" the component name
  creator.addPluginTab(
    "templates",
    templatesPlugin,
    "Embed Survey",
    "svc-tab-template",
    5
  );

  //Automatically save survey definition on changing. Hide "Save" button
  creator.isAutoSave = true;

  //#endregion

  //#region methods region

  //used to save survey callback
  creator.saveSurveyFunc = function (saveNo: any, callback: any) {
    //save the survey JSON
    callback(saveNo, true);
  };

  // handle update and fetch all template
  const updateForm = async (id: string) => {
    const data = await dispatch(GetFormById({ formId: id }));
    if (data.payload) {
      if (isCompleteTransalte) {
        creator.JSON = translatedCreator;
      } else creator.JSON = data.payload?.formSchema;
      setFormType(data.payload.formType);
    }
  };

  creator.onMachineTranslate.add(async (_, options) => {
    try {
      const texts = options.strings;
      const translatedTexts = await translateText(texts);

      // Update translation tab with the translated data
      const translationData: any = {};
      for (let i = 0; i < texts.length; i++) {
        translationData[texts[i]] = translatedTexts[i];
      }
      options.strings = translationData;

      options.callback(translatedTexts);
    } catch (error) {
      // If translation was unsuccessful:
      options.callback(["fail"]);
      alert("Could not translate strings to the Spanish locale");
    }
  });

  //handles routes back
  const handleRouteBack = useCallback(
    (type: string) => {
      console.log("type:", type);
      if (type === "Employee Packet Form") {
        navigate("/business-settings", {
          state: {
            tab: "Forms",
            group: "Onboarding",
          },
        });
      } else {
        navigate("/business-settings", {
          state: {
            tab: "Forms",
            group: "Intake",
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.type]
  );

  // handle submit data
  const handleSubmit = () => {
    let data = JSON.parse(creator.text);
    let formName;
    if (data.locale === "es") {
      formName = data?.title?.es;
    } else {
      formName = data?.title?.default || data?.title;
    }
    if (
      id !== undefined &&
      id !== "newemployeeform" &&
      id !== "newintakeform"
    ) {
      if (data.title !== undefined) {
        dispatch(
          UpdateFormDataById({
            id: id,
            name: formName,
            jsonData: data,
            formType: state?.type || formtype,
          })
        );
        handleRouteBack(state?.type || formtype);
      } else {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Please set your form Title",
          })
        );
      }
    } else {
      if (data.title !== undefined) {
        dispatch(
          PostCustomForm({
            name: formName,
            jsonData: data,
            type: "Internal",
            formType: state?.type || formtype,
          })
        );
        handleRouteBack(state?.type || formtype);
      } else {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Please set your form Title",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isCompleteTransalte) {
      creator.JSON = translatedCreator;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleteTransalte]);

  //openAI API integration for translation
  const translateText = async (texts: any) => {
    try {
      const { data } = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "Translate the following English text to Spanish:",
            },
            { role: "user", content: texts.join("|") }, // Join all texts with "|"
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
        }
      );

      const translatedTexts = data.choices[0].message.content.split("|");
      return translatedTexts;
    } catch (error) {
      console.error(error);
      throw new Error("Error occurred during translation");
    }
  };

  //translate each labels of form
  // const translateLabels = async () => {
  //   try {
  //     const translatedData = JSON.parse(creator.text); // Deep copy the original data
  //     const textsToTranslate = [];
  //     if (translatedData?.title) {
  //       textsToTranslate.push(translatedData.title);
  //     }
  //     for (const page of translatedData.pages) {
  //       for (const element of page?.elements) {
  //         // if (element.title !== undefined) { // Check if title exists
  //         textsToTranslate.push(element?.title);
  //         // }
  //         if (element.choices !== undefined) {
  //           for (const choice of element.choices) {
  //             textsToTranslate.push(choice.text);
  //           }
  //         }
  //       }
  //     }

  //     const translatedTexts = await translateText(textsToTranslate);

  //     let translatedIndex = 0;
  //     if (translatedData.title) {
  //       translatedData.title = translatedTexts[translatedIndex++];
  //     }
  //     for (const page of translatedData.pages) {
  //       for (const element of page.elements) {
  //         // if (element.title !== undefined) { // Check if title exists
  //         element.title = translatedTexts[translatedIndex++];
  //         // }
  //         if (element.choices) {
  //           for (const choice of element.choices) {
  //             choice.text = translatedTexts[translatedIndex++];
  //           }
  //         }
  //       }
  //     }
  //     setTranslatedCreator(translatedData);
  //     setCompleteTransalte(true);
  //   } catch (error: any) {
  //     console.error(error.message);
  //     // Handle translation error
  //     return null;
  //   }
  // };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    if (id !== undefined) {
      if (id === "newintakeform") {
        setFormType("Intake Packet Form");
      } else if (id === "newemployeeform") {
        setFormType("Employee Packet Form");
      } else if (id !== "newemployeeform" && id !== "newintakeform") {
        updateForm(id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateForm]);

  return (
    <Grid className="pageTemplate">
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ gap: "10px" }}
      >
        <Button
          onClick={() => handleRouteBack(state?.type || formtype)}
          className="backBtn"
          startIcon={<KeyboardBackspaceIcon />}
          style={{ marginBottom: 0 }}
        >
          Back
        </Button>
        <div>
          <Button
            variant="outlined"
            sx={{ margin: "0px 5px" }}
            onClick={handleSubmit}
          >
            Save Form
          </Button>
        </div>
      </Box>
      <Box className="survaytemplate">
        <SurveyCreatorComponent creator={creator} />
      </Box>
    </Grid>
  );
};

export default SurveyCreate;
