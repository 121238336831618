import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IRuns } from "../../redux/features/runs/runs-slice";
import SyncIcon from "@mui/icons-material/Sync";
import { useDispatch } from "react-redux";
import {
  GetSyncRequestbyAppName,
  RefreshSyncRequests,
} from "../../redux/features/integration-setting/integration-setting-slice";
import { ISyncDataRequests } from "../../types/businessSettings";
import { exportDataSets, JobStatus } from "../../constants/tools";
import moment from "moment";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
interface ISyncRequests {
  appName: string;
}

const SyncRequest: React.FC<ISyncRequests> = ({ appName }) => {
 
  //#region variable region
  const dispatch = useAppDispatch();
  const [filterValues, setFilterValues] = useState<{
    entityType: string[];
    status: string[];
  }>({
    entityType: [],
    status: [],
  });

  const syncRequests = useAppSelector<ISyncDataRequests[]>(
    (state) => state.integrationSettingSlice.syncDataLogs[appName]
  );

  //#endregion 
  
  //#region grid region
  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }
  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  function dataBound() {
    // console.log("[dataBound]");
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }

  const datatemplate = (props: any) => {
    return (
      <div style={{ padding: "10px 0px" }}>
        {props?.column.field === "jobType" && (
          <Typography>{props.jobType}</Typography>
        )}
        {props?.column.field === "eventType" && (
          <Typography>{props.eventType}</Typography>
        )}
        {props?.column.field === "entityName" && (
          <Typography>{props?.entityName}</Typography>
        )}
        {props?.column.field === "appName" && (
          <Typography>{props?.appName}</Typography>
        )}
        {props?.column.field === "externalId" && (
          <Typography>{props?.externalId}</Typography>
        )}
        {props?.column.field === "errorMessage" && (
          <Typography>{props?.errorMessage}</Typography>
        )}
      </div>
    );
  };

  const StatusTemplate = (props: any) => {
    return (
      <div className={`history-status ${props.status.replace(" ", "")}`}>
        {props?.status}
      </div>
    );
  };
  // #endregion

  const filteredData = (): ISyncDataRequests[] => {
    return syncRequests?.filter((item) => {
      const matchesEntityType =
        filterValues.entityType.length === 0 ||
        filterValues.entityType.includes(item.entityType);
      const matchesStatus =
        filterValues.status.length === 0 ||
        filterValues.status.includes(item.status);

      return matchesEntityType && matchesStatus;
    });
  };

  useEffect(() => {
    dispatch(GetSyncRequestbyAppName({ appName: appName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="header-syncRequest">
        <div>
          <div className="referesh">
            <Typography className="refresh-title">
              Sync Data Requests
            </Typography>
            <Button
              onClick={() =>
                dispatch(RefreshSyncRequests({ appName: appName }))
              }
            >
              <SyncIcon color="primary" fontSize="large" />
            </Button>
          </div>
          {syncRequests?.length > 0 && (
            <Typography className="sync-description">
              last Sync : {moment(syncRequests[0]?.updatedAt).format("LLL")}
            </Typography>
          )}
        </div>

        <div className="filter-syncRequest">
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              Entity Type
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterValues.entityType}
              onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  entityType: e.target.value as string[],
                });
              }}
              input={<OutlinedInput label="Entity Type" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {exportDataSets.map((item, i) => (
                <MenuItem value={item.value} key={i}>
                  <Checkbox
                    checked={filterValues.entityType.indexOf(item.value) > -1}
                  />
                  <ListItemText primary={item.entityType} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterValues.status}
              onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  status: e.target.value as string[],
                });
              }}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {Object.values(JobStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={filterValues.status.indexOf(status) > -1}
                  />
                  <ListItemText primary={status} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <GridComponent
        id="grid" //must have!!
        className="gridTable"
        dataSource={filteredData()}
        dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        allowSelection={false}
        allowGrouping={true}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        allowPdfExport={true}
        allowExcelExport={true}
        pdfExportComplete={pdfExportComplete}
        excelExportComplete={excelExportComplete}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
            width="150"
          />
          <ColumnDirective
            headerText="Job Type"
            width="150"
            textAlign="Left"
            field="jobType"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="EventType"
            width="200"
            textAlign="Left"
            field="eventType"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Entity Name"
            width="200"
            textAlign="Left"
            field="entityName"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="App Name"
            width="200"
            textAlign="Left"
            field="appName"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="External Id"
            width="200"
            textAlign="Left"
            field="externalId"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Errors"
            width="200"
            textAlign="Left"
            field="errorMessage"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="status"
            width="150"
            textAlign="Left"
            field="status"
            template={StatusTemplate}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Group,
            ContextMenu,
            Toolbar,
            Resize,
            DetailRow,
            Filter,
            Sort,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default SyncRequest;
