import React, { Dispatch, SetStateAction, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BorderColor, Close, ContentCopy } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  addAuthorization,
  CopyAuthorizationById,
  deleteSingleAuthorization,
  removeAuthorization,
  setAuthorizationDetails,
  setEditClient,
  setInitialState,
  updateClientAuthorizations,
} from "../../../../redux/features/client-main/client-slice";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";
import { setListDataChange } from "../../../../redux/features/client-main/client-list-slice";

import ClientService from "../../../../service/client.service";

import ClientEditAuthorizationForm from "../../client-helper-forms/ClientEditAuthorizationForm";
import ClientAddAuthorizationForm from "../../client-helper-forms/ClientAddAuthorizationForm";

import {
  ClientAuth,
  ClientAuthPayload,
  InsuranceProviders,
} from "../../../../types/client";

import "./../ClientDetails.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    verticalAlign: "baseline",
    whiteSpace: "nowrap",
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface ClientSubAuthorizationsProps {
  authorizations: ClientAuthPayload[];
  clientId: string;
  authIndex: number | null;
  setAuthIndex: Dispatch<SetStateAction<number | null>>;
  activeTab: string | null;
  setActiveTab: Dispatch<SetStateAction<string | null>>;
  editAuthorizationsInfo: boolean;
  clientInsurance: InsuranceProviders[];
  setEditAuthorizationsInfo: Dispatch<SetStateAction<boolean>>;
}

const ClientSubAuthorizations: React.FC<ClientSubAuthorizationsProps> = ({
  clientInsurance,
  authorizations,
  clientId,
  authIndex,
  setAuthIndex,
  activeTab,
  setActiveTab,
  editAuthorizationsInfo,
  setEditAuthorizationsInfo,
}) => {
  // #region variables region

  //handles the edit for client authorizations details
  const [addAuthorizationsInfo, setAddAuthorizationsInfo] =
    useState<boolean>(false);

  //dispatch for redux
  const dispatch = useAppDispatch();

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  // #endregion

  // #region methods region

  //handles the close logic when save changes or close edit form for client authorizations details
  const handleCloseForAuthorizationsDetails = () => {
    setActiveTab(null);
    setAuthIndex(null);
    if (!isEditClient) {
      dispatch(setInitialState({}));
    }

    dispatch(setEditClient(false));
    setEditAuthorizationsInfo(false);
    setAddAuthorizationsInfo(false);
  };

  //handles the edit form submission for client authorizations details
  const onSubmitAuthorizationsInfo = () => {
    dispatch(updateClientAuthorizations({ clientId: clientId }));
    setEditAuthorizationsInfo(false);
    setAddAuthorizationsInfo(false);
    dispatch(setEditClient(false));
  };

  //handles deleting an authorization
  const handleDeleteAuthorization = (id: string) => {
    setEditAuthorizationsInfo(false);
    dispatch(deleteSingleAuthorization({authId:id}))
    setAuthIndex(0);
    setActiveTab("basic");

  };

  // handle copy auth
  const handleCopyAuth = (id: string) => {
    dispatch(CopyAuthorizationById({ id: id }));
  };

  return (
    <Grid item xs={12} mt="22px">
      <div className="listingTable" style={{ marginBottom: 0 }}>
        <div className="listingTable__header">
          <Typography className="listingTable__title" gutterBottom variant="h6">
            Authorizations
          </Typography>
          <div style={{ position: "relative", textAlign: "right" }}>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                dispatch(addAuthorization({}));
                dispatch(setEditClient(true));
                setAddAuthorizationsInfo(true);
              }}
              className="addBtn"
            >
              + Add New Authorization
            </Button>
          </div>
        </div>
        <TableContainer className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Insurance</StyledTableCell>
                <StyledTableCell>Auth Period</StyledTableCell>
                <StyledTableCell>Auth Codes</StyledTableCell>
                <StyledTableCell># of Units</StyledTableCell>
                <StyledTableCell>Files</StyledTableCell>
                <StyledTableCell>Update On</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {authorizations
                ?.filter((item: ClientAuthPayload) => item.id)
                ?.map((authorization: ClientAuthPayload, index: number) => {
                  return (
                    <StyledTableRow
                      style={{ borderRadius: 20 }}
                      // key={`${row.clientBasicDetails + idx}`}
                      key={authorization.id}
                    >
                      <StyledTableCell component="th">
                        {authorization.authorizationName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization.insurance?.insuranceProviderName}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {authorization?.authPeriod?.startDate}
                        {/* {moment(authorization?.authPeriod?.startDate).format(
                          "DD/MM/YYYY"
                        )} */}{" "}
                        - {authorization?.authPeriod?.endDate}
                        {/* {moment(authorization?.authPeriod?.endDate).format(
                          "DD/MM/YYYY"
                        )} */}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization?.auth.map((item: ClientAuth) => (
                          <div key={item.authCode}>{item.authCode}</div>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization?.auth.map((item: ClientAuth) => (
                          <div key={item.authCode}>{item.units}</div>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization?.files?.map((item: any) => {
                          if (item?.key) {
                            return <div key={item.key}>{item.fileName}</div>;
                          }
                          return (
                            <div key={item.fileName}>{item?.fileName}</div>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization.updatedAt}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="setDate">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <BorderColor
                              onClick={() => {
                                dispatch(setEditClient(true));
                                setAuthIndex(index);
                                dispatch(
                                  setAuthorizationDetails({
                                    authorization: [...authorizations],
                                  })
                                );
                                setEditAuthorizationsInfo(true);
                              }}
                            />
                            <ContentCopy
                              color="primary"
                              onClick={() => handleCopyAuth(authorization.id)}
                            />
                          </span>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </div>
      {isEditClient && editAuthorizationsInfo && (
        <>
          <Dialog
            open={editAuthorizationsInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client - Authorizations
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForAuthorizationsDetails}
              >
                <Close />
              </Button>
            </Box>
            {/* <AuthorizationsDetails
              onSubmit={onSubmitAuthorizationsInfo}
              deleteAuthorizationFile={deleteAuthorizationFile}
              deleteSingleAuthorization={deleteSingleAuthorization}
              authIndex={authIndex}
              activeTabForAuthorization={activeTab}
              isEdit={true}
              headerTitle={headerTitle}
            /> */}
            <ClientEditAuthorizationForm
              authorizationIndex={authIndex || 0}
              clientInsurance={clientInsurance}
              // authorization={authorizations[authIndex || 0]}
              activeTabFromParent={activeTab || "basic"}
              deleteSingleAuthorization={handleDeleteAuthorization}
              //deleteAuthorizationFile={deleteAuthorizationFile}
              onSubmit={onSubmitAuthorizationsInfo}
              authorizations={authorizations}
            />
          </Dialog>
        </>
      )}
      {isEditClient && addAuthorizationsInfo && (
        <>
          <Dialog
            open={addAuthorizationsInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Add Client Authorization
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  handleCloseForAuthorizationsDetails();
                  dispatch(removeAuthorization({}));
                }}
              >
                <Close />
              </Button>
            </Box>
            {/* <AuthorizationsDetails
              onSubmit={onSubmitAuthorizationsInfo}
              deleteAuthorizationFile={deleteAuthorizationFile}
              deleteSingleAuthorization={deleteSingleAuthorization}
              authIndex={authIndex}
              activeTabForAuthorization={activeTab}
              isEdit={true}
              headerTitle={headerTitle}
            /> */}
            <ClientAddAuthorizationForm
              authorizationIndex={authorizations ? authorizations.length : 0}
              clientInsurance={clientInsurance} // authorization={authorizations[authIndex || 0]}
              activeTabFromParent={activeTab || "basic"}
              deleteSingleAuthorization={handleDeleteAuthorization}
              //deleteAuthorizationFile={deleteAuthorizationFile}
              onSubmit={onSubmitAuthorizationsInfo}
              // authorizations={authorizations}
            />
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default ClientSubAuthorizations;
