import { Handle, Position } from "reactflow";
import Verified from "../../assets/images/images-svg/verified.svg";
import Provider from "../../assets/images/images-png/provider.png";
import "./PlanningTwo.scss";
import { IPlanCardData } from "../../types/planning";

interface nodeinterface {
  data: IPlanCardData;
}

const SingleCard: React.FC<nodeinterface> = ({ data }) => {
  return (
    <div key={data.id}>
      <Handle type="target" position={Position.Left} />
      <div className="Detailboxpart__box">
        <div className="planCard bg-transparant">
          <div>
            <div className="profile">
              <img src={data?.profile?.url || Provider} alt="profile" />
              {data.super && (
                <img
                  className="verified-icon"
                  src={Verified}
                  alt="supervisor"
                />
              )}
            </div>
            <h6>{data.label}</h6>
          </div>
          <div>
            <div className="bookingStatus">
              <span>Booked</span>
              <h5>{data.bookedHours}</h5>
            </div>
            <div className="bookingStatus">
              <span>Remaining</span>
              <h5>{data.remainingHours}</h5>
            </div>
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default SingleCard;
