import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import PaymentSystemService from "../../../service/paymentSystem.service";
import {
  ICurrentPlan,
  IPayment,
  IStripeProduct,
  ITenantUsage,
} from "../../../types/product";
import { getOrgInfo } from "../organization/organization-slice";

const initialState: {
  products: IStripeProduct[], 
  tenantUsage: ITenantUsage,
  payments: IPayment[],
  currentPlan: ICurrentPlan
} = {
  products: [],
  tenantUsage: {
    adminCount: 0,
    clientCount: 0,
    providerCount: 0,
  },
  payments: [],
  currentPlan: {
    currentPlanId: "",
    currentSubscriptionId: "",
    adminSeats: 0,
    planDetail: {
      productId: "",
      title: "",
      features: [],
      price: 0,
      isPopular: false,
      clientLimit: 0,
      adminCovered: 0,
      nextProductId: "",
      additionalAdminPlan: "",
    },
  },
};

const planBillingSlice = createSlice({
  name: "planBillingSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getProducts.fulfilled,
      (state, action: PayloadAction<IStripeProduct[]>) => {
        if (action.payload) {
          return {
            ...state,
            products: action.payload,
          };
        }
      }
    );
    builder.addCase(getTenantUsage.fulfilled,
      (state, action: PayloadAction<ITenantUsage>) => {
        if (action.payload) {
          return {
            ...state,
            tenantUsage: action.payload,
          };
        }
      } 
    );
    builder.addCase(getTenantPayments.fulfilled,
      (state, action: PayloadAction<IPayment[]>) => {
        if (action.payload) {
          return {
            ...state,
            payments: action.payload,
          };
        }
      }
    );
    builder.addCase(getTenantPlan.fulfilled,
      (state, action: PayloadAction<ICurrentPlan>) => {
        if (action.payload) {
          return {
            ...state,
            currentPlan: action.payload,
          };
        }
      }
    );
    builder.addCase(UpdateSubscription.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          payments: action.payload,
        };
      }
      return state;
    });
  },
});

export const getProducts = createAsyncThunk(
  "planBillingSlice/getProducts",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: false }));
    try {
      const { status, data } = await PaymentSystemService.getProducts();
      if (status) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getTenantUsage = createAsyncThunk(
  "planBillingSlice/getTenantUsage",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: false }));
    try {
      const { status, data } = await PaymentSystemService.getTenantUsage();
      if (status) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getTenantPlan = createAsyncThunk(
  "planBillingSlice/getTenantPlan",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: false }));
    try {
      const { status, data } = await PaymentSystemService.getTenantPlan();
      if (status) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getTenantPayments = createAsyncThunk(
  "planBillingSlice/getTenantPayments",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: false }));
    try {
      const { status, data } = await PaymentSystemService.getTenantPayments();
      if (status) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const checkoutPayment = createAsyncThunk(
  "planBillingSlice/checkoutPayment",
  async (
    { productId, adminSeats }: { productId: string; adminSeats: number },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: false }));
    try {
      const { status, message, data } = await PaymentSystemService.checkoutPayment(
        productId, 
        adminSeats
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateSubscription = createAsyncThunk(
  "planBillingSlice/UpdateSubscription",
  async (
    { productId, adminSeats }: { productId: string; adminSeats: number },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message, data } = await PaymentSystemService.updateSubscription(
        productId,
        adminSeats
      );
      if (status) {
        dispatch(getOrgInfo({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);


export default planBillingSlice;
