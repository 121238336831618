import React, { useState } from "react";
import "./tools.scss";
import ExportClientsProviderDetails from "./export-tools/ExportClientsProviderDetails";
import ImportDetails from "./import-tools/ImportDetails";
import History from "./History";

const Tools: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>("Export");

  return (
    <div className="pageTemplate" style={{ position: "relative" }}>
      <h3 className="settingPage__title">Import / Export</h3>
      <div className="pageTemplate-top" style={{ width: "fit-content" }}>
        <ul className="innerTabs mb-tab">
          <li className="innerTabs__item form-tabs">
            <button
              onClick={() => setSelectedTab("Export")}
              className={selectedTab === "Export" ? "active" : ""}
            >
              Export
            </button>
          </li>
          <li
            className="innerTabs__item form-tabs"
            onClick={() => {
              setSelectedTab("Import");
            }}
          >
            <button className={selectedTab === "Import" ? "active" : ""}>
              Import
            </button>
          </li>

          <li
            className="innerTabs__item form-tabs"
            onClick={() => setSelectedTab("History")}
          >
            <button className={selectedTab === "History" ? "active" : ""}>
              History
            </button>
          </li>
        </ul>
      </div>
      {selectedTab === "Export" && <ExportClientsProviderDetails />}
      {selectedTab === "Import" && <ImportDetails />}
      {selectedTab === "History" && <History />}
    </div>
  );
};

export default Tools;
