import axios from "axios";
import { AllUserMessage, IComments, IMessage } from "../types/message";

export const comments: IComments = {
  createdAt: "",
  fromUser: {
    firstName: "",
    lastName: "",
    email: "",
    profilePictureUrl: "",
  },
  fromName: "",
  comment: "",
  files: [],
};

export const MessageInfo: IMessage = {
  allMessageData: {
    allUserMessage: [],
    externalCount: 0,
    internalCount: 0,
    UpdateCount: 0,
  },
  openedMessage: null,
  translatedMessage: null,
  unreadUserId:[]
};

export const sortData = (Comments: AllUserMessage[]) => {
  let data = Comments.sort((a, b) => {
    return (new Date(b.createdOn) as any) - (new Date(a.createdOn) as any);
  });
  return data;
};

// export const translateData = async (
//   message: AllUserMessage
// ): Promise<AllUserMessage | false> => {
//   const keyword = ["subject", "message", "comments"];
//   const finalTranslatedMessage = { ...message };
//   const finalTranslatedComments = [...message.comments];

//   const mod1: any = {};
//   Object.entries(message).forEach(([key, value]) => {
//     if (value && keyword.includes(key)) {
//       if (key === "comments" && Array.isArray(value)) {
//         value.forEach((comment: any, index) => {
//           mod1[`comment-${index}`] = comment.comment;
//         });
//       } else {
//         mod1[key] = value;
//       }
//     }
//   });
//   const mod2 = Object.values(mod1).join("|");

//   const { data: language } = await axios.post(
//     "https://api.openai.com/v1/chat/completions",
//     {
//       model: "gpt-3.5-turbo",
//       messages: [
//         {
//           role: "system",
//           content: "What language is this text written in:",
//         },
//         { role: "user", content: mod2 },
//       ],
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${process.env.REACT_APP_OPENAI_SK}`,
//       },
//     }
//   );

//   console.log({
//     cat: language?.choices[0]?.message?.contact?.split(" ")?.pop(),
//     raw: language?.choices[0]?.message?.content?.split(" ")?.pop(),
//   });

//   const { data } = await axios.post(
//     "https://api.openai.com/v1/chat/completions",
//     {
//       model: "gpt-3.5-turbo",
//       messages: [
//         {
//           role: "system",
//           content: "Translate the following English text to Spanish:",
//         },
//         { role: "user", content: mod2 },
//       ],
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${process.env.REACT_APP_OPENAI_SK}`,
//       },
//     }
//   );

//   const translatedMessage = data?.choices[0].message?.content
//     .split("|")
//     .map((data: string) => data.trim());

//   if (!translatedMessage?.length) return false;
//   const mod3: any = {};
//   Object.keys(mod1).forEach(
//     (key, index) => (mod3[key] = translatedMessage[index])
//   );
//   Object.entries(mod3).forEach(([key, value]) => {
//     const translatedWord = value as string;
//     if (key.includes("comment-")) {
//       const index = parseInt(key.split("comment-").pop() || "");
//       finalTranslatedComments[index] = {
//         ...finalTranslatedComments[index],
//         comment: translatedWord,
//       };
//     } else if (key === "message") {
//       finalTranslatedMessage["message"] = translatedWord;
//     } else if (key === "subject") {
//       finalTranslatedMessage["subject"] = translatedWord;
//     }
//   });

//   return { ...finalTranslatedMessage, comments: finalTranslatedComments };
// };

export const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export function htmlToPlainText(html: string) {
  // Remove HTML tags using a regular expression
  const plainText = html.replace(/<\/?[^>]+(>|$)/g, " ");
  return plainText;
}

export function getFirstLineHtml(htmlString: string) {
  let firstLine = htmlString.split("\n")[0];
  if (firstLine?.length > 0) {
    firstLine = firstLine.slice(0, 120) + "...";
    firstLine = htmlToPlainText(firstLine);
  }
  return firstLine;
}