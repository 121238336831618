import { useEffect, useState } from "react";
import {
  Box,
  InputLabel,
  TextField,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Delete, Add } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";
import { PostFormRequest } from "../../../redux/features/forms/form-slice";

import { searchClientText } from "../../../utils/FilterUtils";

import { ClientForms } from "../../../types/client";

import { ReactComponent as Search } from "../../../assets/images/images-svg/searchIcon.svg";

interface IAssessmentmodel {
  selectedFormId: string;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssessmentModel: React.FC<IAssessmentmodel> = ({
  selectedFormId,
  setOpenPopup,
}) => {
  //#region variable region

  //dispatch for redux
  const dispatch = useAppDispatch();

  //fetch client list
  const ClientList = useAppSelector((state) => state.clientListSlice.listData);

  //fetch assessmentList
  const assessmentList = useAppSelector(
    (state) => state.formSlice.AssessmentRequests
  );
  // selected client data
  const [selectedClientData, setSelectedClientData] = useState<ClientForms[]>(
    []
  );

  // store clients Ids list
  const [clientsId, setclientsId] = useState<string[]>([]);

  //strore client data for mapping
  const [dataList, setDataList] = useState<ClientForms[]>([]);

  //used to show error
  const [error, setError] = useState<boolean>(false);

  //#endregion

  //#region method region

  //get match value
  const getMatchData = (item: ClientForms, searchVal: string): boolean => {
    const searchText = searchClientText(searchVal, item);
    if (!searchText) {
      return false;
    }
    if (searchVal === "") {
      return false;
    }
    return true;
  };

  //get client match value
  const handleSearchResult = (searchVal: string) => {
    let result: any[] = [];
    if (searchVal !== undefined) {
      if (searchVal !== "") {
        ClientList.map((item: ClientForms) => {
          return getMatchData(item, searchVal) && result.push(item);
        });
      } else {
        result = [...ClientList];
      }
    }
    setDataList(result);
  };

  //handles selected client
  const getSelectedClientList = (ID: string) => {
    let arr: ClientForms[] = [];

    let result = ClientList.filter(
      (unit) => unit.clientBasicDetails.id === ID
    )[0];

    let val = selectedClientData?.find(
      (item) => item.clientBasicDetails.id === ID
    );
    arr.push(result);

    if (!val) {
      setSelectedClientData([...arr, ...selectedClientData]);
      setclientsId([ID, ...clientsId]);
    }

    return arr;
  };

  // handle deletetion pof selected client
  const handleDeleteSelectedClient = (item: ClientForms) => {
    let result = selectedClientData.filter(
      (unit) => unit.clientBasicDetails.id !== item.clientBasicDetails.id
    );

    let val = clientsId.filter((id) => id !== item.clientBasicDetails.id);

    setSelectedClientData(result);
    setclientsId(val);
  };

  // send new assessment data
  const handleSendAssessment = () => {
    if (clientsId.length === 0) {
      setError(true);
      return;
    } else {
      dispatch(
        PostFormRequest({
          formId: selectedFormId,
          entityIds: clientsId,
          entityType: "Client",
          formType: "Assessment Form",
        })
      );
      setOpenPopup(false);
    }
  };

  //#endregion

  //#region useEffect region
  //to filter client list
  useEffect(() => {
    const arr = assessmentList
      .filter((item) => item.formId === selectedFormId)
      .map((item) => item.entityId);
    const filteredClient = ClientList.filter((item) =>
      arr.includes(item.clientBasicDetails.id)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClientList, assessmentList]);

  //fetch clientdata
  useEffect(() => {
    //fetch client list
    dispatch(GetAllClientData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  return (
    <Box>
      <Box p={"10px"}>
        <Box className="fromGroup--Assesment">
          <Typography className="modelBlockTitle">Audience</Typography>
          <Box className="innerModelSearch">
            <Search
              style={{
                position: "absolute",
                left: "7px",
                top: "7px",
                zIndex: 1,
              }}
            />
            <TextField
              id="input-with-sx"
              label=""
              placeholder="search clients by name here..."
              variant="standard"
              onChange={(e) => {
                handleSearchResult(e.target.value);
              }}
              onFocus={() => {
                setDataList(ClientList);
              }}
            />
          </Box>
          <Box className="modelSearch-data">
            {dataList.length > 0 && (
              <>
                {dataList.map((item: ClientForms, index: number) => {
                  return (
                    <Box
                      style={{
                        padding: "10px 15px",
                        background: "#ecf6ff",
                      }}
                      onClick={() =>
                        getSelectedClientList(item.clientBasicDetails.id)
                      }
                      key={index}
                    >
                      {item.clientBasicDetails.childFirstName}{" "}
                      {item.clientBasicDetails.childLastName}
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {selectedClientData
                          ?.map((i) => i.clientBasicDetails.id)
                          .includes(item?.clientBasicDetails.id)
                          ? "Added"
                          : "Add"}
                        <Add />
                      </span>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
        <Box className="fromGroup--Assesment">
          <InputLabel className="modelBlockTitle" style={{ color: "#000" }}>
            Added Client
          </InputLabel>

          <Box maxHeight={"200px"} overflow="auto">
            {selectedClientData?.length > 0 && (
              <>
                {selectedClientData?.map((item: ClientForms, index: number) => {
                  return (
                    <Box
                      p={"10px 15px"}
                      mb={"8px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      bgcolor={"#fff"}
                      boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
                      borderRadius={"4px"}
                      key={index}
                    >
                      {item.clientBasicDetails.childFirstName}{" "}
                      {item.clientBasicDetails.childLastName}
                      <Box
                        color={"#096dd9"}
                        display={"flex"}
                        alignItems={"center"}
                        onClick={() => {
                          if (clientsId.includes(item?.clientBasicDetails.id)) {
                            handleDeleteSelectedClient(item);
                          }
                        }}
                      >
                        <Delete />
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
        {error && clientsId?.length === 0 && (
          <FormHelperText style={{ color: " #d32f2f", paddingLeft: "25px" }}>
            Please select new client
          </FormHelperText>
        )}
      </Box>
      <Box>
        <div
          className="assessmentModel__footer"
          style={{}}
          onClick={handleSendAssessment}
        >
          <Button type="submit" className="send-btn">
            Send Assessment
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default AssessmentModel;
