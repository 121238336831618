import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import SupervisorService from "../../../service/supervisor.service";
import { NonSupervisorLists, ProviderList } from "../../../types/provider";
import { ClientForms } from "../../../types/client";

const initialState: NonSupervisorLists = {
  providerList: [],
  supervisorList: [],
  // clientList: [],
};

const providerSupervisorSlice = createSlice({
  name: "providerSupervisorSlice",
  initialState: initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      getNonSupervisor.fulfilled,
      (state, action: PayloadAction<ProviderList[] | any>) => {
        if (action.payload !== undefined) {
          return {
            ...state,
            providerList: [...action.payload],
          };
        }
      }
    );
    builder.addCase(
      getSupervisor.fulfilled,
      (state, action: PayloadAction<ProviderList[] | any>) => {
        return {
          ...state,
          supervisorList: [...action.payload],
        };
      }
    );
    //deprecate this!
    // builder.addCase(
    //   getNonSupervisedClients.fulfilled,
    //   (state, action: PayloadAction<ClientForms[] | any>) => {
    //     return {
    //       ...state,
    //       clientList: action.payload,
    //     };
    //   }
    // );
  },
});

export const getNonSupervisor = createAsyncThunk(
  "provider/getNonSupervisor",
  async (_, { getState, dispatch }) => {
    try {
      const data = await SupervisorService.GetNonSupervisor();

      return data;
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    }
  }
);

export const getSupervisor = createAsyncThunk(
  "provider/getSupervisor",
  async (_, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await SupervisorService.GetSupervisor();

      return data;
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

/* deprecate this!
export const getNonSupervisedClients = createAsyncThunk(
  "provider/getNonSupervisedClients",
  async (_, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await SupervisorService.GetNonSupervisedClients();

      const { clientListSlice } = getState() as {
        clientListSlice: { listData: ClientForms[] };
      };

      const filteredData = clientListSlice.listData.filter((client) =>
        data.includes(client.clientBasicDetails.id)
      );

      return filteredData;
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);*/

export default providerSupervisorSlice;
