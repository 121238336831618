import axios from "../axios/axios";
import { OrgService } from "../constants/company-user";
import { OrgBU, OrgData } from "../types/company";
import { SingleResponse, Response } from "../types/response";

export const postOrganization = async (
  name: string,
  orgUrl: string,
  file: any,
  orgService: string[]
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("orgUrl", orgUrl);
    formData.append("orgService", JSON.stringify(orgService));
    const { data } = await axios.post<SingleResponse<OrgData>>(
      "/organization",
      formData
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getOrgInfo = async () => {
  try {
    //call api
    const { data } = await axios.get<SingleResponse<OrgData>>(`/organization`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateOrgInfo = async (
  orgName: string,
  orgUrl: string,
  orgService: OrgService[],
  supportEmail:string,
  tenantId: string
) => {
  try {
    //call api
    const { data } = await axios.put<SingleResponse<OrgData>>("/organization", {
      orgName,
      orgUrl,
      orgService,
      supportEmail,
      tenantId,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const deleteOrg = async () => {
  try {
    const { data } = await axios.delete("/organization");
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getOrganizationLogo = async () => {
  try {
    const { data } = await axios.get<SingleResponse<OrgData>>("/organization");

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateOrganizationLogo = async (file: any) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    //formData.append("tenantId", tenantId);
    const { data } = await axios.put("/organization/logo", formData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//Deprecated this... not used in backend anymore! 8.7.2023

// export const postUser = async (userData: User, orgId: string) => {
//   try {
//     const { data } = await axios.post<SingleResponse<UserResponse>>(
//       `/organization/${orgId}/user`,
//       userData
//     );

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

// export const getAllOrganizationUsers = async (orgId: string) => {
//   try {
//     const { data } = await axios.get<Response<CompanyUsersResponse>>(
//       `/organization/${orgId}/user`
//     );
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

// export const updateCompanyUser = async (userData: User, orgId: string) => {
//   try {
//     const { data } = await axios.put<UpdateResponse>(
//       `/organization/${orgId}/user/${userData.id}`,
//       userData
//     );
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

// export const deleteCompanyUser = async (userId: string) => {
//   try {
//     const { data } = await axios.delete<DeleteResponse>(
//       `/organization/user/${userId}`
//     );
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export const createBusinessUnit = async (unit: OrgBU) => {
  try {
    const { data } = await axios.post<SingleResponse<OrgBU>>("/businessUnit", {
      businessUnitName: unit.businessUnitName,
      businessUnitAddress: unit.businessUnitAddress,
      businessUnitCity: unit.businessUnitCity,
      businessUnitState: unit.businessUnitState,
      businessUnitZipCode: unit.businessUnitZipCode,
      businessUnitTimeZone: unit.businessUnitTimeZone,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateBusinessUnit = async (unit: OrgBU) => {
  try {
    const { data } = await axios.put<SingleResponse<OrgBU>>(
      `/businessUnit/${unit.id}`,
      {
        businessUnitName: unit.businessUnitName,
        businessUnitAddress: unit.businessUnitAddress,
        businessUnitCity: unit.businessUnitCity,
        businessUnitState: unit.businessUnitState,
        businessUnitZipCode: unit.businessUnitZipCode,
        businessUnitTimeZone: unit.businessUnitTimeZone,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const deleteBusinessUnitById = async (businessUnitId: string) => {
  try {
    const { data } = await axios.delete<SingleResponse<OrgBU>>(
      `/businessUnit/${businessUnitId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getAllBusinessUnits = async () => {
  try {
    const { data } = await axios.get<Response<OrgBU>>("/businessUnit");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const CompanyUserService = {
  postOrganization,
  updateOrganizationLogo,
  getOrgInfo,
  updateOrgInfo,
  getOrganizationLogo,
  deleteOrg,
  // postUser,
  // getAllOrganizationUsers,
  // updateCompanyUser,
  // deleteCompanyUser,
  deleteBusinessUnitById,
  updateBusinessUnit,
  getAllBusinessUnits,
  createBusinessUnit,
};

export default CompanyUserService;
